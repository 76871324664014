import { graphql } from 'gatsby';

import React from 'react';
import styled from 'styled-components';

import { useGlobalContext } from '$store';
import { rem, typo, colors, media, mixins, maxBreakpoints } from '$styles';
import { PrismicRich, PrismicText } from '$components/text';
import { Icon, Illustration, Image, ImageRatio } from '$components/image';
import { Button } from '$components/form/Button';
import classNames from 'classnames';
import { SectionTitle } from '$components/text/SectionTitle';
import { Slider } from '$components/global/Slider';

export const query = graphql`
  fragment ImagesSlider_Rich on PrismicPageRichDataBodyImagesSlider {
		id slice_label slice_type
		primary {
			images_slider_title { text }
		}
		items {
			images_slider_image { url alt dimensions { height width } }
		}
	}
`;

const ImagesSliderComponent = ({ className, ...data }) => {
	className = classNames(className, 'image-slider');

	const title = data?.primary?.images_slider_title;
	const images = (data?.items || []).filter(v => v && v.images_slider_image);

	const sliderOpts = {
		width: '100%',
		// fixedWidth: '55vw',
		start: Math.floor(images.length / 2),
		gap: rem(50),
		padding: { left: '10%', right: '10%' },
		focus: 'center',
		trimSpace: true,
		breakpoints: {
			[ maxBreakpoints.mobile ]: {
				gap: rem(10),
				padding: { left: '5%', right: '5%' },
			}
		}
	};

	return <div className={className}>
		<SectionTitle text={title} offset parallax />
		<Slider options={sliderOpts}>
			{ images.map(({ images_slider_image }, i) => (
				<ImageRatio key={i} ratio="1.795" {...images_slider_image} />
			))}
		</Slider>
	</div>;
};

export const ImagesSlider = styled(ImagesSliderComponent)`
	${ mixins.margin(1, null, 1, null, true) }

	h2 {
		max-width: 13em;
		${ mixins.margin(null, 1, null, 1, true) }
	}
`;

