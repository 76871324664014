import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

import { mixins } from '$styles';

import { Icon } from './Icon';

const FrameComponent = ({ className, arch, ...props }) => {
	className = classNames(className, 'frame', { 'is-arch': !!arch });
	const id = arch ? 'frame-arch' : 'frame';
	return <Icon className={className} id={id} freeScale />;
};

export const Frame = styled(FrameComponent)`
	display: block;
	position: absolute;
	top: -1.4%;
	left: -0.5%;
	width: 101%;
	height: 104.2%;
	pointer-events: none;

	&.is-arch {
		height: 102.8%;
	}
`;
