import React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '$components/image';
import { usePageContext } from '$store';
import { media, mixins, rem } from '$styles/';
import { Link } from '$components/global/Link';

const HeaderLogoComponent = ({ className }) => {
	const { siteData } = usePageContext();
	const homepage = siteData?.header_homepage?.url ?? '/';

	return (
		<figure className={ className }>
			<Link className="header-offset" to={homepage}>
				<Icon id="logo" />
			</Link>
		</figure>
	);
};

export const HeaderLogo = styled(HeaderLogoComponent)`
	${ mixins.flexCenter }
	padding: 0 ${ rem(75) };
	flex-grow: 1;

	.icon-logo {
		width: ${ rem(180) };
		height: auto;
		transition: transform 150ms;
	}

	.is-scrolled & .icon-logo {
		transform: scale(0.72);
	}

	.is-mobile-menu & {
		padding: 0 ${ rem(10) };
		flex-grow: 1;

		.icon-logo {
			width: ${ rem(115) };
			height: auto;
		}
	}

	.is-mobile-menu.is-scrolled & .icon-logo {
		transform: scale(0.95);
	}
`;
