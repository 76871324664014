import { css } from 'styled-components';
import { em } from 'polished';

export const maxBreakpoints = {
	smallMobile: 374,
	mobile: 767,
	tabletPortrait: 1023,
	smallDesktop: 1100,
	tabletLandscape: 1279,
	smallFooter: 1350
};

export const minBreakpoints = {
	fromMobile: 768,
	fromTabletLandscape: 1024,
	xxlarge: 1800,
};

export const mqTabletLandscape = `(max-width: ${ maxBreakpoints.tabletLandscape }px)`;
export const mqTabletPortrait = `(max-width: ${ maxBreakpoints.tabletPortrait }px)`;
export const mqTabletPortraitOnly = `(max-width: ${ maxBreakpoints.tabletPortrait }px) and (min-width: ${ maxBreakpoints.mobile }px)`;
export const mqMobile = `(max-width: ${ maxBreakpoints.mobile }px)`;
export const mqPortrait = '(orientation:portrait)';

// Iterate through the sizes and create a media template
/* eslint-disable */
export let media = Object.keys(maxBreakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${em(maxBreakpoints[label])}) {
      ${css(...args)}
    }
`;

  return acc;
}, {});

media = Object.keys(minBreakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${em(minBreakpoints[label])}) {
      ${css(...args)}
    }
`;

  return acc;
}, media);
/* eslint-enable */

const cache = { below: [], between: [], above: [] };
media.below = max => {
	if (maxBreakpoints[ max ]) max = maxBreakpoints[ max ];
	else if (minBreakpoints[ max ]) max = minBreakpoints[ max ];
	const key = max;
	if (cache.below[ key ]) return cache.below[ key ];
	return cache.below[ key ] = (...args) => css`
		@media (max-width: ${ em(max - 1) }) { ${ css(...args) } }
	`;
};

media.between = (min, max) => {
	if (maxBreakpoints[ max ]) max = maxBreakpoints[ max ];
	else if (minBreakpoints[ max ]) max = minBreakpoints[ max ];
	if (maxBreakpoints[ min ]) max = maxBreakpoints[ min ];
	else if (minBreakpoints[ min ]) max = minBreakpoints[ min ];
	const key = min + '_' + max;
	if (cache.between[ key ]) return cache.between[ key ];
	return cache.between[ key ] = (...args) => css`
		@media (min-width: ${ em(min) }) and (max-width: ${ em(max) }) { ${ css(...args) } }
	`;
};

media.above = min => {
	if (maxBreakpoints[ min ]) max = maxBreakpoints[ min ];
	else if (minBreakpoints[ min ]) max = minBreakpoints[ min ];
	const key = min;
	if (cache.above[ key ]) return cache.above[ key ];
	return cache.above[ key ] = (...args) => css`
		@media (min-width: ${ em(min + 1) }) { ${ css(...args) } }
	`;
};
