import React from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { mixins, easings, typo, transitions, spacing, colors } from '$styles';

import { Icon } from '$components/image';
import { Parallax } from '$components/global/Parallax';
import { PrismicText } from './Typography';

const SectionTitleComponent = ({
	tag: Tag = motion.h2,
	className,
	text,
	parallax,
	offset,
	...props
}) => {
	let parallaxStrength = parallax ? -5 : 0;
	if (typeof parallax === 'string' || typeof parallax === 'number') {
		parallaxStrength = +parallax;
	}
	className = classNames(
		className,
		'section-title',
		{ 'has-parallax': !!parallax },
		{ 'has-offset': !!offset }
	);

	return (
		<Parallax strength={parallaxStrength}>
			<Tag className={className} {...props}>
				<PrismicText tag="span" text={text} nl2br />
			</Tag>
		</Parallax>
	);
};

export const SectionTitle = styled(SectionTitleComponent)`
	${ typo.h2 }
	position: relative;
	text-transform: uppercase;
	line-height: 1;
	z-index: 2;
	color: ${ colors.green };
	margin-bottom: 0.2em;
	/* overflow-wrap: break-word;
	hyphens: auto; */
	max-width: 12em;


	&.has-offset {
		margin-bottom: -0.5em;
	}

`;
