import classNames from 'classnames';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useIsomorphicLayoutEffect } from 'react-use';

import { useGlobalContext, usePageContext } from '$store';
import { mixins, media, rem, z, colors, easings, spacing } from '$styles';
import { Container } from '$components/global/Container';
import { MenuNav } from './MenuNav';

const MenuComponent = ({ className, props }) => {
	const { siteData } = usePageContext();

	const {
		isMobile,
		isMenuOpen,
		setMenuOpen,
		setSavedScrollTop,
		setScrollEnabled
	} = useGlobalContext();

	className = classNames(className, {
		'is-open': isMenuOpen
	});

	useEffect(() => {
		if (!isMobile) setMenuOpen(false);
	}, [ isMobile ]);

	useIsomorphicLayoutEffect(() => {
		if (isMenuOpen) {
			const { scrollTop } = document.scrollingElement || document.documentElement;
			setSavedScrollTop(scrollTop);
		}
		setScrollEnabled(!isMenuOpen);
	}, [ isMenuOpen ]);

	if (!isMobile) return null;

	return (
		<div className={className}>
			<div className="content">
				<MenuNav />
			</div>
		</div>
	);
};

export const Menu = styled(MenuComponent)`
	pointer-events: none;
	z-index: ${ z.menu };
	position: fixed;
	overflow: hidden;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	&:before, .content {
		position: absolute;
		top: 0;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&:before {
		content: '';
		z-index: 0;
		background: ${ colors.black };
		pointer-events: none;
		opacity: 0;
		transition: opacity 300ms;
	}

	&.is-open:before {
		opacity: 0.5;
	}

	.content {
		z-index: 1;
		pointer-events: all;
		padding-top: var(--header-height);
		transform: translateX(100%);
		transition: transform 0.9s ${ easings.circOut };
		overflow: hidden;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		${ mixins.bg }
		${ mixins.hiddenScrollbar }
	}

	&.is-open .content {
		transform: translateX(0);
	}
`;
