/* eslint react-hooks/rules-of-hooks: 0 */

import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import siteQuery from '$prismic/site.gql.js';
import ageGateQuery from '$prismic/site_age_gate.gql.js';

export function useSiteDataPreview(ctx) {
	if (typeof document === 'undefined') return;
	if (!process.env.GATSBY_PRISMIC_PREVIEW) return;
	if (!document.cookie.includes('io.prismic.preview')) return;
	const lang = ({ node }) => node.lang === ctx.lang;

	const site = useMergePrismicPreviewData(siteQuery());
	const siteLang = site.data.allPrismicSite.edges.find(lang);
	if (site?.isPreview && siteLang?.node?.data)
		ctx.siteData = siteLang.node.data;

	const ageGate = useMergePrismicPreviewData(ageGateQuery());
	const ageGateLang = ageGate.data.allPrismicSiteAgegate.edges.find(lang);
	if (ageGate?.isPreview && ageGateLang?.node?.data)
		ctx.ageGateData = ageGateLang.node.data;
}
