import { graphql } from 'gatsby';

import React from 'react';
import styled from 'styled-components';

import { rem, typo, colors, media, mixins } from '$styles';

import { PrismicRich, PrismicText } from '$components/text';
import { Icon, Illustration, Image, ImageCover } from '$components/image';
import classNames from 'classnames';
import { Parallax } from '$components/global/Parallax';
import { CTA } from '$components/form/CTA';
import { Slider } from '$components/global/Slider';

export const query = graphql`
	fragment Casa_Home on PrismicPageHomeDataBodyCasa {
		id slice_label slice_type
		primary {
			casa_cta_link { url type target id }
			casa_cta_text
			casa_text { html }
			casa_title { text }
		}
		items {
			casa_image { url dimensions { height width } alt }
		}
	}
`;

const HomeCasaComponent = ({ className, ...data }) => {
	const {
		casa_title,
		casa_text,
		casa_cta_text,
		casa_cta_link
	} = data?.primary || {};
	const images = (data?.items || []).map(v => v.casa_image).filter(Boolean);

	const sliderOpts = {
		width: '100%',
		autoplay: true,
		interval: 3000,
		type: 'loop',
		breakpoints: {}
	};

	if (!images.length) return null;
	className = classNames(className, 'section-home-casa');
	return <section className={className}>
		<div className="content">
			<Parallax strength={-3}>
				<PrismicText tag="h2" className='title' text={casa_title} />
			</Parallax>
			<PrismicRich className='text' text={casa_text} />
			<CTA {...{ ctaLink: casa_cta_link, ctaText: casa_cta_text }} />
		</div>
		<Slider options={sliderOpts}>
			{ images.map((image, i) => (
				<ImageCover key={image?.url + '_' + i} blur={false} {...image} />
			))}
		</Slider>
	</section>;
};

export const HomeCasa = styled(HomeCasaComponent)`
	background: ${ colors.brown };
	position: relative;
	overflow: hidden;

	.content {
		${ mixins.padding(1, 1, 1.2, 1, true) }
		position: relative;
		z-index: 2;
		color: ${ colors.dust };
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;
		min-height: ${ rem(650) };
		pointer-events: none;

		button, a {
			pointer-events: all;
		}
	}

	.title {
		text-align: center;
		margin-bottom: 0.4em;
		${ typo.h2 }
		max-width: 15em;
	}

	.text {
		text-align: center;
		${ typo.text }
		max-width: 42em;
		margin-bottom: 2em;
		${ typo.shadow() }
	}

	.cta {
		${ typo.shadow() }
	}

	${ media.mobile`
		.content {
			min-height: ${ rem(550) };
		}

		.title {
			margin-bottom: 0.6em;
		}

		.text {
			max-width: 26em;
		}
	` }

	// Slider
	.slider {
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.slider-drag-hint {
		color: ${ colors.dust }
	}

	.splide, .cursor-wrapper, .splide__track, .splide__list, .splide__slide {
		height: 100%;
	}

	.slider picture {
		width: 100%;
		height: 100%;
	}

	.splide__track {
		position: relative;
	}

	.splide__list {
		position: relative;
		z-index: 0;
	}

	.splide__track:after {
		content: '';
		z-index: 1;
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: ${ colors.black };
		opacity: 0.45;
	}

	.splide__pagination {
		position: absolute;
		z-index: 0;
		bottom: ${ rem(20) };
		left: 0;
		width: 100%;
	}

	.splide__pagination__page::after,
	.splide__pagination__page.is-active::after {
		${ mixins.sliderDotDust };
	}
`;

HomeCasa.sliceType = 'casa';
