import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { media, mixins, colors, z, rem } from '$styles';

import { FooterLogo } from './FooterLogo';
import { FooterSecondaryNav } from './FooterSecondaryNav';
import { FooterLang } from './FooterLang';
import { FooterCopyright } from './FooterCopyright';

const FooterBottomComponent = ({ className }) => {
	className = classNames(className, 'footer-bottom');

	return (
		<div className={className}>
			<FooterLogo />
			<div className="navs">
				<FooterSecondaryNav />
				<div className="navs-copylang" data-nosnippet="">
					<FooterLang />
					<FooterCopyright />
				</div>
			</div>
		</div>
	);
};

export const FooterBottom = styled(FooterBottomComponent)`
	${ mixins.bgWaves };
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	justify-content: space-between;

	.footer-logo {
		flex: 0 0 auto;
		border-right: ${ mixins.stroke };
	}

	.navs {
		flex: 1 1 auto;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
	}

	.navs-copylang {
		flex: 1 0 auto;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
	}

	.footer-nav {
		flex: 0 1 auto;
		min-height: 60%;
		border-right: ${ mixins.stroke };
	}

	.footer-copyright {
		flex: 1 0 auto;
		text-align: right;
	}

	${ media.mobile`
		display: block;
		background: none;

		.navs {
			flex-flow: column-reverse nowrap;
		}

		.navs-copylang {
			flex-flow: row-reverse nowrap;
			width: 100%;
			${ mixins.padding(0, 1, 0, 1, true) }
			border-bottom: ${ mixins.stroke };
		}

		.footer-nav {
			text-align: center;
			border: none;
		}

		.footer-lang {
			border-left: ${ mixins.stroke };
		}

		.footer-logo {
			${ mixins.bgWaves }
			border: none;
			border-bottom: ${ mixins.stroke };
		}

		.footer-copyright {
			text-align: left;
		}
	` }
`;
