import classNames from 'classnames';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { mixins } from '$styles';

import { ImageCover } from './ImageCover';
import { Parallax } from '$components/global/Parallax';

const ImageRatioComponent = forwardRef(({ className, arch, ratio, parallax, ...props }, ref) => {
	ratio = ratio || (props.dimensions.width / props.dimensions.height).toFixed(4);
	className = classNames(className, 'image-ratio', { 'is-arch': !!arch });

	const base = (
		<div className={className} style={{ '--natural-ratio': ratio }}>
			<ImageCover {...props} ref={ref} />
		</div>
	);

	if (parallax) return <Parallax strength={parallax}>{base}</Parallax>;
	else return base;
});

ImageRatioComponent.displayName = 'ImageRatioComponent';

export const ImageRatio = styled(ImageRatioComponent)`
	display: block;
	width: 100%;
  position: relative;
	--natural-ratio: 1.6;

	&.is-arch {
		${ mixins.arch }
	}

	&:after {
		content: '';
		display: block;
		padding-top: calc((100% / var(--ratio, var(--natural-ratio))) * var(--ratio-mult, 1));
		position: relative;
	}
`;
