import { useCallback } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';

import { useGlobalContext } from '$store';

export function useScollEnabled() {
	const {
		savedScrollTop,
		scrollEnabled,
		setSavedScrollTop,
		setScrollEnabled
	} = useGlobalContext();

	const handleTouchScroll = useCallback((event) => {
		// Disable scroll on touch device with allowance for .scrollable elements
		if (
			!event.target.classList.contains('scrollable') &&
      event.target.closest('.scrollable') === null
		) {
			event.preventDefault();
		}
	}, []);

	useIsomorphicLayoutEffect(() => {
		if (scrollEnabled) {
			window.removeEventListener('touchmove', handleTouchScroll);
			document.body.style.removeProperty('top');
			document.scrollingElement.scrollTo(0, savedScrollTop);
		} else {
			document.body.style.top = `${ -savedScrollTop }px`;
			window.addEventListener('touchmove', handleTouchScroll, { passive: false });
		}
	}, [ handleTouchScroll, scrollEnabled, savedScrollTop ]);

	const reset = () => {
		setSavedScrollTop(0);
		setScrollEnabled(false);
	};

	return reset;
}
