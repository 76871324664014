// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-page-404-js": () => import("./../../../src/templates/Page404.js" /* webpackChunkName: "component---src-templates-page-404-js" */),
  "component---src-templates-page-document-js": () => import("./../../../src/templates/PageDocument.js" /* webpackChunkName: "component---src-templates-page-document-js" */),
  "component---src-templates-page-standby-js": () => import("./../../../src/templates/PageStandby.js" /* webpackChunkName: "component---src-templates-page-standby-js" */)
}

