import '$styles/_css_reset.css';
import '$styles/_css_fonts.css';
import '$styles/_css_global.css';
import '@splidejs/react-splide/css/core';

import React from 'react';
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews';

import { GlobalProvider } from '$store';
import { IconSpritesheet } from '$components/image';
import { Layout } from '$components/global/Layout';

import linkResolver from '$prismic/linkResolver';
import pageList from '$templates';

import 'gatsby-plugin-prismic-previews/dist/styles.css';

export const wrapRootElement = ({ element }) => {
	return (
		<PrismicPreviewProvider
			repositoryConfigs={[
				{
					repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
					linkResolver,
					componentResolver: componentResolverFromMap(pageList),
				},
			]}
		>
			<IconSpritesheet />
			<GlobalProvider>{element}</GlobalProvider>
		</PrismicPreviewProvider>
	);
};

export const wrapPageElement = ({ element, props }) => {
	if (props.pageContext.fromPrismic) {
		return <Layout {...props}>{element}</Layout>;
	} else {
		return element;
	}
};
