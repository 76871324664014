import styled from 'styled-components';
import { rgba } from 'polished';
import React, { useState } from 'react';

import { useGlobalContext, usePageContext } from '$store';
import { useAgegateCookie } from '$hooks';
import { rem, media, colors, spacing, typo } from '$styles';
import propsToDom from '$utils/propsToDom';

import { Container as InputContainer, Field, Input, Select } from '$components/form/Form';
import { FormCheckbox } from '$components/form/FormCheckbox';
import { Button } from '$components/form/Button';


const DEFAULT_LEGAL_AGE = 21;

// Age calculator by Lucas Janon.
// https://stackoverflow.com/a/50827764/362136
function getAge(today, date) {
	return Math.floor((today - date.getTime()) / 3.15576e10);
}

function checkAge({ day, month, year, isExtendedDate }, countryCode, countries) {
	const today = new Date();
	const date = new Date(`${ year }-${ month }-${ day }`);
	const age = getAge(today, date);
	const country = countries.find(({ code }) => countryCode.toUpperCase() === code);

	// Illegal in this country
	if ((country && country.age === null) || isNaN(age)) {
		return 'illegal';
	}

	const ageLimit = country ? country.age : DEFAULT_LEGAL_AGE;
	const isOverLimit = age >= ageLimit;

	// We need day & month in the user birthdate
	if (!isExtendedDate && !isOverLimit && today.getFullYear() - date.getFullYear() === ageLimit) {
		return 'need-extended';
	}

	return age >= ageLimit;
}

const AgeGatewayFormComponent = ({
	too_young_error_text,
	illegal_country_error_text,
	error_link_redirection,
	location_label,
	location_placeholder,
	birthdate_label,
	birthday_placeholder,
	birthmonth_placeholder,
	birthyear_placeholder,
	optin_remember_text,
	enter_text,
	...others
}) => {
	const [ , updateAgegateCookie ] = useAgegateCookie();
	const { setUserCountry } = useGlobalContext();
	const { countries } = usePageContext();

	const [ data, setData ] = useState({
		country: '',
		birthday: '',
		birthmonth: '',
		birthyear: '',
		remember: false,
	});
	const [ errors, setErrors ] = useState({});
	const [ result, setResult ] = useState(null);

	const handleSubmit = (e) => {
		e.preventDefault();

		const { birthday, birthmonth, birthyear, country, remember } = data;

		const isExtendedDate = birthday !== '' && birthmonth !== '';

		const date = {
			day: isExtendedDate ? birthday : '31',
			month: isExtendedDate ? birthmonth : '12',
			year: birthyear,
			isExtendedDate,
		};

		if (date && country) {
			const result = checkAge(date, country, countries);
			setResult(result);

			if (result === true) {
				setUserCountry(country.toUpperCase());

				updateAgegateCookie(
					{
						country: country.toUpperCase(),
						year: date.year,
						...(isExtendedDate ? { day: birthday, month: birthmonth } : {}),
					},
					remember && { expires: 365 }
				);
			} else if (result === false) {
				setTimeout(() => {
					window.location.href = error_link_redirection.url;
				}, 5000);
			}
		}
	};

	// Error management
	let error = null;
	if (result === 'illegal') {
		error = <Error dangerouslySetInnerHTML={{ __html: illegal_country_error_text.html }} />;
	} else if (result === false) {
		error = <Error dangerouslySetInnerHTML={{ __html: too_young_error_text.html }} />;
	}

	const input = {
		onChange: (event) => {
			const { value, name, type } = event.target;
			const error = !event.target.checkValidity();
			setData((prev) => ({ ...prev, [ name ]: type === 'checkbox' ? !prev[ name ] : value }));
			setErrors((prev) => ({ ...prev, [ name ]: error }));
		},
	};

	const needDayAndMonth = result === 'need-extended';
	const firstCountriesInSelect = [ 'fr', 'uk', 'gb', 'de', 'it', 'cz', 'gr' ];

	return (
		<form onSubmit={handleSubmit} {...propsToDom(others)}>
			<Field label={location_label}>
				<Select
					name="country"
					value={data.country}
					placeholder={location_placeholder}
					options={[ { value: '', label: null } ].concat(
						countries
							.map(({ code, label }) => ({ value: code, label }))
							.sort((a, b) => {
								const aCode = a.value.toLowerCase();
								const bCode = b.value.toLowerCase();
								const aIsLocale = firstCountriesInSelect.includes(aCode);
								const bIsLocale = firstCountriesInSelect.includes(bCode);
								if (aIsLocale && !bIsLocale) {
									return -1;
								} else if (!aIsLocale && bIsLocale) {
									return 1;
								} else if (aIsLocale && bIsLocale) {
									return (
										firstCountriesInSelect.indexOf(aCode)
										- firstCountriesInSelect.indexOf(bCode)
									);
								} else {
									return a.label.localeCompare(b.label);
								}
							})
					)}
					required={true}
					{...input}
				/>
			</Field>

			<Field label={birthdate_label}>
				<BirthdateInputs showDayAndMonth={needDayAndMonth}>
					<Input
						type="number"
						name="birthday"
						inputMode="numeric"
						min="1"
						max="31"
						placeholder={birthday_placeholder}
						style={{ width: `${ 0.6 * birthday_placeholder.length }em` }}
						value={data.birthday}
						error={!!errors?.birthday}
						required={needDayAndMonth}
						{...input}
					/>
					<Input
						type="number"
						name="birthmonth"
						inputMode="numeric"
						min="1"
						max="12"
						placeholder={birthmonth_placeholder}
						style={{ width: `${ 0.6 * birthmonth_placeholder.length }em` }}
						value={data.birthmonth}
						error={!!errors?.birthmonth}
						required={needDayAndMonth}
						{...input}
					/>
					<Input
						type="number"
						name="birthyear"
						inputMode="numeric"
						min="1866"
						max={new Date().getFullYear()}
						style={{ width: `${ 0.85 * birthyear_placeholder.length }em` }}
						placeholder={birthyear_placeholder}
						value={data.birthyear}
						error={!!errors?.birthyear}
						required={true}
						{...input}
					/>
				</BirthdateInputs>
			</Field>

			<FormCheckbox id="remember" name="remember" checked={data.remember} {...input}>
				{optin_remember_text}
			</FormCheckbox>

			{error}

			<Button background="small" color="green" type="submit">
				{enter_text}
			</Button>
		</form>
	);
};

export const AgeGateForm = styled(AgeGatewayFormComponent)`
  margin: auto;

  ${ InputContainer } {
    ${ typo.h3 }

    input::placeholder,
    .placeholder {
      color: ${ rgba(colors.dust, 0.5) };
    }

    ${ media.mobile`
      font-size: ${ rem(30) };
    ` }
  }

  ${ Input } {
    text-align: center;
  }

  ${ Field } + ${ Field } {
    margin-top: ${ spacing.s3 };
  }

  ${ FormCheckbox } {
    width: 100%;
    max-width: ${ rem(400) };
    margin: ${ spacing.s3 } auto 0;
  }

  ${ Button } {
    margin-top: ${ spacing.s4 };
  }
`;

const BirthdateInputs = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${ spacing.s3 };

  ${ InputContainer } {
    &:nth-child(1),
    &:nth-child(2) {
      display: ${ ({ showDayAndMonth = false }) => (showDayAndMonth ? null : 'none') };
    }
  }
`;

const Error = styled.p`
  font-weight: ${ typo.fontWeight.medium };
  color: ${ colors.red };
  width: 100%;
  max-width: ${ rem(698) };
  margin: ${ spacing.s2 } auto 0;
`;
