import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { useGlobalContext, usePageContext } from '$store';
import { mixins, rem, typo, colors, media, spacing } from '$styles';
import { PrismicText, PrismicSpan, PrismicRich } from '$components/text';
import { Icon, Illustration } from '$components/image';
import { Button } from '$components/form/Button';
import { Parallax } from '$components/global/Parallax';
import { CTA } from '$components/form/CTA';
import { SectionTitle } from '$components/text/SectionTitle';
import linkResolver from '$prismic/linkResolver';
import { ImageCover } from '$components/image';


const DOTS = '.'.repeat(200);

const formatPrice = (v) => {
	if (!v) return null;
	v = (v + '').trim();
	if (!v.endsWith('€')) v += '€';
	return v;
};

const BookingComponent = ({ className, ...data }) => {
	className = classNames('wrapper', className);

	const text = data?.booking_text;
	const footer = data?.booking_footer;
	const items = (data.booking_list || []).map(v => ({
		text: v?.book_text,
		image: v?.book_image,
		cta: { text: v?.book_cta_text, link: v?.book_cta_link, isPopin: v?.book_cta_popin }
	})).filter(v => v.cta?.link?.url);

	return <div className={className} >
		<PrismicRich className="title" tag="h1" text={text} />
		<ul className="pushs">
			{ items.map((item, i) => (
				<li className="push" key={i + '_' + item.text.text}>
					<div className="push-content">
						<PrismicRich className="push-text" text={item.text} />
						<CTA className="cta" {...item.cta} />
					</div>
					{ item.image && <ImageCover {...item.image} /> }
				</li>
			)) }
		</ul>
		<footer><PrismicRich text={footer}/></footer>
	</div>;
};

export const Booking = styled(BookingComponent)`
	.wrapper {
		display: flex;
		flex-flow: column nowrap;
		align-items: stretch;
		justify-content: center;
		min-height: 100%;
	}

	.title {
		${ typo.h3 }
		${ mixins.padding(0, 1, 0.45, 1, true) }
		text-align: center;
		max-width: 40em;
		margin: 0 auto;
	}

	ul {
		flex: 1 1 auto;
		--gap: ${ rem(20) };
		max-height: ${ rem(530) };
		display: flex;
		flex-flow: row wrap;
		align-items: space-between;
		justify-content: center;
		padding: 0 var(--gap);
	}

	footer {
		${ mixins.padding(0, 1, 0.15, 1, true) }
		text-align: center;
	}

	li {
		${ mixins.padding(0.3, 0.5, 0.3, 0.5, true) }
		flex: 0 1 auto;
		min-height: 100%;
		width: calc(33.3% - var(--gap) * 2 - 2px);
		position: relative;
		margin: 0 var(--gap) calc(var(--gap) * 2);
		text-align: center;
		color: ${ colors.dust };
	}

	li:after {
		${ mixins.overlay(0.6, 1) }
	}

	.image-cover {
		z-index: 0;
	}

	.push-content {
		height: 100%;
		position: relative;
		z-index: 2;
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;
	}

	.push-text {
		${ typo.h3 }
		${ typo.shadow() }
		margin-bottom: 1.5em;
	}

	.cta {
		${ typo.shadow() }
	}

	${ media.mobile`

		ul {
			flex: 0 0 auto;
			max-height: none;
		}

		li {
			${ mixins.padding(1.2, 3, 1.2, 3, true) }
			width: 100%;
			min-height: auto;
			margin-bottom: 1.5em;
		}
	` }
`;
