import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import { useGlobalContext, usePageContext } from '$store';
import { mixins, rem, typo, colors, media } from '$styles';
import { PrismicText } from '$components/text';
import { Icon, Illustration } from '$components/image';
import { Button } from '$components/form/Button';
import { Parallax } from '$components/global/Parallax';

export const query = graphql`
  fragment PushClub_Rich on PrismicPageRichDataBodyPushClub {
		id slice_label slice_type
		primary {
			push_club_visible
			push_club_text { html text }
		}
	}
`;

const PushClubComponent = ({ className, ...data }) => {
	const { siteData } = usePageContext();
	const { setModal } = useGlobalContext();

	className = classNames(className, 'push-club');
	const isVisible = data?.primary?.push_club_visible;

	const title = siteData?.push_club_title || '';
	const defaultText = siteData?.push_club_text || '';
	const text = data?.primary?.push_club_text || defaultText;

	const ctaTitle = siteData?.push_club_cta_title || '';
	const ctaText = siteData?.push_club_cta_text || '';

	function handleClick() {
		setModal({ id: 'club' });
	}

	if (!isVisible) return null;

	return <section className={className}>
		<div className="text-block">
			<div className="text-title">
				<PrismicText tag="h2" text={title} />
				<Parallax strength="2" rotate>
					<div className="sigle">
						<Illustration id="eminente-club" color="black" />
					</div>
				</Parallax>
			</div>
			<PrismicText tag="p" text={text} />
		</div>
		<div className="cta-block">
			{ ctaTitle && ctaTitle.length && <PrismicText tag="h3" text={ctaTitle} /> }
			<Button component="button" background="club" onClick={handleClick}>
				<span>{ctaText}</span>
				<Icon id="arrow-right" />
			</Button>
		</div>
	</section>;
};

export const PushClub = styled(PushClubComponent)`
	${ mixins.bgWaves }
	${ mixins.padding(0.4, 1, 0.4, 1, true) }
	border-top: ${ mixins.stroke };
	overflow: hidden;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;

	h2 {
		overflow-wrap: none;
		hyphens: none !important;
	}

	// LAYOUT ---
	.text-block {
		flex: 0 1 auto;
	}
	.cta-block {
		flex: 0 0 auto;
		width: 36%;
	}

	${ media.mobile`
		display: block;
		${ mixins.padding(0.6, 1, 0.6, 1, true) }

		.cta-block {
			width: 100%;
		}
	` }

	// TEXT ----
	.text-block {
		--sigle-size: ${ rem(100) };
		margin-left: calc(var(--sigle-size) * 0.95)
	}

	.text-block h2 {
		position: relative;
		z-index: 1;
		${ typo.h2 }
		font-size: ${ rem(40) };
		${ media.mobile`font-size: ${ rem(35) };` }
		${ media.smallMobile`font-size: ${ rem(35) };` }
		margin-bottom: 0.4em;
	}

	.text-block p {
		${ typo.capsSmaller }
		background: ${ colors.dust };
		border-top: 1px solid ${ colors.white };
		border-bottom: 1px solid ${ colors.white };
		padding: 0.2em 1.5em 0.05em 1.5em;
		max-width: 41em;
		margin-right: 2em;
	}

	.text-title {
		position: relative;
	}

	.sigle {
		position: absolute;
		z-index: 0;
		width: var(--sigle-size);
		height: var(--sigle-size);
		top: calc(var(--sigle-size) * -0.18);
		left: calc(var(--sigle-size) * -1.15);

		img {
			position: relative;
			z-index: 1;
			--width: 100%;
		}
		&:after {
			${ mixins.overlay(1, 0) }
			background: ${ colors.dust };
			border-radius: 50%;
		}
	}

	${ media.mobile`
		display: block;
		${ mixins.padding(0.7, 1, 0.7, 1, true) }

		.text-block {
			--sigle-size: ${ rem(90) };
			margin-left: calc(var(--sigle-size) * 0.9)
		}

		.text-block h2 {
			max-width: 8em;
		}

		.text-block p {
			margin-left: ${ rem(-15) };
		}
	` }

	// CTA ----
	h3 {
		${ typo.h3 }
		position: relative;
		text-transform: uppercase;
		margin-bottom: 0.8em;
	}

	.icon-arrow-right {
		display: inline-block;
		margin-left: 0.7em;
		margin-bottom: 0.2em;
	}

	${ media.mobile`
		.cta-block {
			max-width: 530px;
			margin: 0 auto;
			margin-top: ${ rem(40) };
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: space-between;
		}

		h3 {
			margin-bottom: 0;
			margin-right: 1em;
			max-width: 12em;
		}
	` }
`;

PushClub.sliceType = 'push_club';
