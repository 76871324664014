import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Button } from './Button';
import { PrismicSpan } from '$components/text';

const CTAComponent = ({ className, ...props }) => {
	const link = props.ctaLink || props.link;
	const text = props.ctaText || props.text;

	className = classNames(className, 'cta');
	if (!link || !text) return null;
	return (
		<Button
			className={className}
			background="medium"
			color="green"
			{...props}
			{...link}
		>
			<PrismicSpan text={text} />
		</Button>
	);
};

export const CTA = styled(CTAComponent)`
`;
