import React, { useEffect } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { navigate } from 'gatsby';
import {
	useIsomorphicLayoutEffect,
	useLocalStorage,
	useMount,
	usePrevious,
	useUpdateEffect,
} from 'react-use';

import { useAgegateCookie, useScollEnabled } from '$hooks';
import { mixins, colors, z } from '$styles';
import { PageProvider, useGlobalContext } from '$store';
import { delegateEvent } from '$utils/delegateEvent';

import { useSiteDataPreview } from '$hooks/useSiteDataPreview';

import { AgeGate } from '$components/age-gate/AgeGate';
import { ModalManager } from '../modal/ModalManager';
import { Menu } from '$components/menu/Menu';

import { Header } from '$components/standby/Header';
import { Footer } from '$components/standby/Footer';

import GlobalStyle from './GlobalStyle';

const LS_KEY = 'nl-subscribed';
let newsletterOpen = false;
let newsletterTimer = null;
let hasModal = false;

const Layout = ({ children, location, pageContext }) => {
	const [ { country } ] = useAgegateCookie();
	const resetScroll = useScollEnabled();
	const { pageType, hreflang, url, localeIso, sellingCountriesIso } = pageContext;
	const [ choosenLocale ] = useLocalStorage('user-selected-locale');
	const isPage404 = pageType === 'page_404';

	useSiteDataPreview(pageContext);

	const {
		modal,
		setModal,
		showAgeGate,
		setShowAgeGate,
		scrollEnabled,
		setUserCountry,
		headerHeight,
		isMenuOpen,
		setMenuOpen,
		setPage404,
		isMobile,
		wh,
		dpr
	} = useGlobalContext();

	useEffect(() => { setPage404(!!isPage404) }, [ isPage404 ]);

	useIsomorphicLayoutEffect(() => {
		const hideAgeGate = location.search.indexOf('no-agegate') > -1;
		const navLang = navigator?.language?.split('-')[ 0 ];
		const urlLang = hreflang.find(({ lang }) => lang.split('-')[ 0 ] === navLang)?.url;

		if (hideAgeGate) {
			setShowAgeGate(false);
		}

		if (!choosenLocale && url !== urlLang && isPage404) {
			navigate(urlLang);
		}

		setUserCountry(country || localeIso.toUpperCase());
	}, []);

	// CRM - Auto open newsletter modale
	function startNewsletterTimer(delayMult = 1) {
		const { crm_timer } = pageContext.siteData;
		clearTimeout(newsletterTimer);
		const subscribed = !!+localStorage.getItem(LS_KEY);
		if (subscribed || !crm_timer || crm_timer < 1) return;
		newsletterTimer = setTimeout(
			autoOpenNewsletterModale,
			crm_timer * 1000 * delayMult
		);
	}

	function autoOpenNewsletterModale() {
		// Restart delay if there is currently an active modale
		if (newsletterOpen) return;
		if (hasModal) return startNewsletterTimer();
		setModal({ id: 'club' });
	}

	useIsomorphicLayoutEffect(() => {
		if (newsletterOpen) return;
		if (modal && modal.id === 'club') {
			newsletterOpen = true;
			localStorage.setItem(LS_KEY, 1);
		}
		hasModal = !!modal;
	}, [ modal ]);

	useIsomorphicLayoutEffect(() => {
		const { crm_timer } = pageContext.siteData;
		const subscribed = !!+localStorage.getItem(LS_KEY);
		if (subscribed || !crm_timer || crm_timer < 1) return;
		startNewsletterTimer();
		return () => clearTimeout(newsletterTimer);
	}, []);


	useMount(() => {
		// Delegates event for cookies preferences toggle
		delegateEvent(document.body, 'click', '.optanon-toggle-display', () => {
			if (window?.Optanon?.ToggleInfoDisplay instanceof Function) {
				window.Optanon.ToggleInfoDisplay();
			}
		});
	});

	useUpdateEffect(() => {
		if (isMenuOpen) {
			resetScroll();
			setMenuOpen(false);
		}
	}, [ children ]);

	return (
		<PageProvider pageContext={pageContext}>
			<Helmet>
				<link rel="preconnect" href="https://casa-eminente.cdn.prismic.io" />
				<link rel="apple-touch-icon" sizes="180x180" href="/images/favicons/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/images/favicons/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/images/favicons/favicon-16x16.png" />
				<link rel="manifest" href="/images/favicons/site.webmanifest" />
				<link rel="mask-icon" href="/images/favicons/safari-pinned-tab.svg" color={colors.metaThemeColor} />
				<link rel="shortcut icon" href="/images/favicons/favicon.ico" />
				<meta name="msapplication-config" content="/images/favicons/browserconfig.xml" />
				<meta name="theme-color" content={colors.metaThemeColor} />
			</Helmet>
			<GlobalStyle
				scrollEnabled={scrollEnabled}
				dpr={dpr}
				viewportHeight={wh}
				headerHeight={headerHeight}
			/>
			<Header />
			<Menu />
			<Main key={isMobile ? '0' : '1'}>{children}</Main>
			{!isPage404 && <Footer />}
			{showAgeGate && !isPage404 && <AgeGate />}
			<ModalManager />
		</PageProvider>
	);
};

const Main = styled.main`
  position: relative;
	overflow: hidden;
  z-index: ${ z.layout };
`;

export default Layout;
export { Layout };
