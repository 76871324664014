export function delegateEvent(el, evt, sel, handler, useCapture) {
	if (typeof useCapture === 'undefined') {
		useCapture = false;
	}

	el.addEventListener(
		evt,
		function (event) {
			let t = event.target;
			while (t && t !== this) {
				if (t.matches(sel)) {
					handler.call(t, event);
				}
				t = t.parentNode;
			}
		},
		useCapture
	);
}
