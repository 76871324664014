import propsToDom from '$utils/propsToDom';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

import { PrismicSpan } from './Typography';
import { Icon } from '$components/image';
import { typo, rem, colors, media } from '$styles';

const SmallBoxTextComponent = ({
	tag: Tag = 'div',
	text,
	...props
}) => {
	props.className = classNames('small-box-text', props.className);
	return <Tag {...propsToDom(props)}>
		<PrismicSpan text={text} />
		<Icon id="box-line" freeScale />
	</Tag>;
};

export const SmallBoxText = styled(SmallBoxTextComponent)`
	position: relative;
	display: inline-box;
	padding: 0.2em 1.2em;

	${ typo.fontTitle }
	font-weight: 500;
	font-size: ${ rem(18) };

	${ media.mobile`font-size: ${ rem(14) };` }

	.icon-box-line {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		color: ${ colors.green }
	}
`;
