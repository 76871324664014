const req = require.context('!!raw-loader!.', true, /\.svg$/);
const sprites = {};

req
	.keys()
	.forEach(key => {
		const data = req(key).default.trim();
		const name = key.split('/').pop().replace('.svg', '');
		const colorizable = !name.endsWith('_raw');
		const id = colorizable ? name : name.slice(0, -4);
		const classes = colorizable ? '' : 'class="raw" ';
		const viewbox = data.match(/viewBox="([. 0-9]+)"/i)[ 1 ] || '0 0 10 10';

		let symbol = data
			.trim()
			.replace(
				/ xmlns="[a-z0-9:/.-_?&=%]+"/,
				''
			)
			.replace(
				/^<svg ?([^>]*)?(?:id="[ -_a-z0-9]")?([^>]*)>/gi,
				`<symbol id="${ id }" ` + classes + `$1$2>`
			)
			.replace(
				/<\/svg> *$/iu,
				'</symbol>'
			);

		if (colorizable)
			symbol = symbol.replace(
				/(fill|stroke)="#?[a-z0-9]*"/gi,
				``
			);

		// Svg minification
		symbol = symbol.trim();

		const width = +viewbox.split(' ')[ 2 ];
		const height = +viewbox.split(' ')[ 3 ];

		sprites[ id ] = {
			id,
			viewbox,
			width,
			height,
			symbol
		};
	});


export default sprites;
