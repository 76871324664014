import { graphql } from 'gatsby';
import React from 'react';
import styled, { css } from 'styled-components';
import {
	Accordion as ReactAccordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

import { mixins, rem, spacing } from '$styles';

import { Icon } from '$components/image';
import RichText from '$components/text/RichText';
import { Title3 } from '$components/text';
import propsToDom from '$utils/propsToDom';

export const query = graphql`
  fragment Accordion_Document on PrismicPageDocumentDataBodyAccordion {
    id slice_type slice_label
    items {
      item_title {
        text
      }
      text {
        raw
      }
    }
  }
`;


const AccordionComponent = ({ slice_type, items, ...others }) => {
	return (
		<ReactAccordion {...propsToDom(others)} allowMultipleExpanded={true} allowZeroExpanded={true}>
			{items.map(({ item_title, text }, index) => (
				<Item key={item_title.text + index}>
					<AccordionItemHeading>
						<Toggle>
							<Title3><span data-nosnippet="">{item_title.text}</span></Title3>
							<Icon id="plus" />
							<Icon id="minus" />
						</Toggle>
					</AccordionItemHeading>
					<Panel>
						<RichText render={text.raw} />
					</Panel>
				</Item>
			))}
		</ReactAccordion>
	);
};

export const Accordion = styled(AccordionComponent)`
	${ mixins.hasHover(css`
		.accordion__heading {
			transition: opacity 200ms;
		}
		.accordion__heading:hover {
			opacity: 0.4;
		}
	`) }

`;

const Item = styled(AccordionItem)``;

const Toggle = styled(AccordionItemButton)`
  position: relative;
  padding: ${ spacing.s3 } 0;
  border-bottom: 1px solid currentColor;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;

  ${ Item }:first-child & {
    padding-top: 0;

    .icon {
      top: calc(50% - ${ spacing.s1_5 });
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);

    .icon-minus {
      font-size: ${ rem(4) };
      display: none;
    }

    .icon-plus {
      font-size: ${ rem(18) };
    }
  }

  &[aria-expanded='true'] {
   .icon-minus {
      display: block;
    }

    .icon-plus {
      display: none;
    }
  }
`;

const Panel = styled(AccordionItemPanel)`
  padding: ${ spacing.s3 } 0;
`;

