import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import RichText from '$components/text/RichText';
import propsToDom from '$utils/propsToDom';

export const query = graphql`
  fragment Text_Document on PrismicPageDocumentDataBodyText {
    id slice_type slice_label
    primary {
      text { raw }
    }
  }
`;

const TextComponent = ({ slice_type, primary, ...others }) => {
	return (
		<div {...propsToDom(others)}>
			<RichText render={primary.text.raw} />
		</div>
	);
};

export const Text = styled(TextComponent)``;

