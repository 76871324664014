import React, { useEffect, useState } from 'react';
import { useIsomorphicLayoutEffect, useMeasure } from 'react-use';
import { useViewportScroll } from 'framer-motion';
import styled from 'styled-components';
import classNames from 'classnames';

import { useGlobalContext, usePageContext } from '$store';
import { mixins, colors, z, rem } from '$styles';

import { HeaderLogo } from './HeaderLogo';

const HeaderComponent = ({ className }) => {
	// Compute menu height
	const [ $header, { height } ] = useMeasure();
	const { setHeaderHeight, isPage404 }	= useGlobalContext();
	useIsomorphicLayoutEffect(() => setHeaderHeight(height), [ height ]);

	// Change menu state depending on scroll value
	const { scrollEnabled } = useGlobalContext();
	const [ isScrolled, setScrolled ] = useState(false);
	const [ isMinified, setMinified ] = useState(false);
	const { scrollY } = useViewportScroll();

	let lastSignY = 0;
	let lastSign = 0;
	let lastY = 0;

	useEffect(scrollY.onChange(y => {
		const sign = y > lastY ? 1 : -1;
		lastY = y;
		if (sign !== lastSign) {
			lastSign = sign;
			lastSignY = y;
		}
		const distance = Math.abs(y - lastSignY) * sign;
		if (!isMinified && distance > 400) setMinified(true);
		else if (isMinified && distance < -20) setMinified(false);
		if (y > 150) {
			setScrolled(true);
		} else {
			lastSignY = lastY;
			setMinified(false);
			setScrolled(false);
		}
	}), []);


	// Always display submenu on header hover
	function onMouseEnter() {
		lastSignY = lastY;
		setMinified(false);
	}

	// Mobile menu state
	const { isMobile: isMobileMenu } = useGlobalContext();

	return (
		<header
			ref={$header}
			onMouseEnter={onMouseEnter}
			className={classNames(className, 'app-header', {
				'is-mobile-menu': isMobileMenu,
				'is-404': isPage404,
				'is-scrolled': false && (!scrollEnabled || isScrolled),
				'is-minified': false && scrollEnabled && isMinified
			})}
		>
			<div className="wrapper">
				<div className="header-top">
					<HeaderLogo />
				</div>
			</div>
		</header>
	);
};

export const Header = styled(HeaderComponent)`
  position: relative;
	pointer-events: none;
  top: 0;
  right: 0;
  left: 0;
  z-index: ${ z.header };
	color: ${ colors.black };
	--header-scrolled-offset: ${ rem(-45) };

	& .header-bottom, & .header-top {
		pointer-events: all;
	}

	.wrapper {
		transition: transform 200ms;
	}

	.header-offset {
		transition: transform 200ms;
	}

	&.is-scrolled .wrapper {
		transform: translateY(var(--header-scrolled-offset));
	}

	&.is-scrolled .header-offset {
		transform: translateY(calc(-0.5 * var(--header-scrolled-offset)));
	}

  &.is-404 {
		visibility: hidden !important;
		pointer-events: none !important;
		* { pointer-events: none !important; }
  }

	.header-top {
		position: relative;
		z-index: 1;
		border-bottom: 1px solid ${ colors.black };
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		min-height: ${ rem(100) };
		width: 100%;
		${ mixins.bg }
	}

	.header-bottom {
		${ mixins.bg }
		position: relative;
		z-index: 0;
		transition: transform 200ms;
		border-bottom: ${ mixins.stroke };
	}

	&.is-minified .header-bottom {
		transform: translateY(-110%);
	}

	&.is-mobile-menu {
		--header-scrolled-offset: ${ rem(-10) };

		.header-top {
			min-height: ${ rem(65) };
		}
	}
`;
