import React from 'react';
import styled from 'styled-components';
import { useLifecycles } from 'react-use';

import { usePageContext, useGlobalContext } from '$store';
import { rem, colors, spacing, z } from '$styles';
import { Container } from '$components/global';
import { Icon, ImageCover } from '$components/image';
import { SmallCaps, Title3 } from '$components/text';
import { AgeGateForm } from './AgeGateForm';

const AgeGateComponent = ({ className }) => {
	const { setScrollEnabled } = useGlobalContext();
	const { ageGateData } = usePageContext();
	const { main_text, secondary_text, background_image, ...form } = ageGateData;
	const hasBackgroundImage = !!background_image?.url;
	const hasMainText = !!main_text?.html;
	const hasSecondaryText = !!secondary_text?.html;

	// Disable/Enable scroll on Mount/Unmount
	useLifecycles(
		() => setScrollEnabled(false),
		() => setScrollEnabled(true)
	);

	return (
		<aside className={className}>
			{
				hasBackgroundImage &&
				<ImageCover {...background_image} />
			}
			<div className="scrollable">
				<Container>
					<Icon id="logo" className="logo" />
					{
						hasMainText &&
						<Title3 text={main_text}/>
					}
					<AgeGateForm {...form} />
					{
						hasSecondaryText &&
						<SmallCaps tag="div" text={secondary_text} />
					}
				</Container>
			</div>
		</aside>
	);
};

export const AgeGate = styled(AgeGateComponent)`
  position: fixed;
  top: 0;
  left: 0;
	right: 0;
	bottom: 0;

  z-index: ${ 1000 };
  color: ${ colors.dust };

	.logo {
		position: relative;
		fill: ${ colors.dust };
		width: ${ rem(160) };
		height: auto;
		z-index: 3;
		display: block;
		margin: ${ spacing.s2 } auto 0;
	}

	.scrollable {
		position: relative;
		z-index: 2;
		width: 100%;
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
	}

	& ${ ImageCover } {
		transition: transform 3s;
		transform: scale(1.05);

		&[data-loaded='true'] {
			transform: scale(1);
		}

		.blurred {
			/* display: none; */
		  transition: opacity 3s !important;
		}
	}

	& ${ ImageCover }:after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: black;
		pointer-events: none;
		opacity: 0.6;
		z-index: 1;
	}

	& ${ Container } {
		min-height: 100vh;
		padding-top: ${ rem(10) };
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
	}

	& ${ Title3 } {
		width: 100%;
		max-width: ${ rem(810) };
		margin-top: ${ spacing.s4 };
		margin-bottom: ${ spacing.s3 };
	}

	& ${ SmallCaps } {
		margin-top: ${ spacing.s3 };
		margin-bottom: ${ spacing.s3 };

		a {
			color: currentColor;
		}
	}
`;
