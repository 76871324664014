import React from 'react';
import styled, { css } from 'styled-components';
import propsToDom from '$utils/propsToDom';

import { useGlobalContext, usePageContext } from '$store';
import { Link } from '$components/global/Link';
import { rem, media, colors, mixins, typo } from '$styles';
import { Icon } from '$components/image';
import classNames from 'classnames';
import { Button } from '$components/form/Button';


const MenuNavComponent = ({ className, ...props }) => {
	const { siteData } = usePageContext();
	const { setModal } = useGlobalContext();
	const links = siteData.header_nav.map(({ nav_link, nav_text }, i) => {
		const linkProps = { to: nav_link.url };
		if (nav_link.target === '_blank') linkProps.target = '_blank';

		const isExternal = !nav_link.type;
		const className = [ isExternal && 'is-external' ].filter(Boolean);

		return <li key={i} className={className.join(' ')}>
			<Link {...linkProps}>
				<Icon id="line-horizontal" className="line" freeScale />
				<span>{nav_text}</span>
				{/* {isExternal && <Icon id="external" />} */}
			</Link>
		</li>;
	});

	className = classNames('menu', className);

	const ctaText = siteData?.push_club_cta_text || '';

	return (
		<div className={className}>
			<nav>
				<ul>{links}</ul>
			</nav>
			<footer>
				<p>{siteData.i18n_join_eminente_club}</p>
				<Button
					className="cta"
					color="green"
					background="medium"
					onClick={() => setModal({ id: 'club' })}
				>
					<span>{ctaText}</span>
					<Icon id="arrow-right" />
				</Button>
			</footer>
		</div>
	);
};

export const MenuNav = styled(MenuNavComponent)`
	color: ${ colors.black };

	${ typo.fontTitle }
	text-transform: uppercase;
	font-size: ${ rem(28) };
  line-height: ${ rem(54) };

	nav {
		padding: ${ rem(15) } ${ rem(20) } ${ rem(15) };
	}

	/* .is-external {
		text-transform: capitalize;
		font-size: ${ rem(32) };
		font-style: italic;
	} */

	li {
		position: relative;
	}

	li a {
		position: relative;
	}

	li .line {
		/* display: none; */
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: ${ rem(4) };
		transform-origin: right center;
		transform: scaleX(0);
		opacity: 0;
		transition: transform 300ms, opacity 300ms;
		color: ${ colors.green }
	}

	${ mixins.hasHover(css`
		li a:hover .line {
			transform-origin: left center;
			transform: scaleX(1);
			opacity: 1;
		}
	`) }

	li a.is-active .line {
		transform-origin: left center;
		transform: scaleX(1);
		opacity: 1;
	}

	.icon-external {
		position: absolute;
		right: -1.5em;
		top: calc(50% - 0.7em);
		font-size: 0.55em;
		color: ${ colors.green };
	}

	footer {
		border-top: 1px solid ${ colors.black };
		padding: ${ rem(15) } ${ rem(20) };
		text-transform: none;
		font-weight: 500;
		font-size: 0.8em;
		line-height: ${ rem(44) };

		button {
			${ typo.fontText }
			${ typo.caps }
			font-size: 0.7em;
		}

		.icon-arrow-right {
			display: inline-block;
			margin-left: 0.7em;
			margin-bottom: 0.2em;
		}
	}

`;
