import styled from 'styled-components';

import { rem, media, spacing } from '$styles';

export const Container = styled.div`
  margin-left: ${ rem(60) };
  margin-right: ${ rem(60) };

  ${ media.mobile`
    margin-left: ${ ({ noMobileMargin = false }) => (noMobileMargin ? 0 : rem(16)) };
    margin-right: ${ ({ noMobileMargin = false }) => (noMobileMargin ? 0 : rem(16)) };
  ` }
`;

export const ContainerGrid = styled(Container)`
  display: grid;
  grid-template-columns: ${ ({ nbCols = 12 }) => `repeat(${ nbCols }, 1fr) ` };
  grid-column-gap: ${ spacing.gridColumnGap };
  margin-left: ${ ({ nbCols }) => (nbCols < 12 ? 0 : null) };
  margin-right: ${ ({ nbCols }) => (nbCols < 12 ? 0 : null) };

  ${ media.mobile`
    grid-template-columns: minmax(0, 1fr);

    & > * {
      grid-column: 1 / -1 !important;
    }
  ` }
`;
