import React from 'react';
import styled from 'styled-components';

import svgs from '$assets/svg';

export function IconSpritesheet() {
	let inner = '';
	for (const k in svgs) inner += svgs[ k ].symbol;

	return <SpritesheetStyle
		style={{ display: 'none' }}
		aria-hidden="true"
		className="icons-spritesheet"
		dangerouslySetInnerHTML={{ __html: inner }}
	/>;
}

const SpritesheetStyle = styled.svg`
	display: none;

	& symbol {
		transform-origin: 50% 50%;
	}

	& symbol:not(.raw) * {
		transform-origin: 50% 50%;
		stroke-width: 0;
		stroke: currentColor;
		fill: currentColor;
	}
`;

export function Icon(userProps) {
	const { id, className, freeScale, ...props } = userProps;
	const symbol = svgs[ id ];
	if (!symbol) return null;
	if (freeScale) props.preserveAspectRatio = 'none';

	const href = '#' + id;
	props.className = [ 'icon', 'icon-' + id, (className || '') ].join(' ').trim();

	// Custom viewbox or default one
	if (props.viewBox == null) props.viewBox = symbol.viewbox;
	if (props.width === true) props.width = symbol.width;
	if (props.height === true) props.height = symbol.height;

	return <IconStyle {...props}>
		<use href={href} />
	</IconStyle>;
}

const IconStyle = styled.svg`
		display: inline-block;
		width: 1em;
		height: 1em;
		vertical-align: top;
		transform-origin: 50% 50%;
		color: inherit;
		/* border: 1px solid red; */
`;
