import { graphql } from 'gatsby';

import React from 'react';
import styled from 'styled-components';

import { useGlobalContext } from '$store';
import { rem, typo, colors, media, mixins } from '$styles';
import { PrismicRich, PrismicText } from '$components/text';
import { Icon, Illustration, Image, ImageRatio } from '$components/image';
import { Button } from '$components/form/Button';
import classNames from 'classnames';
import { SectionTitle } from '$components/text/SectionTitle';

export const query = graphql`
	fragment Quote_Rich on PrismicPageRichDataBodyQuote {
		id slice_label slice_type
		primary {
			illustration: quote_illustration
			text: quote_text { html }
		}
	}
`;

const QuoteComponent = ({ className, ...data }) => {
	className = classNames(className, 'quote');

	data = data?.primary || {};
	if (data.quote_illustration) data.illustration = data.quote_illustration;
	if (data.quote_text) data.text = data.quote_text;

	return <section className={className}>
		<div className="content">
			<div>
				<span className="quote-char quote-char-left">“</span>
				<PrismicRich className="text" text={data.text} />
				<span className="quote-char quote-char-right">”</span>
			</div>
		</div>
		<Illustration color="white" id={data.illustration} parallax={-0.5}/>
	</section>;
};

export const Quote = styled(QuoteComponent)`
	${ mixins.margin(1, 3, 1, 3, true) }
	${ typo.h3 }
	text-align: center;
	line-height: 1.7;
	position: relative;

	font-size: ${ rem(56) };
	${ media.mobile`  font-size: ${ rem(40) }; ` }
  ${ media.smallMobile` font-size: ${ rem(38) }; ` }

	.illus {
		position: absolute;
		z-index: 0;
		--width: 100%;
		--height: 100%;
		top: calc(50% - var(--height) * 0.55);
		left: calc(50% - var(--width) * 0.5);
	}

	.content {
		position: relative;
		z-index: 1;
		min-height: ${ rem(480) };
		${ mixins.flexCenter }
		${ media.mobile` 	min-height: ${ rem(400) }; ` }
	}

	p:first-of-type, p:last-of-type {
		display: inline;
	}

	strong {
		font-weight: 500;
	}

	strong em, em strong {
		font-weight: 500;
		${ mixins.underline() }
	}

	.text {
		display: inline;
	}

	.quote-char {
		display: inline-block;
		color: ${ colors.green };
		font-size: 1.6em;
		line-height: 0;
	}

	.quote-char-left {
		/* margin-left: -0.5em; */
		transform: translate(-0.3em, 0.4em);
	}

	.quote-char-right {
		/* margin-left: -0.2em; */
		transform: translate(0.3em, 0.4em);
	}
`;

