import React, { useState } from 'react';
import { motion, useMotionValue, useSpring } from 'framer-motion';
import { useIsomorphicLayoutEffect, useMeasure } from 'react-use';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import styled from 'styled-components';

import propsToDom from '$utils/propsToDom';

import { gentle, gentle_slow } from '$data/springs';
import { colors, media, rem, mixins, maxBreakpoints } from '$styles';
import { usePageContext } from '$store/PageProvider';
import { Image } from '$components/image';
import { Button } from '$components/form/Button';
import classNames from 'classnames';
import { useGlobalContext } from '$store/GlobalProvider';

const SliderComponent = ({
	className,
	children,
	options,
	mobileOptions = null,
	label = 'Slider'
}) => {
	const { isMobile } = useGlobalContext();

	const sliderOpts = options || {
		fixedWidth: rem(400),
		gap: rem(55),
		padding: { left: rem(120), right: rem(50) }
	};

	if (mobileOptions) {
		const bp = sliderOpts.breakpoints = {};
		bp[ maxBreakpoints.mobile ] = mobileOptions;
	} else if (!options || !options.breakpoints) {
		const bp = sliderOpts.breakpoints = {};
		bp[ maxBreakpoints.mobile ] = {
			fixedWidth: '92%',
			gap: '5%',
			padding: { left: rem(20), right: rem(20) }
		};
	}

	const { siteData } = usePageContext();
	const dragX = useSpring(0, gentle);
	const dragY = useSpring(0, gentle);
	const dragRotate = useSpring(0, gentle);
	const dragScale = useSpring(0, gentle);
	const dragOpacity = useSpring(0, gentle);
	const onMouseEnter = () => {
		if (isMobile) return;
		dragScale.set(1);
		dragOpacity.set(1);
	};
	const onMouseLeave = () => {
		if (isMobile) return;
		dragScale.set(0);
		dragOpacity.set(0);
	};
	const onMouseMove = (event) => {
		if (isMobile) return;
		const bounds = event.currentTarget.getBoundingClientRect();
		const mouseX = event.clientX - bounds.left;
		const mouseY = event.clientY - bounds.top;
		dragX.set(mouseX);
		dragY.set(mouseY);
		dragRotate.set(event.movementX / 5);
	};

	if (!children) return null;
	children = Array.isArray(children) ? children : [ children ];
	className = classNames(className, 'slider');

	return <section className={className}>
		<Splide
			options={sliderOpts}
			aria-label={label}
			hasTrack={false}

		>
			<div
				className="cursor-wrapper"
				onMouseMove={onMouseMove}
				onMouseLeave={onMouseLeave}
				onMouseEnter={onMouseEnter}
			>
				<SplideTrack>
					{children.map((c, i) => <SplideSlide key={i}>{ c }</SplideSlide>)}
				</SplideTrack>
				{
					!isMobile
					&& <Button
						className="slider-drag-hint"
						component={motion.div}
						background="small"
						color="green"
						style={{ x: dragX, y: dragY, rotate: dragRotate, scale: dragScale, opacity: dragOpacity }}
						transformTemplate={({ x, y, rotate, scale }) =>
							`translateX(-50%) translate(${ x }, ${ y }) rotate(${ rotate }) scale(${ scale })`
						}
					>
						{siteData.i18n_drag}
					</Button>
				}
			</div>
			<ul className="splide__pagination" />
		</Splide>
	</section>;
};

export const Slider = styled(SliderComponent)`
	.cursor-wrapper {
		position: relative;
	}

  ${ Button } {
    position: absolute;
    top: 10px;
    left: 0;
    pointer-events: none;
    color: ${ colors.green };
  }

	.splide__arrows {
		display: none;
	}

	.splide__track {
		cursor: grab;
		&:active {
    cursor: grabbing;
  }
	}

	.splide__pagination {
		margin-top: ${ rem(18) };
		${ media.mobile`margin-top: ${ rem(12) } ` }
	}

	.splide__pagination__page {
		position: relative;
		${ mixins.buttonReset }
		width: 16px;
		height: 16px;
		transition: width 200ms;

		&:after {
			content: '';
			position: absolute;
			top: 6px;
			left: 3px;
			right: 3px;
			margin: 0 auto;
			height: 4px;
			${ mixins.sliderDot };
			background-size: 100% 100%;
		}

		&.is-active {
			width: 25px;
		}

		&.is-active:after {
			${ mixins.sliderDotActive };
		}
	}
`;
