import React, { useRef, useState } from 'react';
import { useIsomorphicLayoutEffect, useMeasure } from 'react-use';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { rgba } from 'polished';

import { dry } from '$data/springs';
import beziers from '$data/beziers';

import { useGlobalContext, usePageContext } from '$store';
import { rem, media, z, colors, spacing } from '$styles';

import { Container as RowContainer } from '$components/global/Container';
import { LinkUnderline } from '$components/text';

const OVERLAY = {
	visible: {
		opacity: 1,
		transition: dry,
	},
	hidden: {
		opacity: 0,
		transition: dry,
	},
};

const ModalComponent = ({
	id,
	ready = true,
	setScroll = true,
	fullWidth = false,
	header = (close) => close,
	children,
	...others
}) => {
	const $scroller = useRef(null);
	const { setModal } = useGlobalContext();
	const { siteData } = usePageContext();
	const [ isScrolled, setScrolled ] = useState(false);
	const [ $header, { height: headerHeight } ] = useMeasure();

	useIsomorphicLayoutEffect(() => {
		if ($scroller.current) {
			const handleScroll = () => setScrolled($scroller.current.scrollTop > 0);
			$scroller.current.addEventListener('scroll', handleScroll, { passive: true });
			return () => {
				$scroller.current.removeEventListener('scroll', handleScroll);
			};
		}
	}, [ ready ]);

	const close = () => setModal(null);

	return (
		<div
			{...others}
			data-id={id}
			data-full={fullWidth}
			data-scrolled={isScrolled}
			style={{ '--modal-header-height': `${ headerHeight }px` }}
		>
			<Overlay
				variants={OVERLAY}
				initial="hidden"
				animate="visible"
				exit="hidden"
				onClick={close}
			/>
			{ready && (
				<Container
					className='modal-container'
					variants={{
						visible: {
							y: fullWidth ? 0 : null,
							opacity: fullWidth ? 1 : 1,
							transition: { type: 'ease', duration: 0.9, ease: beziers.expoOut },
						},
						hidden: {
							y: fullWidth ? '100%' : null,
							opacity: fullWidth ? 1 : 0,
						},
						exit: {
							y: fullWidth ? '100%' : null,
							opacity: fullWidth ? 1 : 0,
							transition: dry,
						},
					}}
					initial="hidden"
					animate="visible"
					exit="exit"
					transformTemplate={!fullWidth && (({ y }) => `translate(-50%, -50%) translateY(${ y })`)}
				>
					<Header ref={$header}>
						<RowContainer>
							{header(<Close onClick={close}>{siteData.i18n_close}</Close>)}
						</RowContainer>
					</Header>
					<Scroller className="scrollable" ref={$scroller}>
						{children}
					</Scroller>
				</Container>
			)}
		</div>
	);
};

export const Modal = styled(ModalComponent)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${ z.modal };
`;

const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${ rgba(colors.black, 0.3) };
  cursor: pointer;
`;

export const Close = styled(LinkUnderline)`
  margin-left: auto;
`;

export const Container = styled(motion.div)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: ${ () => `calc(100% - var(--header-height, ${ spacing.s9 }) * 0.4) ` };
  background: ${ colors.dust };
  color: ${ colors.black };
  border-radius: ${ spacing.s4 } ${ spacing.s4 } 0 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  [data-full='true'] & {
		height: 100%;
		max-height: ${ () => `calc(100% - var(--header-height, ${ spacing.s9 }) * 0.73) ` };

		${ media.mobile`
			max-height: ${ () => `calc(100% - var(--header-height, ${ spacing.s9 }) * 0.905) ` };
		` }
	}

  [data-full='false'] & {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    width: 70rem;
    min-width: 698px;
    border-radius: ${ spacing.s4 };
    clip-path: inset(0 0 0 0 round ${ spacing.s4 });

    ${ media.mobile`
      width: calc(100% - ${ rem(25) });
      min-width: 0;
    ` }
  }
`;

export const Header = styled.div`
  flex-grow: 0;
  flex-shrink: 0;

  ${ RowContainer } {
    position: relative;
    display: flex;
    justify-content: center;
    padding: ${ spacing.s3 } 0;
  }

  [data-scrolled='true'] & {
    box-shadow: inset 0 -1px 0 0 ${ rgba(colors.black, 0.5) };
  }
`;

export const Scroller = styled.div`
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: ${ colors.dust };
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${ () => `var(--accent-color, ${ colors.green }) ` };
    border-radius: 2.5px;

    [data-id='buy'] & {
      background-color: ${ colors.red };
    }
  }
`;
