import React from 'react';
import sliceComponents from '$components/slices';

const SliceZone = ({ body }) => {
	const allSlices = body || [];

	let lastType = null;
	let count = 0;

	const slices = allSlices.map((s, index) => {
		if (!s.slice_type) return;
		const key = s.id ? s.id : `${ s.slice_type }-${ index }`;
		const type = s.slice_type;
		const normType = s.slice_type.split('_')
			.map(w => w[ 0 ].toUpperCase() + w.slice(1)).join('');

		// Count same block combos
		// To create alternates between odd/even
		if (type !== lastType) count = 0;
		s.comboIndex = count++;
		lastType = type;

		const Component = sliceComponents[ type ] || sliceComponents[ normType ];
		if (!Component) return;

		return <Component key={key} {...s} />;
	}).filter(Boolean);
	return slices;
};

export default SliceZone;
