import { motion } from 'framer-motion';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useGlobalContext } from '$store';
import classNames from 'classnames';

function getImageUrlAtWidth(targetWidth, image) {
	const { width, height } = image.dimensions;
	const scale = targetWidth / width;
	const w = Math.round(width * scale);
	const h = Math.round(height * scale);

	return image.url.replace(`w=${ width }`, `w=${ w }`).replace(`h=${ height }`, `h=${ h }`);
}

function getSrcSet(image) {
	const breakpoints = [ 768, 1024, 1280, 1440, 1600, 1920, 2560 ];

	return (
		breakpoints
			.concat([ image.dimensions.width ])
			.sort()
		// Set srcSet only for smaller images than original,
		// but keeping a 2x possibility
			.filter((bp) => bp <= image.dimensions.width * 2)
		// Generate url
			.map((bp) => `${ getImageUrlAtWidth(bp, image) } ${ bp }w`)
		// Generate srcSet
			.join(', ')
	);
}

function getPreviewUrl(image) {
	const url = new URL(getImageUrlAtWidth(100, image));
	url.searchParams.append('blur', '100');
	return url.href;
}

const BLANK_PX =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

const Image = forwardRef(
	(
		{
			dimensions,
			url: originalUrl,
			alt,
			loading = 'lazy',
			sizes: originalSizes = null,
			naturalSizing = false,
			blur = true,
			...others
		},
		$ref
	) => {
		const url = new URL(originalUrl).href;
		const $preview = useRef(null);
		const $picture = useRef(null);
		const $element = $ref || $picture;
		const { isClient } = useGlobalContext();
		const [ isLoaded, setLoaded ] = useState(false);
		const [ isPreviewVisible, setPreviewVisible ] = useState(true);
		const [ sizes, setSizes ] = useState(originalSizes);
		const srcSet = getSrcSet({ url, dimensions });
		const previewSrc = getPreviewUrl({ url, dimensions });
		const paddingBottom = `${ (dimensions.height / dimensions.width) * 100 }%`;

		if (!alt) alt = 'Casa Eminente Image';

		useEffect(() => {
			const observer = new ResizeObserver(([ entry ]) => {
				setSizes(`${ Math.round(entry.contentRect.width) }px`);
			});

			if ($element.current && originalSizes === null) {
				observer.observe($element.current);
			} else {
				setSizes(originalSizes);
			}

			return () => observer.disconnect();
		}, [ $element, setSizes, originalSizes ]);

		const onPreviewTransitionEnd = useCallback(() => {
			setPreviewVisible(false);
		}, [ setPreviewVisible ]);

		const onLoad = useCallback(
			(event) => {
				if (event.target.currentSrc === BLANK_PX) {
					return;
				}

				if ($preview.current) {
					$preview.current.addEventListener('transitionend', onPreviewTransitionEnd);
				}

				setLoaded(true);
			},
			[ onPreviewTransitionEnd ]
		);

		return (
			<motion.picture ref={$element} {...others} data-loaded={isLoaded}>
				{!naturalSizing && <span style={{ paddingBottom }} />}
				<Img
					key={isClient ? 'client' : 'server'}
					isAbsolute={!naturalSizing}
					src={BLANK_PX}
					srcSet={sizes ? srcSet : null}
					data-srcset={!sizes ? srcSet : null}
					sizes={sizes}
					onLoad={onLoad}
					loading={loading}
					width={dimensions.width}
					height={dimensions.height}
					alt={alt}
				/>
				{blur && isPreviewVisible && (
					<Blurred
						className={classNames(isPreviewVisible, 'blurred')}
						ref={$preview}
						src={previewSrc}
						alt={alt}
						loading="eager"
					/>
				)}
			</motion.picture>
		);
	}
);

Image.displayName = 'Image';

const Img = styled.img`
  position: ${ ({ isAbsolute }) => (isAbsolute ? 'absolute' : null) };
  top: ${ ({ isAbsolute }) => (isAbsolute ? 0 : null) };
  left: ${ ({ isAbsolute }) => (isAbsolute ? 0 : null) };
  width: ${ ({ isAbsolute }) => (isAbsolute ? '100%' : null) };
  height: ${ ({ isAbsolute }) => (isAbsolute ? '100%' : null) };
  object-fit: cover;
  object-position: center center;
  z-index: 0;
`;

const Blurred = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.8s;

  [data-loaded='true'] & {
    opacity: 0;
  }
`;

export default styled(Image)`
  position: relative;
  display: block;

  & > span {
    display: block;
  }
`;
