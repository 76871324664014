import React, { createContext, useContext } from 'react';

const DEFAULT_LANG = 'fr-FR';

export const PageContext = createContext({
	uid: null,
	lang: DEFAULT_LANG,
});

export const PageProvider = ({ children, pageContext }) => {
	return <PageContext.Provider value={pageContext}>{children}</PageContext.Provider>;
};

export default PageProvider;

export const usePageContext = () => {
	const context = useContext(PageContext);
	if (!context) throw Error('usePageContext must be used inside a `PageProvider`');
	return context;
};
