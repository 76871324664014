import React from 'react';
import styled, { css } from 'styled-components';

import { rem, mixins, colors, spacing, typo } from '$styles';
import { Icon } from '$components/image';
import propsToDom from '$utils/propsToDom';

const InputCSS = css`
  font: inherit;
  appearance: none;
  border: none;
  background: transparent;
  outline: none;
  margin: 0;
  width: 100%;
  color: inherit;
  line-height: inherit;
`;

// ------------------------------------------------------------
//    CONTAINER
// ------------------------------------------------------------
const ContainerComponent = ({ error = false, children, ...others }) => (
	<div {...others} data-error={error}>
		{children}
		<Icon id="line-horizontal" freeScale />
	</div>
);

export const Container = styled(ContainerComponent)`
  display: block;
  position: relative;
  padding: 0 ${ rem(5) };

  input::placeholder,
  .placeholder {
    color: inherit;
  }

  .icon-line-horizontal {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 3px;
    color: var(--form-color, ${ colors.green });
    pointer-events: none;
  }

  &[data-error='true'] {
    .icon-line-horizontal {
      color: ${ colors.red };
    }

    &:after {
      content: attr(data-error-text);
      ${ typo.smallCaps }
      position: absolute;
      right: 0;
      top: 100%;
      text-transform: uppercase;
      color: ${ colors.red };
    }
  }
`;

export const SelectContainer = styled(Container)`
  display: inline-block;
  position: relative;
  padding-right: ${ rem(44) };

  .icon-arrow-dropdown {
    font-size: ${ rem(18) };
    position: absolute;
    top: calc(50%);
    right: ${ rem(14) };
    margin-top: ${ rem(-6) };;
    pointer-events: none;
    color: ${ colors.green };
  }

  p {
    pointer-events: none;
  }
`;

// ------------------------------------------------------------
//    INPUT
// ------------------------------------------------------------
const InputComponent = ({ error = false, errorText = null, ...props }) => (
	<Container error={error} data-error-text={errorText}>
		<input {...propsToDom(props)} />
	</Container>
);

export const Input = styled(InputComponent)`
  ${ InputCSS }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
  }
`;

// ------------------------------------------------------------
//    Select
// ------------------------------------------------------------
const SelectComponent = ({ error = false, placeholder, options, ...props }) => {
	const label = options.find(({ value }) => value === props.value)?.label;

	return (
		<SelectContainer error={error}>
			<select {...propsToDom(props)}>
				{options.map(({ value, label }) => (
					<option key={value} value={value}>
						{label}
					</option>
				))}
			</select>
			<p className={!label ? 'placeholder' : null}>{label || placeholder}</p>
			<Icon id="arrow-dropdown" />
		</SelectContainer>
	);
};

export const Select = styled(SelectComponent)`
  appearance: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
`;

// ------------------------------------------------------------
//    RADIO
// ------------------------------------------------------------
export const RadioComponent = ({ label, className, ...others }) => (
	<div className={className}>
		<input type="radio" {...propsToDom(others)} />
		<label htmlFor={others?.id}>{label}</label>
	</div>
);

export const Radio = styled(RadioComponent)`
  display: inline-block;

  & + & {
    margin-left: ${ spacing.s3 };
  }

  input {
    opacity: 0;
    position: absolute;
  }

  label {
    position: relative;
    display: block;
    padding-left: ${ rem(16 + 10) };

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      border-radius: 50%;
    }

    &:before {
      width: ${ rem(16) };
      height: ${ rem(16) };
      transform: translateY(-50%);
      box-shadow: inset 0 0 0 1px var(--form-color, ${ colors.red });
    }

    &:after {
      width: ${ rem(10) };
      height: ${ rem(10) };
      left: ${ rem(8) };
      transform: translate(-50%, -50%);
      background: var(--form-color, ${ colors.red });
      opacity: 0;
    }

    ${ mixins.hasHover(css`
			&:hover:after {
					opacity: 0.5;
			}
		`) }
  }

  input:checked ~ label:after {
    opacity: 1;
  }
`;

// ------------------------------------------------------------
//    FIELD
// ------------------------------------------------------------
const FieldComponent = ({ label, htmlFor = null, children, ...others }) => (
	<div {...others}>
		<label htmlFor={htmlFor}>{label}</label>
		{children}
	</div>
);

export const labelCSS = css`
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: ${ typo.fontWeight.medium };
`;

export const Field = styled(FieldComponent)`
  & > label {
    ${ labelCSS }
  }
`;
