import { graphql, useStaticQuery } from 'gatsby';

export default () => useStaticQuery(graphql`
# BEGIN_GRAPHQL
{
	allPrismicSite {
		edges {
			node {
				_previewable
				lang
				data {

					# ----- SEO ------
					seo_title_suffix
					seo_title
					seo_description
					seo_twitter_site
					seo_image {
						url
						dimensions {
							width
							height
						}
					}

					# ---- HEADER -----
					header_nav {
						nav_link {
							target
							url
							type
							id
						}
						nav_text
					}
					header_adress_link {
						url
						type
						target
						id
					}
					header_homepage {
						url
						type
						id
					}
					header_address
					header_opening_hours
					header_booking_link {
						url
						type
						target
						id
					}

					# ---- FOOTER ------
					footer_nav {
						nav_text
						nav_link {
							url
							target
							type
							id
						}
					}
					footer_map {
						alt
						url
						dimensions {
							height
							width
						}
					}
					footer_eminente_text {
						html
					}
					footer_eminente_cta_text
					footer_eminente_cta_link {
						url
						type
						target
						id
					}
					footer_cookies
					footer_contact_text {
						html
					}
					footer_signature
					footer_eminente_image {
						url
						dimensions {
							height
							width
						}
						alt
					}

					# ------ I18N ------
					i18n_book_cta
					i18n_close
					i18n_drag
					i18n_coming_soon
					i18n_join_eminente_club

					# --- EMINENTE CLUB PUSH ---
					push_club_cta_text
					push_club_cta_title {
						text
					}
					push_club_text
					push_club_title {
						text
					}

					# ------- CRM ------
					crm_timer
					crm_title {
            text
          }
          crm_introduction {
            html
          }
          crm_form_title_label
          crm_form_mr_label
          crm_form_mrs_label
          crm_form_firstname_label
          crm_form_firstname_placeholder
          crm_form_lastname_label
          crm_form_lastname_placeholder
          crm_form_email_label
          crm_form_email_placeholder
          crm_form_birthdate_label
          crm_form_birthday_label
          crm_form_birthmonth_label
          crm_form_birthyear_label
          crm_form_consent_text {
            html
          }
          crm_form_submit_text
          crm_success_message
          crm_error_message
          crm_required
				}
			}
		}
	}
}
# END_GRAPHQL
`);
