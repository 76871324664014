import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { useGlobalContext } from '$store';
import Image from './Image';
import classNames from 'classnames';

const ImageCoverComponent = forwardRef(({ className, ...props }, ref) => {
	const { ww, wh } = useGlobalContext();
	if (!props.dimensions || !props.url) return null;
	const ratio = props.dimensions.width / props.dimensions.height;
	const sizes = ww && wh ? `${ Math.max(wh * ratio, ww) >> 0 }px` : null;
	className = classNames(className, 'image-cover');
	return <Image
		ref={ref}
		{...props}
		className={className}
		naturalSizing={true}
		sizes={sizes}
		role="presentation"
	/>;
});

ImageCoverComponent.displayName = 'ImageCoverComponent';

export const ImageCover = styled(ImageCoverComponent)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
`;
