import { usePageContext } from '$store/PageProvider';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import { mixins } from '$styles';

import { graphql } from 'gatsby';
import { useIsomorphicLayoutEffect } from 'react-use';

export const query = graphql`
  fragment Base_Home on PrismicPageHome { _previewable id uid }
	fragment Base_Standby on PrismicPageStandby { _previewable id uid }
	fragment Base_Rich on PrismicPageRich { _previewable id uid }
	fragment Base_Document on PrismicPageDocument { _previewable id uid }
	fragment Base_Menu on PrismicPageMenu { _previewable id uid }
	fragment Base_Booking on PrismicPageBooking { _previewable id uid }
	fragment Base_404 on PrismicPage404 { _previewable id uid }
`;

const PageContainerComponent = ({ className, children }) => {
	const data = usePageContext();

	const pageClass = data.pageType.replace(/_/g, '-');
	className = classNames(className, 'page', pageClass);

	const [ getRefresher, setRefresher ] = useState(0);

	useIsomorphicLayoutEffect(() => {
		if (getRefresher > 0) return;
		setTimeout(() => setRefresher(getRefresher + 1), 1);
	});

	return (
		<section className={className} key={ pageClass + '-' + getRefresher }>
			{children}
		</section>
	);
};

export const PageContainer = styled(PageContainerComponent)`
	${ mixins.headerPadding }
`;
