import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import withPrismicPreview from '$utils/withPrismicPreview';

import { mixins } from '$styles';

import { SEO } from '$components/global/SEO';
import { PageContainer as _PageContainer } from '$components/global/PageContainer';
import SliceZone from '$components/global/SliceZone';

export const pageQuery = graphql`
  query ($uid: String!, $lang: String!) {
		prismicPageHome(uid: { eq: $uid }, lang: { eq: $lang }) {
			...Base_Home
			data {
				...SEO_Home
				body {
					...Hero_Home
					...Marquee_Home
					...DuoImages_Home
					...Club_Home
					...Events_Home
					...Handwritten_Home
					...Tabs_Home
					...Experiences_Home
					...Casa_Home
				}
			}
		}
	}
`;

export default withPrismicPreview(({ className, ...props }) => {
	const data = props.data?.prismicPageHome?.data || {};
	return (
		<PageContainer className={className} data={data}>
			<SEO {...data} />
			<SliceZone {...data} />
		</PageContainer>
	);
});

const PageContainer = styled(_PageContainer)`
`;

