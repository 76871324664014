import React, { useCallback } from 'react';
import { useIsomorphicLayoutEffect, usePrevious } from 'react-use';
import { AnimatePresence } from 'framer-motion';

import { useGlobalContext } from '$store';
import { Modal } from './Modal';
import { ModalBook } from './ModalBook';
import { ModalClub } from './ModalClub';
import { ModalCarte } from './ModalCarte';

export const ModalManager = () => {
	const {
		modal,
		setScrollEnabled,
		setSavedScrollTop
	} = useGlobalContext();

	const previousModal = usePrevious(modal);
	const getModal = useCallback(() => {
		const options = modal?.options || {};
		if (!modal || !modal.id) return null;
		switch (modal?.id) {
			case 'book': return <ModalBook {...options} />;
			case 'club': return <ModalClub {...options} />;
			case 'carte': return <ModalCarte {...options} />;
			default: return <Modal fullWidth>-</Modal>;
		}
	}, [ modal ]);

	// Manage scroll enabled for modals
	useIsomorphicLayoutEffect(() => {
		if (previousModal == null) {
			const { scrollTop } = document.scrollingElement || document.documentElement;
			setSavedScrollTop(scrollTop);
		}
		setScrollEnabled(!modal);
	}, [ modal ]);

	return <AnimatePresence>{ getModal() }</AnimatePresence>;
};
