export default {
	linear: [ 0.25, 0.25, 0.75, 0.75 ],
	quadIn: [ 0.55, 0.085, 0.68, 0.53 ],
	cubicIn: [ 0.55, 0.055, 0.675, 0.19 ],
	quartIn: [ 0.895, 0.03, 0.685, 0.22 ],
	quintIn: [ 0.755, 0.05, 0.855, 0.06 ],
	sineIn: [ 0.47, 0.0, 0.745, 0.715 ],
	expoIn: [ 0.95, 0.05, 0.795, 0.035 ],
	circIn: [ 0.6, 0.04, 0.98, 0.335 ],
	backIn: [ 0.6, -0.28, 0.735, 0.045 ],
	quadOut: [ 0.25, 0.46, 0.45, 0.94 ],
	cubicOut: [ 0.215, 0.61, 0.355, 1.0 ],
	quartOut: [ 0.165, 0.84, 0.44, 1.0 ],
	quintOut: [ 0.23, 1.0, 0.32, 1.0 ],
	sineOut: [ 0.39, 0.575, 0.565, 1.0 ],
	expoOut: [ 0.19, 1.0, 0.22, 1.0 ],
	circOut: [ 0.075, 0.82, 0.165, 1.0 ],
	backOut: [ 0.175, 0.885, 0.32, 1.275 ],
	quadInOut: [ 0.455, 0.03, 0.515, 0.955 ],
	cubicInOut: [ 0.645, 0.045, 0.355, 1.0 ],
	quartInOut: [ 0.77, 0.0, 0.175, 1.0 ],
	quintInOut: [ 0.86, 0.0, 0.07, 1.0 ],
	sineInOut: [ 0.445, 0.05, 0.55, 0.95 ],
	expoInOut: [ 1.0, 0.0, 0.0, 1.0 ],
	circInOut: [ 0.785, 0.135, 0.15, 0.86 ],
	backInOut: [ 0.68, -0.55, 0.265, 1.55 ],
	principle: [ 0.25, 0.1, 0, 1 ],
};
