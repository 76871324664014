import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import styled, { css } from 'styled-components';

import { colors, typo, rem, mixins, media } from '$styles';
import { useParallax } from '$hooks';
import { useGlobalContext, usePageContext } from '$store';
import clamp from '$utils/clamp';

import { Illustration, ImageCover } from '$components/image';
import { PrismicRich, PrismicSpan, PrismicText, Title1 } from '$components/text';
import isEmpty from '$utils/isEmpty';
import { Parallax } from '$components/global/Parallax';
import { Frame } from '$components/image/Frame';
import { Link } from './Link';
import { CTA } from '$components/form/CTA';


const CardComponent = ({
	className,
	parallax = 0,
	green,
	...data
}) => {
	className = classNames(className, {
		'card': true,
		'card-green': !!green
	});

	return <Parallax strength={parallax}>
		<section className={className}>
			{
				!green
				&& <Illustration className="card-illus" color="green" id='crocodile' />
			}
			<Frame />
			<PrismicText className="card-title" tag="h2" text={data.title} />
			<div className="card-content">
				<PrismicRich text={data.text} />
				{
					data.minorCtaLink
					&& <Link {...data.minorCtaLink} className="card-cta-secondary">
						<PrismicSpan text={data.minorCtaText} />
					</Link>
				}
			</div>
			{
				data.ctaLink
				&& <div className="card-cta-primary">
					<CTA
						color={ green ? 'dust' : 'green'}
						{...{ ctaLink: data.ctaLink, ctaText: data.ctaText }}
					/>
				</div>
			}
		</section>
	</Parallax>;
};

export const Card = styled(CardComponent)`
	position: relative;
	background: ${ colors.dust };
	${ mixins.padding(0.5, 0.6, 0.5, 0.6, false) }
	margin-top: ${ rem(90) };

	${ media.mobile`
		${ mixins.padding(0.5, 1, 0.4, 1, false) }
		margin-top: ${ rem(70) };
	` }

	.card-title {
		${ typo.h2 }
		font-size: ${ rem(50) }; // 60 fontsize_update
		${ media.mobile` font-size: ${ rem(35) };` } // 40 fontsize_update
		${ media.smallMobile` font-size: ${ rem(32) };` } // 36 fontsize_update
		max-width: 9em;
		margin-bottom: 0.45em;
	}

	.card-content {
		${ typo.text }
		max-width: 30em;
		padding-left: 1.5em;

		ul {
			margin-top: 1em;
			text-transform: uppercase;
			font-size: 0.875em;
			letter-spacing: 0.08ch;
		}

		li {
			padding: 0.75em 0;
			border-bottom: 1px solid currentColor;
			max-width: 73%;
		}
	}

	.card-cta-primary {
		margin-top: ${ rem(35) };
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-end;
		justify-content: flex-end;
	}

	.card-cta-secondary {
		${ typo.h3 }
		${ mixins.underline(colors.black) }
		display: inline-block;
		margin-top: 1em;
		transition: opacity 300ms;

		${ mixins.hasHover(css`
			&:hover {
				opacity: 0.5;
			}
		`) }
	}

	.card-illus {
		position: absolute;
		top: 0;
		--width: ${ rem(140) };
		right: calc(var(--width) * -0.03);
		margin-top: calc(var(--height) * -0.65);

		${ media.mobile`
		--width: ${ rem(105) };
		` }
	}

	&.card-green {
		background: ${ colors.green };
		color: ${ colors.dust };

		.card-cta-secondary {
			${ mixins.underline(colors.dust) }
		}
	}
`;
