import { graphql } from 'gatsby';

import React from 'react';
import styled from 'styled-components';

import { rem, typo, colors, media, mixins } from '$styles';

import { PrismicRich, PrismicText } from '$components/text';
import { Icon, Illustration, Image, ImageRatio } from '$components/image';
import { Button } from '$components/form/Button';
import { useGlobalContext } from '$store/GlobalProvider';

export const query = graphql`
  fragment Club_Home on PrismicPageHomeDataBodyClub {
		id slice_label slice_type
		primary {
			club_image { alt dimensions { height width } url }
			club_title { text }
			club_text { html }
			club_cta_text
		}
	}
`;

const HomeClubComponent = ({ className, ...data }) => {
	const { setModal } = useGlobalContext();
	const image = data?.primary?.club_image;
	const title = data?.primary?.club_title || '';
	const text = data?.primary?.club_text || '';
	const ctaText = data?.primary?.club_cta_text;

	return <div className={className}>
		<div className="col col-left">
			<div className="img">
				{/* <Illustration id="distillery" parallax={{ strength: 2 }} /> */}
				{ image && <ImageRatio className="club-image" {...image} /> }
				<Illustration id="eminente-club" parallax={{ strength: 1 }} />
			</div>
		</div>
		<div className="col col-right">
			<PrismicText className="title" tag="h2" text={ title } />
			<div className="content-wrapper">
				<PrismicRich className="text" text={text} />
				<Button
					className="cta"
					color="dust"
					background="medium"
					onClick={() => setModal({ id: 'club' })}
				>
					{ctaText}
				</Button>
				<Icon id="crocodile" />
			</div>
		</div>
	</div>;
};

export const HomeClub = styled(HomeClubComponent)`
	${ mixins.bgGreen }
	${ mixins.padding(1, 1, 1, 1, true) }
	${ typo.fontTitle }

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;

 	.col-left {
		padding: 0 6% 0 3%;
		width: 55%;
	}

	.col-right {
		width: 45%;
	}

	.club-image {
		position: relative;
		z-index: 1;
	}

	${ media.mobile`
		display: block;
		.col { width: 100%; }
		.col-left { padding: 0 16%; }
		.col-right { margin-top: ${ rem(50) }; }
		.image-ratio { --ratio: 1.4; }
	` }

	.img {
		position: relative;
		/* transform: translateX(10%); */
	}

	.img .illus-distillery {
		--width: ${ rem(180) };
		left: calc(var(--width) * -0.5);
		top: calc(var(--height) * -0.2);
		position: absolute;
		z-index: 2;

		${ media.mobile`
			--width: 30vw;
		` }
	}

	.img .illus-eminente-club {
		--width: ${ rem(150) };
		left: calc(var(--width) * -0.45);
		top: calc(var(--height) * -0.32);
		/* right: calc(var(--width) * -0.5);
		bottom: calc(var(--height) * -0.3); */
		position: absolute;
		z-index: 2;

		${ media.mobile`
			--width: 26vw;
		` }
	}

	.title {
		text-transform: uppercase;
		${ typo.h2 }
		max-width: 6em;
		font-weight: ${ typo.fontWeight.medium };
		margin-bottom: 0.4em;
		line-height: 1.3em;
	}

	.content-wrapper {
		position: relative;
		padding-left: ${ rem(50) };

		${ media.mobile`
			padding-left: ${ rem(20) };
			max-width: 40em;
		` }
	}

	.content-wrapper .icon-crocodile {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 160%;
		opacity: 0.1
	}

	.text {
		position: relative;
		z-index: 1;
		font-size: ${ rem(22) };
		font-weight: ${ typo.fontWeight.medium };

		p {
			line-height: 1.3em;
			max-width: 35em;
		}

		ul {
			margin-top: 1em;
		}

		li {
			${ typo.text }
			position: relative;
			line-height: 1.6em;
			max-width: 30em;
			/* text-transform: uppercase; */
			margin-left: 1.8em;

			&:not(:first-child) {
				margin-top: 0.8em;
			}
		}

		li:not(:last-of-type) {
			margin-bottom: 0.5em;

		}

		li:before {
			content: '';
			width: 0.85em;
			height: 0.85em;
			position: absolute;
			${ mixins.bgBullet };
			background-size: contain;
			top: 0.4em;
			left: -1.85em;
		}
	}

	.cta {
		position: relative;
		pointer-events: all;
		z-index: 2;
		${ typo.fontText }
		margin-top: 2.5em;
	}

`;

HomeClub.sliceType = 'club';
