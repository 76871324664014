import React from 'react';
import { useLocalStorage } from 'react-use';
import styled from 'styled-components';
import classNames from 'classnames';

import { usePageContext } from '$store';
import { media, mixins, colors, z, rem } from '$styles';
import { Icon } from '$components/image';
import { Link } from '$components/global/Link';
import { LinkUnderline } from '$components/text';


const FooterLangComponent = ({ className }) => {
	const { hreflang, lang: currentLang } = usePageContext();
	const [ , setChoosenLocale ] = useLocalStorage('user-selected-locale');

	const handleClick = (locale) => {
		setChoosenLocale(locale);
	};

	className = classNames(className, 'footer-lang');

	if (hreflang.length <= 1) return null;

	return <section className={className}>
		<nav>
			<ul>
				{hreflang.map(({ lang, url, others }) => {
					const [ locale ] = lang.split('-');
					return (
						<li key={lang}>
							<LinkUnderline
								to={url}
								onClick={() => handleClick(locale)}
								isActive={currentLang === lang}
							>
								{locale}
							</LinkUnderline>
						</li>
					);
				})}
			</ul>
		</nav>
	</section>;
};

export const FooterLang = styled(FooterLangComponent)`
	${ mixins.padding(0.2, 0, 0.2, 0, true) }

	padding-left: ${ rem(40) };
	${ media.smallFooter`
	padding-left: ${ rem(20) };
	` }

	li {
		display: inline-block;
	}

	li:not(:last-child) {
		margin-right: 1em;
	}

	li > * {
		display: inline-block;
	}
`;
