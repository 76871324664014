import { css } from 'styled-components';
import easings from './easings';

export default {
	link: css`
		transition-duration: 0.4s;
		transition-timing-function: ${ easings.principle };
	`,

	fillHighlight: css`
		transition-property: color, background;
		transition-duration: 0.2s;
		transition-timing-function: ${ easings.principle };
	`
};
