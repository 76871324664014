import { graphql } from 'gatsby';

import React from 'react';
import styled from 'styled-components';

import { rem, typo, colors, media, mixins } from '$styles';
import { PrismicRich, PrismicText } from '$components/text';
import { Icon, Illustration, Image, ImageCover } from '$components/image';
import { SectionTitle } from '$components/text/SectionTitle';
import { Slider } from '$components/global/Slider';
import { SmallBoxText } from '$components/text/SmallBoxText';
import { usePageContext } from '$store';
import formatDate from '$utils/formatDate';

const getDate = str => {
	if (!str) return null;
	const a = str.split('-');
	if (a.length !== 3) return null;
	return new Date(a[ 0 ], a[ 1 ] - 1, a[ 2 ]);
};

export const query = graphql`
	fragment Events_Home on PrismicPageHomeDataBodyEvents {
		id slice_label slice_type
		primary {
			events_title { text }
		}
		items {
			event_text { html }
			event_date
			event_image { alt url dimensions { height width } }
		}
	}
`;

const HomeEvent = ({ text, image, date }) => {
	const { lang } = usePageContext();
	return (
		<div className="event">
			<div className="event-image"><ImageCover {...image} /></div>
			<SmallBoxText className="event-date" text={date} />
			<PrismicRich text={text} />
		</div>
	);
};

const HomeEventsComponent = ({ className, ...data }) => {
	const title = data?.primary?.events_title;

	const events = (data.items || [])
		.map(v => ({
			date: v.event_date,
			image: v.event_image,
			text: v.event_text
		}))
		.map((props, i) => <HomeEvent {...props} key={i} />);

	if (events.length <= 0) return null;

	return <div className={className}>
		{ title && <SectionTitle text={title} parallax/> }
		<Slider className="events">{ events }</Slider>
	</div>;
};

export const HomeEvents = styled(HomeEventsComponent)`
	${ mixins.padding(1, 0, 1.2, 0, true) }

	h2 {
		overflow-wrap: break-word;
		hyphens: auto;
	}

	.section-title {
		${ mixins.margin(0, 1, 0, 1, true) }
	}

	.events {
		margin-top: ${ rem(40) };
		${ media.mobile`margin-top: ${ rem(20) };` }
	}

	.event {
		.event-image {
			position: relative;
			top: auto;
			left: auto;
			width: 100%;
			height: ${ rem(500) };
			margin-bottom: ${ rem(20) };

			${ media.mobile`
				height: ${ rem(300) }
			` }
		}


		.event-date {
			margin-bottom: 1.5em;
		}

		h3 {
			${ typo.h3 }
			margin-bottom: 0.4em;
			${ media.mobile`font-size: ${ rem(20) }` }
			max-width: 16em;
		}

		p {
			${ typo.text }
			max-width: 24.5em;
		}
	}
`;

HomeEvents.sliceType = 'events';
