import React, { forwardRef } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';

import { useGlobalContext, usePageContext } from '$store';
import propsToDom from '$utils/propsToDom';
import { buttonReset } from '$styles';
import openPopup from '$utils/openPopup';

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const LinkComponent = (
	{
		children,
		to,
		activeClassName = 'is-active',
		isBroken = false,
		prismic,
		url,
		type,
		id,
		...others
	},
	ref
) => {
	const { setModal } = useGlobalContext();
	const { url: currentUrl } = usePageContext();

	if (url && !to) {
		to = url;
	}

	if (!url && to) {
		url = to;
	}

	if (prismic && prismic.url) {
		to = prismic.url;
		if (prismic.target) others.target = prismic.target;
	}

	if (type === 'page_menu') {
		const ogOnClick = others.onClick;
		others.onClick = e => {
			e.preventDefault();
			if (ogOnClick) ogOnClick(e);
			setModal({ id: 'carte', options: {
				previousUrl: currentUrl,
				url,
				id
			} });
		};
	} else if (type === 'page_booking') {
		const ogOnClick = others.onClick;

		others.onClick = e => {
			e.preventDefault();
			if (currentUrl === url) return;
			if (ogOnClick) ogOnClick(e);
			setModal({ id: 'book', options: {
				previousUrl: currentUrl,
				url,
				id
			} });
		};
	}

	// if (!type && to && to.includes('bookings.zenchef')) {
	// 	const ogOnClick = others.onClick;
	// 	others.onClick = e => {
	// 		e.preventDefault();
	// 		if (ogOnClick) ogOnClick(e);
	// 		openPopup(to);
	// 	};
	// }

	if (!to || isBroken) {
		return <button {...propsToDom(others)}>{children}</button>;
	}

	// Tailor the following test to your environment.
	// This example assumes that any internal link (intended for Gatsby)
	// will start with exactly one slash, and that anything else is external.
	const internal = /^\/(?!\/)/.test(to);

	// Use Gatsby Link for internal links, and <a> for others
	if (internal) {
		return (
			<GatsbyLink
				ref={ref}
				to={to}
				activeClassName={activeClassName}
				onClick={others.onClick}
				{...propsToDom(others)}
			>
				{children}
			</GatsbyLink>
		);
	}

	return (
		<a
			ref={ref}
			href={to}
			{...propsToDom(others)}
			rel={others.target === '_blank' ? 'noopener' : null}
		>
			{children}
		</a>
	);
};

export const Link = styled(forwardRef(LinkComponent))`
  ${ buttonReset }
`;
