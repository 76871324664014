import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { rem, typo, colors, mixins, media } from '$styles';

import { PrismicSpan, PrismicText } from '$components/text';
import { Icon } from '$components/image';
import classNames from 'classnames';
import { Parallax } from '$components/global/Parallax';

export const query = graphql`
  fragment Handwritten_Home on PrismicPageHomeDataBodyHandwritten {
		id slice_label slice_type
		items {
			handwritten_star_left
			handwritten_star_right
			handwritten_text
		}
	}

	fragment Handwritten_Rich on PrismicPageRichDataBodyHandwritten {
		id slice_label slice_type
		items {
			handwritten_star_left
			handwritten_star_right
			handwritten_text
		}
	}
`;

const HandwrittenComponent = ({ className, ...data }) => {
	const texts = (data?.items || []).map(v => ({
		hasLeftStar: !!v.handwritten_star_left,
		hasRightStar: !!v.handwritten_star_right,
		text: v.handwritten_text || null
	})).filter(v => v.text != null && v.text.length > 0);

	className = classNames(className, 'handwritten-text');

	if (texts.length <= 0) return null;
	return <Parallax strength={2}>
		<section className={ className }>
			<div className="rotate">
				{ texts.map(({ hasLeftStar, hasRightStar, text }, i) => (
					<p key={text + '_' + i}>
						{ hasLeftStar && <Icon id="star-small" className="left" /> }
						<PrismicSpan text={ text } />
						{ hasRightStar && <Icon id="star-small" className="right" /> }
					</p>
				)) }
			</div>
		</section>
	</Parallax>;
};

export const Handwritten = styled(HandwrittenComponent)`
	${ mixins.padding(0.5, 1.5, 0.5, 1.5, true) }
	${ typo.fontHandwritten }
	color: ${ colors.green };
	line-height: 1.3em;
	font-size: ${ rem(54) };
	font-weight: 900;

	.rotate {
		transform-origin: top left;
		transform: rotate(-3deg);
	}

	p {
		max-width: 14em;
	}

	p:not(:first-child) {
		margin-left: 2.5em;
	}

	.icon-star-small {
		font-size: 0.6em;
		margin-top: 0.5em;
	}

	p:first-child .icon-star-small {
		margin-top: -0.4em;
	}

	.icon-star-small.left {
		margin-right: -1em;
		transform: translateX(-1.2em);
	}

	.icon-star-small.right {
		margin-left: 0.5em;
	}

	${ media.mobile`
	${ mixins.padding(1, 1, 0.5, 2.5, true) }
		font-size: min(6.6vw, ${ rem(36) });
		p:not(:first-child) { margin-left: 0.8em; }
	` }
`;
