import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useMeasure } from 'react-use';
import { useInView } from 'react-intersection-observer';

import { useGlobalContext } from '$store';

export function clampedMap(value, start1, stop1, start2, stop2) {
	const v = start2 + (stop2 - start2) * ((value - start1) / (stop1 - start1));
	return Math.max(start2, Math.min(stop2, v));
}

const BaseMarqueeComponent = ({
	children,
	isReversed = false,
	duration = 11,
	separator = null,
	...others
}) => {
	const { ww } = useGlobalContext();
	const [ clones, setClones ] = useState(0);

	const [ modDuration, setModDuration ] = useState(duration);

	const [ $element, { width } ] = useMeasure();
	const [ $main, isPlaying ] = useInView({ threshold: 0 });

	useEffect(() => {
		if (ww > 0 && width > 0) {
			setClones(Math.ceil(ww / width) + 1);
		}
		if (ww > 0) {
			const mult = clampedMap(ww, 550, 900, 0.6, 1);
			setModDuration(duration * mult);
		} else {
			setModDuration(duration);
		}
	}, [ ww, width ]);

	const props = {
		isReversed,
		isPlaying,
		duration: modDuration,
	};

	return (
		<div ref={$main} {...others} key={`loop-text-${ clones }`}>
			<Wrapper>
				<Item {...props} ref={$element}>
					{children}
					{separator}
				</Item>
				{new Array(clones).fill(0).map((_, i) => (
					<Item {...props} key={`clone-${ i }`}>
						{children}
						{separator}
					</Item>
				))}
			</Wrapper>
		</div>
	);
};

export const BaseMarquee = styled(BaseMarqueeComponent)`
  position: relative;
  overflow: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const Item = styled.div`
  @keyframes loop {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  display: inline-flex;
  flex-shrink: 0;
  animation-name: ${ ({ isReversed = false }) => (isReversed ? reverse : loop) };
  animation-duration: ${ ({ duration = 3 }) => `${ duration }s` };
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: ${ ({ isPlaying }) => (isPlaying ? 'running' : 'paused') };
  will-change: transform;
`;

const loop = keyframes`
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(-100%);
	}
`;

const reverse = keyframes`
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0);
}
`;
