import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import withPrismicPreview from '$utils/withPrismicPreview';
import { SEO } from '$components/global/SEO';
import { PageContainer as _PageContainer } from '$components/global/PageContainer';
import SliceZone from '$components/global/SliceZone';

import { mixins } from '$styles';

export const pageQuery = graphql`
  query ($uid: String!, $lang: String!) {
		prismicPageRich(uid: { eq: $uid }, lang: { eq: $lang }) {
			...Base_Rich
			data {
				...SEO_Rich
				body {
					...Hero_Rich
					...FloatingTrio_Rich
					...RestaurantChefCarte_Rich
					...Chefs_Rich
					...PushClub_Rich
					...PushExplore_Rich
					...CardImage_Rich
					...DuoImages_Rich
					...ImageTextGreen_Rich
					...ImageText_Rich
					...TextSlider_Rich
					...ImagesSlider_Rich
					...Handwritten_Rich
					...Quote_Rich
					...ImagesGrid_Rich
				}
			}
		}
	}
`;

export default withPrismicPreview(({ className, ...props }) => {
	const data = props.data?.prismicPageRich?.data || {};
	return (
		<PageContainer className={className} data={data}>
			<SEO {...data} />
			<SliceZone {...data} />
		</PageContainer>
	);
});

const PageContainer = styled(_PageContainer)`
`;

