import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import { mixins, rem, typo, colors, media } from '$styles';
import { PrismicRich } from '$components/text';
import { ImageRatio } from '$components/image';
import { SectionTitle } from '$components/text/SectionTitle';
import { Card } from '$components/global/Card';

export const query = graphql`
  fragment RestaurantChefCarte_Rich on PrismicPageRichDataBodyRestaurantChefCarte {
		id slice_label slice_type
		primary {
			ctaLink: restaurant_carte_book_cta_link { url target type id }
			ctaText: restaurant_carte_book_cta_text
			minorCtaLink: restaurant_carte_cta_link { url target type id }
			minorCtaText: restaurant_carte_cta_text
			text: restaurant_carte_text { html }
			title: restaurant_carte_title { text }
			chefCaption: restaurant_chef_caption { html }
			chefPhoto: restaurant_chef_image { url dimensions { height	width } alt }
			chefText: restaurant_chef_text { html }
			chefTitle: restaurant_chef_title { text }
		}
	}
`;
const RestaurantChefCarteComponent = ({ className, ...data }) => {
	className = classNames(className, 'restaurant-chef-carte');

	data = data?.primary || {};
	if (data.restaurant_carte_book_cta_link) data.ctaLink = data.restaurant_carte_book_cta_link;
	if (data.restaurant_carte_book_cta_text) data.ctaText = data.restaurant_carte_book_cta_text;
	if (data.restaurant_carte_cta_link) data.minorCtaLink = data.restaurant_carte_cta_link;
	if (data.restaurant_carte_cta_text) data.minorCtaText = data.restaurant_carte_cta_text;
	if (data.restaurant_carte_text) data.text = data.restaurant_carte_text;
	if (data.restaurant_carte_title) data.title = data.restaurant_carte_title;
	if (data.restaurant_chef_caption) data.chefCaption = data.restaurant_chef_caption;
	if (data.restaurant_chef_image) data.chefPhoto = data.restaurant_chef_image;
	if (data.restaurant_chef_text) data.chefText = data.restaurant_chef_text;
	if (data.restaurant_chef_title) data.chefTitle = data.restaurant_chef_title;

	return <section className={className}>
		<SectionTitle text={data.chefTitle} parallax />
		<section className="content">
			<section className="left">
				{ data.chefPhoto && <ImageRatio ratio="0.67" {...data.chefPhoto} /> }
				<PrismicRich className="chef-caption" text={data.chefCaption} />
			</section>
			<section className="right">
				<PrismicRich className="chef-text" text={data.chefText} />
				<Card { ...data} parallax="0.6"/>
			</section>
		</section>
	</section>;
};

export const RestaurantChefCarte = styled(RestaurantChefCarteComponent)`
	${ mixins.padding(1, 1, 0.5, 1, true) }
	.content{
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: flex-start;
	}

	.left {
		${ mixins.padding(0, 0.7, 0, 0.7, false) }
		flex: 0 0 auto;
		width: 42%;
	}

	.right {
		flex: 1 1 auto;
		${ mixins.padding(0.3, 0, 0, 0, false) }
		width: 100%;
	}

	.chef-caption {
		h3 {
			${ typo.h3 }
			margin-top: 0.6em;
			margin-bottom: 0.2em;
		}
		p {
			${ typo.capsSmaller }
			em {
				font-style: normal;
			}
		}
	}

	.chef-text {
		${ typo.text }
		max-width: 28em;
	}

	.card {
		margin-top : ${ rem(80) };
		margin-left: ${ rem(80) };
	}

	${ media.mobile`
		.content {
			display: block;
		}

		.left,
		.right {
			padding: 0;
			width: 100%;
		}

		.left {
			margin-bottom: ${ rem(20) };
		}

		.image-ratio {
			--ratio: 0.8;
		}

		.chef-caption {
			h3 {
				margin-top: 1em;
			}
		}

		.card {
			margin-top : ${ rem(85) };
			max-width: ${ rem(550) };
			margin-left: auto;
		}
	` }
`;

