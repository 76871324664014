import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import { useGlobalContext, usePageContext } from '$store';
import { spacing, mixins, rem, typo, colors, media } from '$styles';
import { PrismicRich, PrismicText } from '$components/text';
import { Icon, Illustration, ImageRatio } from '$components/image';
import { Button } from '$components/form/Button';
import { Parallax } from '$components/global/Parallax';
import { SectionTitle } from '$components/text/SectionTitle';
import { CTA } from '$components/form/CTA';

export const query = graphql`
  fragment PushExplore_Rich on PrismicPageRichDataBodyPushExplore {
		id slice_label slice_type
		primary {
			push_explore_title { text }
			push_explore_text { html }
			push_explore_illustration
			push_explore_image { url alt dimensions { height width } }
			push_explore_cta_link { url type target id }
			push_explore_cta_text
			push_explore_cta_only
		}
	}
	fragment PushExplore_Menu on PrismicPageMenuDataBody1PushExplore {
		id slice_label slice_type
		primary {
			push_explore_title { text }
			push_explore_text { html }
			push_explore_illustration
			push_explore_image { url alt dimensions { height width } }
			push_explore_cta_link { url type target id }
			push_explore_cta_text
			push_explore_cta_only
		}
	}
`;

export const PushExplore = ({ className, ...data }) => {
	data = data?.primary || {};
	className = classNames(className, 'push-explore');

	if (data.push_explore_cta_only) {
		className += ' push-explore-cta-only';
		return <PushExploreCtaOnly className={className}>
			<CTA className="cta" color="green" link={data.push_explore_cta_link} text={data.push_explore_cta_text} />
		</PushExploreCtaOnly>;
	}

	return <PushExploreStandard className={className}>
		<SectionTitle text={data.push_explore_title} offset />
		<div className="content">
			<div className="left">
				<div className="photo">
					{
						data.push_explore_image &&
						<Parallax strength="0.3">
							<ImageRatio {...data.push_explore_image} ratio={1.6288}/>
						</Parallax>
					}
					<Illustration id={data.push_explore_illustration} parallax="2.5" />
				</div>
			</div>
			<div className="right">
				<PrismicRich tag="h1" text={data.push_explore_text} />
				<CTA color="dust" link={data.push_explore_cta_link} text={data.push_explore_cta_text} />
			</div>
		</div>
	</PushExploreStandard>;
};

const PushExploreCtaOnly = styled.section`
	${ mixins.margin(-0.4, 1, 1, 1, true) }
	${ mixins.flexCenter }

	${ media.mobile`
		${ mixins.margin(-1.3, 1, 1, 1, true) }
	` }

	.cta {
		padding: ${ spacing.s2_5 } ${ spacing.s8 };
		font-size: max(15px, ${ rem(18) });
		${ media.mobile`
			font-size: ${ rem(15) };
			padding: ${ spacing.s2 } ${ spacing.s5 };
		` }
	}
`;

const PushExploreStandard = styled.section`
	${ mixins.bgGreen }
	${ mixins.padding(1, 1, 1.4, 1, true) }
	overflow: hidden;

	.content {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: stretch;
	}

	.left {
		${ mixins.padding(0, 2, 0, 1, true) }
		flex: 1 1 auto;
	}

	.right {
		flex: 0 0 auto;
		width: 33.3%;
		display: flex;
		flex-flow: column nowrap;
		align-items: flex-start;
		justify-content: flex-end;
	}

	.photo {
		position: relative;
	}

	.illus {
		position: absolute;
		--width: calc(${ rem(150) } * var(--size-mult, 1));
		bottom: calc(var(--height) * -0.4);
		right: calc(var(--width) / var(--size-mult, 1) * 0.25);
	}

	h2 {
		color: ${ colors.dust };
		max-width: 9.4em;
	}

	h3 {
		${ typo.h3 }
		max-width: 12em;
		margin-bottom: 1em;
	}

	p {
		${ typo.text }
		max-width: 35em;
		margin-bottom: 1em;
	}

	.cta {
		margin-top: 1em;
	}

	${ media.mobile`
		h2 {
			margin-bottom: 0.6em !important;
		}

		.content {
			flex-flow: column-reverse nowrap;
		}

		.left {
			width: 100%;
			padding: 0;
		}

		.right {
			${ mixins.padding(0, 1.5, 0, 1.5, true) }
			width: 100%;
		}

		h3 {
			max-width: 22em;
			margin-bottom: 1em;
		}

		p {
			max-width: 35em;
			margin-bottom: 1em;
		}

		.cta {
			margin: ${ rem(30) } auto ${ rem(50) };
		}

		.illus {
			position: absolute;
			--width: calc(22vw * var(--size-mult, 1));
			--height: calc(var(--width) / 0.6);
			bottom: calc(var(--height) * -0.4);
			right: calc(var(--width) / var(--size-mult, 1) * 0.35);
		}
	` }

	// Illus mults
	.illus-jungle { --size-mult: 1.3; }
`;

