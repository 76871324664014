import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { usePageContext } from '$store';
import { media, mixins, colors, z, rem } from '$styles';
import { Icon } from '$components/image';
import { Link } from '$components/global/Link';
import { LinkUnderline } from '$components/text';


const FooterSecondaryNavComponent = ({ className }) => {
	const { siteData: data } = usePageContext();
	className = classNames(className, 'footer-nav');

	const items = (data?.footer_nav || [])
		.map(v => ({ text: v.nav_text, link: v.nav_link }))
		.filter(({ link }) => !!link?.url);

	const cookies = data?.footer_cookies || 'Customize cookies';

	return <section className={className}>
		<nav>
			<ul>
				{ items.map(({ link, text }, i) => (
					<li key={i + '_' + link.url}>
						<LinkUnderline {...link} noSnippet>{text}</LinkUnderline>
					</li>
				)) }
				<li key="optanon">
					<LinkUnderline className="optanon-toggle-display" noSnippet={true}>
						{ cookies }
					</LinkUnderline>
				</li>
			</ul>
		</nav>
	</section>;
};

export const FooterSecondaryNav = styled(FooterSecondaryNavComponent)`
	${ mixins.margin(0.2, 0, 0.2, 0, true) }

	padding-left: ${ rem(40) };
	padding-right: ${ rem(40) };
	${ media.smallFooter`
	padding-left: ${ rem(25) };
	padding-right: ${ rem(25) };
	` }

	display: flex;
	flex-shrink: 1;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;

	li {
		display: inline-block;
	}

	li:not(:last-child) {
		margin-right: 1.3em;
	}

	li > * {
		display: inline-block;
	}

	${ media.mobile`
		li {
			margin: 0 1em;
			font-size: ${ rem(12) };
		}
	` }
`;
