import React from 'react';
import { cloneElement } from 'react';
import { useParallax } from '$hooks/useParallax';
import clamp from '$utils/clamp';
import { motion } from 'framer-motion';

export const ParallaxComponent = ({
	children,
	strength = 1,
	rotate,
	options = {}
}) => {
	if (Array.isArray(children)) children = children[ 0 ];

	const str = Math.abs(strength) / 10;
	const sign = strength >= 0 ? 1 : -1;
	const [ ref, y ] = useParallax((y, b) => {
		const max = b.h * str;
		return clamp(y * max * sign, -max, max);
	}, options);

	if (!children) return null;
	const cprops = children.props;

	const style = Object.assign(
		{},
		cprops.style,
		rotate ? { rotate: y } : { y },
	);

	if (typeof children.type === 'string') {
		const Tag = motion[ children.type ];
		children = <Tag {...children.props} {...{ ref, style }}/>;
	} else if (cprops?.tag && typeof cprops.tag === 'string') {
		const tag = motion[ cprops.tag ];
		children = cloneElement(children, { ref, style, tag });
	} else if (cprops?.component && typeof cprops.component === 'string') {
		const component = motion[ cprops.component ];
		children = cloneElement(children, { ref, style, component });
	} else {
		children = cloneElement(children, { ref, style });
	}

	return children;
};


export const Parallax = ({
	children,
	...props
}) => {
	if (!props.strength || props.strength === 0) return children || null;
	return <ParallaxComponent {...props}>{children}</ParallaxComponent>;
};
