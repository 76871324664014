import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useLifecycles } from 'react-use';
import { graphql, useStaticQuery } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

import { useGlobalContext, usePageContext } from '$store';
import { Modal } from './Modal';
import { PrismicRich, PrismicSpan } from '$components/text';
import { media, mixins, typo, spacing, colors, rem } from '$styles';
import { Button } from '$components/form/Button';
import { CTA } from '$components/form/CTA';
import { ImageCover } from '$components/image';
import { Booking } from '$components/booking/Booking';

export const query = graphql`
	fragment PageBooking_Data on PrismicPageBookingDataType {
		booking_text {
			html
			text
		}
		booking_footer {
			html
			text
		}
		booking_list {
			book_cta_link {
				url
				type
				target
				id
			}
			book_cta_text
			book_image {
				url
				alt
				dimensions {
					height
					width
				}
			}
			book_text {
				html
				text
			}
		}
	}
`;

const useBookQuery = () => useStaticQuery(graphql`
	query BookQuery {
		allPrismicPageBooking {
			edges {
				node {
					prismicId
					data {
						...PageBooking_Data
					}
				}
			}
		}
	}
`);

const ModalBookComponent = ({ previousUrl, url, id, className, ...props }) => {
	const { setModal } = useGlobalContext();

	let allBook = useMergePrismicPreviewData(useBookQuery());
	allBook = (allBook?.data?.allPrismicPageBooking?.edges || []).filter(v => v.node.prismicId === id);
	const data = allBook[ 0 ]?.node?.data || {};

	useLifecycles(
		() => {
			history.pushState(null, null, url);
			window.addEventListener('popstate', handlePopState);
		},
		() => {
			history.pushState(null, null, previousUrl);
			window.removeEventListener('popstate', handlePopState);
		}
	);

	const handlePopState = useCallback(
		() => { if (window.location.pathname === previousUrl) setModal(null); },
		[ setModal, previousUrl ]
	);

	return <Modal className={className} fullWidth>
		<Booking {...data} />
	</Modal>;
};

export const ModalBook = styled(ModalBookComponent)`
	.wrapper {
		display: flex;
		flex-flow: column nowrap;
		align-items: stretch;
		justify-content: center;
		min-height: 100%;
	}

	.text {
		${ typo.h3 }
		${ mixins.padding(0, 1, 0.45, 1, true) }
		text-align: center;
		max-width: 40em;
		margin: 0 auto;
	}

	ul {
		flex: 1 1 auto;
		--gap: ${ rem(20) };
		max-height: ${ rem(530) };
		display: flex;
		flex-flow: row wrap;
		align-items: space-between;
		justify-content: center;
		padding: 0 var(--gap);
	}

	footer {
		${ mixins.padding(0, 1, 0.15, 1, true) }
		text-align: center;
	}



	li {
		${ mixins.padding(0.3, 0.5, 0.3, 0.5, true) }
		flex: 0 1 auto;
		min-height: 100%;
		width: calc(33.3% - var(--gap) * 2 - 2px);
		position: relative;
		margin: 0 var(--gap) calc(var(--gap) * 2);
		text-align: center;
		color: ${ colors.dust };
	}

	li:after {
		${ mixins.overlay(0.6, 1) }
	}

	.image-cover {
		z-index: 0;
	}

	.push-content {
		height: 100%;
		position: relative;
		z-index: 2;
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;
	}

	.push-text {
		${ typo.h3 }
		${ typo.shadow() }
		margin-bottom: 1.5em;
	}

	.cta {
		${ typo.shadow() }
	}

	${ media.mobile`

		ul {
			flex: 0 0 auto;
			max-height: none;
		}

		li {
			${ mixins.padding(1.2, 3, 1.2, 3, true) }
			width: 100%;
			min-height: auto;
			margin-bottom: 1.5em;
		}
	` }
`;
