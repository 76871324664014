import { graphql, useStaticQuery } from 'gatsby';

export default () => useStaticQuery(graphql`
# BEGIN_GRAPHQL
{
	allPrismicSiteAgegate {
		edges {
			node {
				_previewable
				lang
				data {
					main_text {
						html
					}
					secondary_text {
						html
					}
					too_young_error_text {
						html
					}
					illegal_country_error_text {
						html
					}
					error_link_redirection {
						url
					}
					background_image {
						url
						dimensions {
							width
							height
						}
						thumbnails {
							mobile {
								url
								dimensions {
									width
									height
								}
							}
						}
					}
					location_label
					location_placeholder
					birthdate_label
					birthday_placeholder
					birthmonth_placeholder
					birthyear_placeholder
					optin_remember_text
					enter_text
				}
			}
		}
	}
}
# END_GRAPHQL
`);
