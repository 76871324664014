import React, { Fragment, useState } from 'react';
import { useLifecycles } from 'react-use';
import styled from 'styled-components';

import iso2to3 from '$data/iso2to3';
import { useAgegateCookie } from '$hooks';
import { useGlobalContext, usePageContext } from '$store';
import { rem, typo, fontWeight, media, colors, spacing } from '$styles';

import { Title3 } from '$components/text';
import { Button } from '$components/form/Button';
import { Container as FormInputContainer, Field, Input, Radio } from '$components/form/Form';
import RichText from '$components/text/RichText';
import { Modal, Header as ModalHeader, Close as ModalClose } from './Modal';

const SOURCE_DETAILS = {
	fr: '2022_02_Casa Eminente',
	en: '2022_02_EN_Casa Eminente'
};

const ModalClubComponent = ({ ...others }) => {
	const { siteData, localeIso, locale	} = usePageContext();

	const sourceDetail = SOURCE_DETAILS[ locale ] || SOURCE_DETAILS.fr;

	const { setSavedScrollTop, setScrollEnabled, userCountry } = useGlobalContext();
	const [ agegate ] = useAgegateCookie();
	const [ data, setData ] = useState({
		title: 'Mr.',
		firstname: '',
		lastname: '',
		email: '',
		birthday: agegate?.day || '',
		birthmonth: agegate?.month || '',
		birthyear: agegate?.year || '',
	});
	const [ errors, setErrors ] = useState({});
	const [ success, setSuccess ] = useState(false);
	const [ error, setError ] = useState(false);

	const {
		crm_title,
		crm_introduction,
		crm_form_title_label,
		crm_form_mr_label,
		crm_form_mrs_label,
		crm_form_firstname_label,
		crm_form_firstname_placeholder,
		crm_form_lastname_label,
		crm_form_lastname_placeholder,
		crm_form_email_label,
		crm_form_email_placeholder,
		crm_form_birthdate_label,
		crm_form_birthday_label,
		crm_form_birthmonth_label,
		crm_form_birthyear_label,
		crm_form_consent_text,
		crm_form_submit_text,
		crm_success_message,
		crm_error_message,
		crm_required,
	} = siteData;

	const hasTitle = !!crm_title?.text;
	const hasIntro = !!crm_introduction?.html;
	const hasConsent = !!crm_form_consent_text?.html;

	const input = {
		required: true,
		errorText: crm_required,
		onChange: (e) => {
			const { value, name } = event.target;
			const error = !e.target.checkValidity();
			setData((prev) => ({ ...prev, [ name ]: value }));
			setErrors((prev) => ({ ...prev, [ name ]: error }));
		},
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const $form = e.currentTarget;

		if ($form.checkValidity() === false) {
			const $fields = [ ...$form.querySelectorAll('[required]') ];

			$fields.forEach(($field) => {
				setErrors((prev) => ({ ...prev, [ $field.name ]: !$field.checkValidity() }));
			});
		} else {
			fetch(process.env.GATSBY_ENGAGE_API_URL, {
				method: 'POST',
				body: JSON.stringify({
					object: 'contacts',
					type: 'create',
					body: {
						optinRelational: true,
						optinEmailChannel: true,
						optinMessagingChannel: false,
						civility: data.title || null,
						firstname: data.firstname || null,
						lastname: data.lastname || null,
						email: data.email || null,
						locationOfResidence: iso2to3[ userCountry.toUpperCase() ],
						market: iso2to3[ localeIso.toUpperCase() ],
						birthDate: `${ data.birthyear }-${ data.birthmonth }-${ data.birthday }`,
						brand: 'EMI',
						typeOfTouchPoint: 'newsletter',
						dataSource: 'Website',
						sourceType: 'Newsletter Form',
						sourceDetail,
						touchPoint: window.location.href,
					},
				}),
				headers: {
					Api_Key: process.env.GATSBY_ENGAGE_API_KEY,
					Accept: 'application/json',
					'Content-type': 'application/json',
				},
			})
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						return Promise.resolve(response);
					} else {
						return Promise.reject(response);
					}
				})
				.then(() => {
					setSuccess(true);
				})
				.catch(({ data }) => {
					setError(true);
				});
		}
	};

	// Disable/Enable scroll on Mount/Unmount
	useLifecycles(
		() => {
			const { scrollTop } = document.scrollingElement || document.documentElement;
			setSavedScrollTop(scrollTop);
			setScrollEnabled(false);
		},
		() => setScrollEnabled(true)
	);

	return (
		<Modal
			{...others}
			id="newsletter"
			header={(close) => (
				<Fragment>
					{hasTitle && <Title3>{crm_title.text}</Title3>}
					{close}
				</Fragment>
			)}
		>
			{success ? (
				<Success>{crm_success_message}</Success>
			) : (
				<Form style={{ '--form-color': colors.green }} onSubmit={handleSubmit} noValidate>
					{hasIntro && (
						<RichText className="intro" dangerouslySetInnerHTML={{ __html: crm_introduction.html }} />
					)}
					<Fields>
						<Field label={crm_form_title_label}>
							<Radio
								label={crm_form_mr_label}
								id="Mr."
								name="title"
								value="Mr."
								checked={data.title === 'Mr.'}
								{...input}
							/>
							<Radio
								label={crm_form_mrs_label}
								id="Mrs."
								name="title"
								value="Mrs."
								checked={data.title === 'Mrs.'}
								{...input}
							/>
						</Field>
						<Field label={crm_form_firstname_label}>
							<Input
								type="text"
								name="firstname"
								placeholder={crm_form_firstname_placeholder}
								value={data.firstname}
								error={!!errors?.firstname}
								{...input}
							/>
						</Field>
						<Field label={crm_form_lastname_label}>
							<Input
								type="text"
								name="lastname"
								placeholder={crm_form_lastname_placeholder}
								value={data.lastname}
								error={!!errors?.lastname}
								{...input}
							/>
						</Field>
						<Field label={crm_form_email_label}>
							<Input
								type="email"
								name="email"
								placeholder={crm_form_email_placeholder}
								pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,8}$"
								value={data.email}
								error={!!errors?.email}
								{...input}
							/>
						</Field>
						<Field label={crm_form_birthdate_label}>
							<BirthdateInputs>
								<Input
									type="number"
									name="birthday"
									inputMode="numeric"
									min="1"
									max="31"
									placeholder={crm_form_birthday_label}
									value={data.birthday}
									error={!!errors?.birthday}
									{...input}
								/>
								<Input
									type="number"
									name="birthmonth"
									inputMode="numeric"
									min="1"
									max="12"
									placeholder={crm_form_birthmonth_label}
									value={data.birthmonth}
									error={!!errors?.birthmonth}
									{...input}
								/>
								<Input
									type="number"
									name="birthyear"
									inputMode="numeric"
									min="1866"
									max={new Date().getFullYear()}
									placeholder={crm_form_birthyear_label}
									value={data.birthyear}
									error={!!errors?.birthyear}
									{...input}
								/>
							</BirthdateInputs>
						</Field>
					</Fields>
					<Footer>
						{error && <Error>{crm_error_message}</Error>}

						{hasConsent && (
							<RichText dangerouslySetInnerHTML={{ __html: crm_form_consent_text.html }} />
						)}

						<Button background="small" color="green">
							{crm_form_submit_text}
						</Button>
					</Footer>
				</Form>
			)}
		</Modal>
	);
};

export const ModalClub = styled(ModalClubComponent)`
  ${ ModalHeader } {
    margin-left: ${ spacing.s5 };
    margin-right: ${ spacing.s5 };

		${ media.mobile`
			margin-top: -1em;
			padding-bottom: 0.5em;
		` }
  }

  ${ ModalClose } {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

	${ Title3 } {
		${ media.mobile`
			transform: translateY(1em);
		` }
	}

  ${ RichText } a {
    color: inherit;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        text-decoration: none;
      }
    }
  }

	.intro {
		display: block;
		line-height: 1em;

		& br {
			display: block;
			content: '';
   		margin: 0.3em 0;
		}
	}

  ${ media.mobile`
    ${ ModalHeader } {
      margin-left: ${ spacing.s3 };
      margin-right: ${ spacing.s3 };
      justify-content: flex-start;
    }
  ` }
`;

const Success = styled.p`
  text-align: center;
  padding: 0 ${ spacing.s2 } ${ spacing.s3 };
`;

const Error = styled.p`
  color: ${ colors.red };
  margin-top: ${ spacing.s2 };
  font-weight: ${ fontWeight.medium };
`;

const Form = styled.form`
  padding: 0 ${ spacing.s5 } ${ spacing.s4 };

  & > ${ RichText } {
    margin-bottom: ${ spacing.s2 };
    text-align: center;
  }

  ${ media.mobile`
    padding-left: ${ spacing.s3 };
    padding-right: ${ spacing.s3 };
  ` }
`;

const Fields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${ spacing.s2 } ${ spacing.s2 };

  ${ Field } {
    &:first-child {
      grid-column: 1 / -1;
    }

		& > label {
			font-size: 11px;
		}
  }

  ${ media.mobile`
    grid-template-columns: 1fr;
  ` }
`;

const BirthdateInputs = styled.div`
  display: flex;

  ${ FormInputContainer } {
    display: inline-block;
    min-width: 4em;

    &:not(:last-child) {
      margin-right: ${ spacing.s2 };
    }
  }
`;

const Footer = styled.div`
  text-align: center;

  ${ RichText } {
    ${ typo.smallCaps }
		font-family: sans-serif;
		text-transform: uppercase;
		letter-spacing: 0.04em;
		font-size: max(9px, ${ rem(9) });
    margin: ${ spacing.s3 } 0 ${ spacing.s2 } 0;
    line-height: ${ 14 / 10.5 };
		opacity: 0.7;
  }
`;
