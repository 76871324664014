
import React from 'react';
import styled, { css } from 'styled-components';

import propsToLink from '$utils/propsToLink';
import { rem, mixins, transitions, colors, spacing } from '$styles';

import { Link } from '$components/global/Link';
import { Icon } from '$components/image';

const bgs = {
	medium: 'button-line-medium',
	small: 'button-line-small',
	club: 'button-line-club'
};

const ButtonComponent = ({
	component: Component = false,
	id = null,
	color = null,
	background = null,
	children,
	link = {},
	...others
}) => {
	let bg = bgs[ background ]
		? <Icon id={bgs[ background ]} className="bg" freeScale />
		: null;

	return Component ? (
		<Component data-id={id} data-bg={background} data-color={color} {...others}>
			{bg}
			<span>{children}</span>
		</Component>
	) : (
		<Link data-id={id} data-bg={background} data-color={color} {...propsToLink({ ...others, ...link })} >
			{bg}
			<span>{children}</span>
		</Link>
	);
};

export const Button = styled(ButtonComponent)`
  ${ mixins.buttonReset }
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  padding: ${ spacing.s1_5 } ${ spacing.s3 };
  letter-spacing: 0.1em;
  ${ transitions.link }
  transition-property: opacity;

  span {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &[data-bg='medium'] {
    padding-left: ${ rem(40) };
    min-width: ${ rem(206) };
  }

  &[data-bg='small'] {
    padding-left: ${ rem(38) };
    padding-right: ${ rem(38) };
  }

	${ Object
		.keys(colors)
		.map(c => css`
			&[data-color='${ c }'] .bg { color: ${ colors[ c ] }; }`
		) }

	${ mixins.hover(css`
			opacity: 0.5;
	`) }
`;
