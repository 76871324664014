import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import withPrismicPreview from '$utils/withPrismicPreview';
import { SEO } from '$components/global/SEO';
import { PageContainer as _PageContainer } from '$components/global/PageContainer';
import SliceZone from '$components/global/SliceZone';

import { mixins } from '$styles';
import { Carte } from '$components/carte/Carte';

export const pageQuery = graphql`
  query ($uid: String!, $lang: String!) {
		prismicPageMenu(uid: { eq: $uid }, lang: { eq: $lang }) {
			...Base_Menu
			data {
				...SEO_Menu
				menu_suptitle { text }
				menu_title { text }
				body {
					...CarteCategory_Menu
				}
				body1 {
					...PushExplore_Menu
				}
			}
		}
	}
`;

export default withPrismicPreview(({ className, ...props }) => {
	const data = props.data?.prismicPageMenu?.data || {};
	return (
		<PageContainer className={className} data={data}>
			<SEO {...data} />
			<Carte {...data} />
			<SliceZone {...{ ...data, body: data.body1 }} />
		</PageContainer>
	);
});

const PageContainer = styled(_PageContainer)`
`;

