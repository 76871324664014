import { css } from 'styled-components';

import { baseFontSize } from './metrics';
import { rem } from './utils';
import { media } from './media';


// ------------------------------------------------------------
//    SIZES
// ------------------------------------------------------------
export const fontWeight = {
	normal: 'normal',
	medium: 500,
};

export const fontSize = {
	base: baseFontSize,
};

export const lineHeight = {
	base: 1.8,
};


export const fontText = css`
  font-family: 'Maax', sans-serif;
`;

export const fontTitle = css`
  font-family: 'Louize', serif;
`;

export const fontHandwritten = css`
	font-family: 'Bluefires', serif;
`;

// ------------------------------------------------------------
//    TITLES
// ------------------------------------------------------------
export const text = css`
	${ fontText }
	font-size: max(13px, ${ rem(14) });
	line-height: 1.875em;
	font-weight: normal;
	${ media.smallDesktop` font-size: max(13px, ${ rem(19) }); ` }
	${ media.mobile`  font-size: ${ rem(15) }; ` }
	${ media.smallMobile` font-size: ${ rem(14) }; ` }
`;

export const smallText = css`
	${ fontText }
	font-size: ${ rem(14) };
	line-height: 1.875em;
	font-weight: normal;
	${ media.smallDesktop` font-size: ${ rem(16) }; ` }
	${ media.mobile`  font-size: ${ rem(14) }; ` }
	${ media.smallMobile` font-size: ${ rem(14) }; ` }
`;

export const h1 = css`
  ${ fontTitle }
  font-size: ${ rem(84) };
  line-height: ${ 102 / 84 };
  font-weight: ${ fontWeight.medium };
  ${ media.mobile` font-size: ${ rem(49) }; ` }
  ${ media.smallMobile` font-size: ${ rem(42) }; ` }
`;

export const h2 = css`
  ${ fontTitle }
  font-size: ${ rem(75) }; // 84 fontsize_update
	text-transform: uppercase;
  line-height: 1.214;
  font-weight: ${ fontWeight.medium };
	/* overflow-wrap: break-word;
	hyphens: auto; */
  ${ media.mobile`  font-size: ${ rem(49) }; ` }
  ${ media.smallMobile` font-size: ${ rem(42) }; ` }
`;

export const h3 = css`
  ${ fontTitle }
  font-size: max(19px, ${ rem(25) }); // 28 fontsize_update
  line-height: 1.214;
  font-weight: ${ fontWeight.medium };

  ${ media.mobile`
    font-size: ${ rem(22) };
  ` }
`;

export const h4 = css`
  ${ fontTitle }
	font-size: max(16px, ${ rem(18) }); // 21 fontsize_update
  line-height: 1.3;
  font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	-webkit-text-stroke: 0.5px currentColor;
	${ media.mobile` font-size: ${ rem(18) }; ` }
`;

export const h5 = css`

`;


// ------------------------------------------------------------
//    MISC STYLES
// ------------------------------------------------------------
export const caps = css`
  font-weight: ${ fontWeight.medium };
  line-height: ${ 20 / 14 };
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;

export const capsSmaller = css`
  ${ caps }
	letter-spacing: 0.01em;
	line-height: 1.5em;
	${ media.mobile` font-size: 0.85714286rem; ` }
`;

export const smallCaps = css`
  text-transform: uppercase;
  font-size: max(10.5px, ${ rem(10.5) });
  line-height: ${ 20 / 14 };
  letter-spacing: 0.2rem;
`;

export const shadow = (opacity = 1) => css`
	text-shadow:
			0px 0px 30px rgba(0, 0, 0, ${ opacity }),
			0px 0px 20px rgba(0, 0, 0, ${ opacity });
`;
