import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import { graphql, useStaticQuery } from 'gatsby';
import { useLifecycles } from 'react-use';

import { useGlobalContext } from '$store';
import { media } from '$styles';
import { Modal } from './Modal';
import { Carte } from '$components/carte/Carte';
import SliceZone from '$components/global/SliceZone';

export const query = graphql`
  fragment CarteCategory_Menu on PrismicPageMenuDataBodyMenuCategory  {
		id slice_label slice_type
		primary {
			menu_category_title { text }
			menu_category_illustration
		}
		items {
			menu_element_text { text html }
			menu_element_price
		}
	}
`;

const useCarteQuery = () => useStaticQuery(graphql`
	query MyQuery {
		allPrismicPageMenu {
			edges {
				node {
					prismicId
					data {
						menu_suptitle { text }
						menu_title { text }
						body {
							...CarteCategory_Menu
						}
						body1 {
							...PushExplore_Menu
						}
					}
				}
			}
		}
	}
`);

const ModalCarteComponent = ({ previousUrl, url, id, className }) => {
	const { setModal } = useGlobalContext();

	let allCartes = useMergePrismicPreviewData(useCarteQuery());
	allCartes = (allCartes?.data?.allPrismicPageMenu?.edges || []).filter(v => v.node.prismicId === id);
	const data = allCartes[ 0 ]?.node?.data || {};
	console.log(id);
	useLifecycles(
		() => {
			history.pushState(null, null, url);
			window.addEventListener('popstate', handlePopState);
		},
		() => {
			history.pushState(null, null, previousUrl);
			window.removeEventListener('popstate', handlePopState);
		}
	);

	const handlePopState = useCallback(
		() => {
			if (window.location.pathname === previousUrl) {
				setModal(null);
			}
		},
		[ setModal, previousUrl ]
	);

	return <Modal className={className} fullWidth>
		<Carte {...data} />
		<SliceZone {...{ ...data, body: data.body1 }} />
	</Modal>;
};

export const ModalCarte = styled(ModalCarteComponent)`
`;
