import { useState } from 'react';

export default function createStates(obj) {
	return Object.keys(obj).reduce((acc, k) => {
		const defaultValue = obj[ k ];
		const getKey = k;
		const setKey = k.startsWith('is')
			? k.replace(/^is/, 'set')
			: `set${ k[ 0 ].toUpperCase() }${ k.slice(1) }`;
		const [ getter, setter ] = useState(defaultValue);
		acc[ getKey ] = getter;
		acc[ setKey ] = setter;
		return acc;
	}, {});
}
