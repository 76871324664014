import { rem } from 'polished';

export const baseFontSize = 14;
export const defaultGridSize = 40;
export const designedDesktop = 1366;
export const designedMobile = 375;

export const spacing = {
	s1: rem(10, baseFontSize),
	s1_5: rem(15, baseFontSize),
	s2: rem(20, baseFontSize),
	s2_5: rem(25, baseFontSize),
	s3: rem(30, baseFontSize),
	s4: rem(40, baseFontSize),
	s5: rem(50, baseFontSize),
	s6: rem(60, baseFontSize),
	s7: rem(70, baseFontSize),
	s8: rem(80, baseFontSize),
	s9: rem(90, baseFontSize),
	s10: rem(100, baseFontSize),
	s16: rem(160, baseFontSize),
	gridColumnGap: rem(50, baseFontSize),
};
