import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { media, mixins, colors, z, rem } from '$styles';
import { FooterBottom } from '../footer/FooterBottom';


const FooterComponent = ({ className }) => {
	className = classNames(className, 'app-footer');

	return (
		<footer className={className}>
			<FooterBottom />
		</footer>
	);
};

export const Footer = styled(FooterComponent)`
	border-top: ${ mixins.stroke };
`;
