import { graphql } from 'gatsby';

import React from 'react';
import styled from 'styled-components';

import { useGlobalContext } from '$store';
import { rem, typo, colors, media, mixins } from '$styles';

import { PrismicRich, PrismicText, Title2 } from '$components/text';
import { Icon, Illustration, Image } from '$components/image';
import { CTA } from '$components/form/CTA';
import { Parallax } from '$components/global/Parallax';

export const query = graphql`
  fragment Experiences_Home on PrismicPageHomeDataBodyExperiences {
		id slice_label slice_type
		primary {
			experiences_cta_text
			experiences_cta_link { url type target id }
			experiences_image { url dimensions { height width } alt }
			experiences_text { html }
			experiences_title { text }
		}
	}
`;

const HomeExperiencesComponent = ({ className, ...data }) => {
	const { isMobile } = useGlobalContext();

	const title = data?.primary?.experiences_title;
	const text = data?.primary?.experiences_text;
	const ctaText = data?.primary?.experiences_cta_text;
	const ctaLink = data?.primary?.experiences_cta_link;
	const image = data?.primary?.experiences_image;

	const imageBlock = <div className="image-block">
		<Icon id="lesraffineurs-eminente" />
		{ image && <Image {...image} /> }
	</div>;

	return <div className={className}>
		{ !isMobile && <div className="col col-left">
			{ imageBlock }
		</div> }
		<div className="col col-right">
			{
				title && <Parallax strength="-2">
					<h2 className="title">
						<PrismicText text={title}/>
					</h2>
				</Parallax>
			}
			{
				isMobile && imageBlock
			}
			<div className="text">
				<PrismicRich text={text} />
				<CTA color="dust" {...{ ctaLink, ctaText }} />
			</div>

		</div>
	</div>;
};

export const HomeExperiences = styled(HomeExperiencesComponent)`
	${ mixins.bgGreen }
	${ mixins.padding(1, 1, 1, 1, true) }

	${ media.mobile`
		${ mixins.padding(0.2, 1, 1, 1, true) }
	` }

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;

	.col {
		width: 50%;
	}

	.image-block {
		position: relative;
		padding: 0 ${ rem(80) } 0 ${ rem(30) }
	}

	.col-right {

	}

	${ media.mobile`
		display: block;
		.col { width: 100%; }
		.image-block {
			padding: 0 ${ rem(20) };
			margin-bottom: ${ rem(50) };
		}
		.col-right {
			margin-top: ${ rem(50) };
		}
	` }

	.title {
		${ typo.h2 }
		margin-bottom: 0.75em;
	}

	.text {
		margin-left: ${ rem(50) };
		${ media.mobile`margin-left: ${ rem(0) };` }

		h3 {
			${ typo.h3 }
			margin-bottom: 1em;
			max-width: 15em;
		}

		p {
			${ typo.text }
			max-width: 30em;
		}

		.cta {
			margin-top: 2em;
		}
	}

	.icon-lesraffineurs-eminente {
		position: absolute;
		z-index: 2;
		top: 0%;
		left: 5%;
		width: 55%;
		height: auto;
		${ media.mobile`
			top: 0%;
			left: 0%;
			width: 70%;
		` }
	}

`;

HomeExperiences.sliceType = 'experiences';

