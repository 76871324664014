import { graphql } from 'gatsby';

import React from 'react';
import styled from 'styled-components';

import { useGlobalContext } from '$store';
import { rem, typo, colors, media, mixins } from '$styles';
import { PrismicRich, PrismicText } from '$components/text';
import { Icon, Illustration, Image, ImageRatio } from '$components/image';
import { Button } from '$components/form/Button';
import classNames from 'classnames';
import { SectionTitle } from '$components/text/SectionTitle';
import { Slider } from '$components/global/Slider';

export const query = graphql`
  fragment TextSlider_Rich on PrismicPageRichDataBodyTextSlider {
		id slice_label slice_type
		primary {
			illustration: text_slider_illustration
			text: text_slider_text { html text }
			title: text_slider_title { text }
		}
		items {
			image: text_slider_image { url alt dimensions { height width } }
			caption: text_slider_image_caption { html }
		}
	}
`;

const TextSliderItem = ({ image, caption, index, illustration }) => {
	const { isMobile } = useGlobalContext();
	const className = classNames('item', { 'is-slider': isMobile });
	return <div className={className}>
		<ImageRatio	ratio="0.72" {...image} />
		<PrismicRich className="item-caption" text={caption} />
		{index === 0 && <Illustration color="green" id={illustration} parallax="1"/>}
	</div>;
};

const TextSliderComponent = ({ className, ...data }) => {
	const { isMobile } = useGlobalContext();
	let items = (data?.items || [])
		.map(v => ({
			image: v.image || v.text_slider_image,
			caption: v.caption || v.text_slider_image_caption
		}))
		.filter(v => !!v.image)
		.slice(0, 3).map((v, i) => (
			<TextSliderItem
				key={i}
				index={i}
				illustration={data?.primary?.illustration}
				{...v}
			/>
		));


	data = data?.primary || {};
	if (data.text_slider_illustration) data.illustration = data.text_slider_illustration;
	if (data.text_slider_text) data.text = data.text_slider_text;
	if (data.text_slider_title) data.title = data.text_slider_title;

	className = classNames(className, 'text-slider', { 'is-slider': isMobile });

	const sliderOpts = {
		width: '100%',
		fixedWidth: '55vw',
		gap: rem(25),
		padding: { left: rem(48), right: rem(50) },
		breakpoints: {}
	};

	return <div className={className}>
		<SectionTitle text={data.title} parallax offset />
		<div className='wrapper'>
			<PrismicRich className="content" text={data.text} />
			{ isMobile
				? <Slider options={sliderOpts}>{ items }</Slider>
				: <div className="items">{ items }</div>
			}
		</div>
	</div>;
};

export const TextSlider = styled(TextSliderComponent)`
	${ mixins.margin(1, null, 1, null, true) }

	h2 {
		${ mixins.margin(null, 1, null, 1, true) }
		max-width: 12em;
		hyphens: none;
	}

	.wrapper {
		${ mixins.margin(null, 1, null, 1, true) }
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
	}

	.content {
		${ mixins.padding(1, 0.5, 1, 0.5, true) }
		width: 30%;

		h3 {
			${ typo.h3 }
		}

		p {
			margin-top: 1.5em;
			${ typo.smallText }
		}
	}

	.items {
		flex: 1 1 auto;
	}

	&:not(.is-slider) .items {
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-start;
		justify-content: space-between;

		.item {
			width: 31%;
		}
	}

	&.is-slider {
		.wrapper {
			display: block;
			margin: 0;
		}

		.content {
			padding: 0;
			width: 100%;
			max-width: ${ rem(350) };
			${ mixins.margin(1, 2, 0.5, 3, true) }
		}

		.items {
			border: 1px solid red;
		}

		.image-ratio {
			--ratio: 0.8;
		}

	}

	.splide__track {
		overflow: visible;
	}

	.item {
		position: relative;

		.image-ratio {
			position: relative;
			z-index: 0;
		}

		.item-caption {
			position: absolute;
			z-index: 1;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 1em;
			padding-top: 4em;
			${ typo.shadow() }
			color: ${ colors.white };
			text-align: center;
			background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
		}

		h3 {
			${ typo.capsSmaller }
			margin-bottom: 1em;
		}

		p {
			${ typo.smallText }
			line-height: 1.7;
			min-height: 4em;
		}

		.illus {
			position: absolute;
			z-index: 3;
			--width: 45%;
			bottom: calc(var(--height) * -0.55);
			left: calc(var(--width) * -0.7);

			${ media.mobile`
			--width: 35%;
			bottom: auto;
			top: calc(var(--height) * 0.1);
			left: calc(var(--width) * -0.6);

			` }
		}

	}
`;

