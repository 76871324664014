const req = require.context('./', true, /\.js$/);
const slices = {};

req
	.keys()
	.forEach(key => {
		if (!key.startsWith('./')) return;
		const name = key.slice(2, -3);
		if (name === 'index') return;
		const exp = req(key);
		Object.keys(exp).forEach(k => {
			const mod = exp[ k ];
			if (mod.sliceType) k = mod.sliceType;
			slices[ k ] = mod;
		});
	});

export default slices;
