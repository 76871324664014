import React from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';

import { mixins, easings, colors, typo, transitions } from '$styles';

import { Icon } from '$components/image';
import { Link } from '$components/global/Link';

const LinkUnderlineComponent = ({
	tag: Tag = Link,
	isActive,
	children,
	className,
	noSnippet,
	...others
}) => {
	className = classNames(className, { 'is-active': isActive });

	return (
		<Tag className={className} {...others}>
			<span data-nosnippet={noSnippet != null ? '' : null}>{children}</span>
			<Icon id="line-horizontal" freeScale />
		</Tag>
	);
};

export const LinkUnderline = styled(LinkUnderlineComponent)`
  ${ typo.caps }
  position: relative;

  & > span {
    transition-property: opacity;
    ${ transitions.link }
  }

  .icon-line-horizontal {
    position: absolute;
    bottom: -0.25em;
    left: 0;
    width: 100%;
    height: 4px;
    transform: scaleX(0);
    transform-origin: 100% 50%;
		color: var(--accent-color, ${ colors.green });
    opacity: 0;
    transition: transform 0.5s ${ easings.inOutExpo }, opacity 0.4s ${ easings.outSine };
  }

  &.is-active .icon-line-horizontal {
    transform-origin: 0 50%;
    transform: scaleX(1);
    opacity: 1;
  }

  ${ mixins.hasHover(css`
		&:not(.is-active):hover {
      span {
        opacity: 0.5;
      }
    }

    a:hover &,
    &:hover {
      .icon-line-horizontal {
        transform-origin: 0 50%;
        transform: scaleX(1);
        opacity: 1;
      }
    }
	`) }

`;
