import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import styled from 'styled-components';

import { typo, rem, mixins, media } from '$styles';
import { useParallax } from '$hooks';
import { useGlobalContext, usePageContext } from '$store';
import clamp from '$utils/clamp';

import { ImageCover } from '$components/image';
import { PrismicRich, PrismicText, Title1 } from '$components/text';
import isEmpty from '$utils/isEmpty';
import { Parallax } from '$components/global/Parallax';

export const query = graphql`
  fragment Hero_Home on PrismicPageHomeDataBodyHero {
		id slice_label slice_type
		primary {
			hero_title { text }
			hero_text { html }
			hero_image { url alt dimensions { height width } }
		}
	}

	fragment Hero_Rich on PrismicPageRichDataBodyHero {
		id slice_label slice_type
		primary {
			hero_title { text }
			hero_text { html }
			hero_image { url alt dimensions { height width } }
		}
	}
`;

const HeroComponent = ({ className, ...data }) => {
	const { pageType } = usePageContext();
	const { isMobile } = useGlobalContext();
	const isHome = pageType === 'page_home';
	className = classNames(className, {
		'hero': true,
		'is-home-hero': isHome
	});

	const title = data?.primary?.hero_title;
	const text = data?.primary?.hero_text;
	const image = data?.primary?.hero_image;


	const [ $parallax, y ] = useParallax(
		(y, b, wh) => clamp(y * 0.35, 0, wh * 0.5), {
			smooth: false,
			animation: null,
		}
	);

	return <header className={className}>
		<Parallax strength={isHome ? 0 : -2}>
			<div className="hero-content">
				{ !isEmpty(title) && <PrismicText tag="h1" text={title}/> }
				{ !isEmpty(text) && <PrismicRich className="text" text={text} /> }
			</div>
		</Parallax>
		{
			image && <ImageCover
				ref={$parallax}
				style={{ y: isMobile ? 0 : y }}
				{...image}
			/>
		}
	</header>;
};

export const Hero = styled(HeroComponent)`
	${ mixins.bgGreen }
	${ mixins.flexCenter }
	position: relative;
	height: ${ rem(600) };
	margin-top: ${ rem(-50) };
	overflow: hidden;

	.hero-content {
		${ mixins.padding(0.5, 1, 0.5, 1, true) }
		position: relative;
		z-index: 2;
		text-align: center;
		margin-top: ${ rem(50) };
		${ typo.shadow(0.5) }
	}

	&.is-home-hero .hero-content {
		visibility: hidden;
	}

	h1 {
		${ typo.h1 }
		text-transform: uppercase;
		/* visibility: hidden; */
		pointer-events: none;
	}

	.text {
		${ typo.h3 }
	}

	&:not(.is-home-hero):after {
		${ mixins.overlay(0.4, 1) }
	}

	.image-cover {
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		width: 100%;
		height: calc(100% + ${ rem(50) });
	}

	${ media.mobile`
		height: ${ rem(275) };
	` }
`;
