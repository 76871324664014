import React, { forwardRef } from 'react';
import styled from 'styled-components';

import ensureChildrenArray from '$utils/ensureChildrenArray';
import { typo } from '$styles';

const _nl2br = text => text.replace(/\n/g, '<br />');

// Wrapper to use prismic rich text property
// Withou using dangerouslySetInnerHTML	directly
export const prismicTextFactory = (tag, textOnly) => {
	const PrismicTextComponent = forwardRef((_props, ref) => {
		let { tag: Component, children, text, nl2br, ...props } = _props;

		if (!textOnly && text?.html) props.dangerouslySetInnerHTML = { __html: text.html };
		else if (nl2br) {
			if (text?.text) props.dangerouslySetInnerHTML = { __html: _nl2br(text.text) };
			if (typeof text === 'string') props.dangerouslySetInnerHTML = { __html: _nl2br(text) };
		} else {
			if (text?.text) children = ensureChildrenArray(children, text.text);
			if (typeof text === 'string') children = ensureChildrenArray(children, text);
		}

		if (props.dangerouslySetInnerHTML != null) children = undefined;
		return <Component {...props } ref={ref}>{ children }</Component>;
	});
	PrismicTextComponent.displayName = 'PrismicTextComponent';
	PrismicTextComponent.defaultProps = { tag };
	return PrismicTextComponent;
};

export const Text = prismicTextFactory('div');
export const PrismicText = prismicTextFactory('div', true);
export const PrismicRich = prismicTextFactory('div');
export const PrismicParagraph = prismicTextFactory('p', true);
export const PrismicSpan = prismicTextFactory('span', true);
export const Title1 = styled(prismicTextFactory('h1'))`${ typo.h1 }`;
export const Title2 = styled(prismicTextFactory('h2'))`${ typo.h2 }`;
export const Title3 = styled(prismicTextFactory('h3'))`${ typo.h3 }`;
export const Title4 = styled(prismicTextFactory('h4'))`${ typo.h4 }`;
export const Title5 = styled(prismicTextFactory('h5'))`${ typo.h5 }`;
export const Caps = styled(prismicTextFactory('p'))`${ typo.caps }`;
export const SmallCaps = styled(prismicTextFactory('p'))`${ typo.smallCaps }`;
