import React from 'react';
import styled from 'styled-components';

import { rem, colors } from '$styles';
import { labelCSS } from './Form';

import { Icon } from '$components/image';

const FormCheckboxComponent = ({ children, className, ...others }) => {
	return (
		<div className={className}>
			<input type="checkbox" {...others} />

			<label htmlFor={others.id}>
				<span className="icons">
					<Icon id="checkbox-on" />
					<Icon id="checkbox-off" />
				</span>
				{children}
			</label>
		</div>
	);
};

export const FormCheckbox = styled(FormCheckboxComponent)`
  position: relative;
  text-align: center;
  line-height: ${ 20 / 14 };

	.icons {
		position: relative;
	}

	.icons:after {
		content: '';
		position: absolute;
		z-index: 0;
		background: white;
		top: ${ rem(1) };
		left: ${ rem(4) };
		width: ${ rem(12) };
		height: ${ rem(12) };
		display: none;
	}

  .icon-checkbox-on, .icon-checkbox-off {
		position: relative;
		z-index: 1;
    font-size: ${ rem(20) };
    line-height: ${ rem(20) };
    vertical-align: center;
    margin-right: ${ rem(12) };
    color: ${ colors.green };
    pointer-events: none;
  }

	.icon-checkbox-on { display: none; }
	.icon-checkbox-off { display: inline-block; }

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;

    &:checked ~ label {
			.icons:after { display: block; }
			.icon-checkbox-on { display: inline-block; }
			.icon-checkbox-off { display: none; }
    }
  }

  label {
    ${ labelCSS }
    display: inline-block;
    cursor: pointer;

    &:before {
      content: ' ';
      display: inline-block;
    }
  }
`;
