import { createGlobalStyle } from 'styled-components';
import { rem } from 'polished';

import { media, colors, typo, mixins } from '$styles';

const GlobalStyle = createGlobalStyle`
  :root {
		--dpr: ${ ({ dpr }) => dpr };
    --viewport-height: ${ ({ viewportHeight }) => `${ viewportHeight }px` };
    --header-height: ${ ({ headerHeight }) => `${ Math.floor(headerHeight) }px` };
  }

  html {
    font-size: max(${ rem(9) }, ${ (14 / 1376) * 100 }vw);
    line-height: ${ typo.lineHeight.base };
    background: ${ colors.white };
    color: ${ colors.font };
    -webkit-font-smoothing: antialiased;
		${ mixins.bg }
    ${ media.mobile`
      font-size: 14px;
    ` }
  }

  body {
    position: ${ ({ scrollEnabled }) => (scrollEnabled ? null : 'fixed') };
    overflow-y: ${ ({ scrollEnabled }) => (scrollEnabled ? null : 'scroll') };
    width: 100%;
    font-size: max(${ rem(14) }, 14px);
    overscroll-behavior: none;
  }

  .scrollable {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mobile-only {
    ${ media.fromMobile`
      display: none !important;
    ` }
  }

  .no-mobile {
    ${ media.mobile`
      display: none !important;
    ` }
  }
`;

export default GlobalStyle;
