import React from 'react';
import { useLocalStorage } from 'react-use';
import styled from 'styled-components';
import classNames from 'classnames';

import { usePageContext } from '$store';
import { typo, media, mixins, colors, z, rem } from '$styles';
import { Icon } from '$components/image';
import { Link } from '$components/global/Link';
import { LinkUnderline } from '$components/text';


const FooterCopyrightComponent = ({ className }) => {
	className = classNames(className, 'footer-copyright');
	return <section className={className}>
		<span className="casaeminente">Casa Eminente</span>{ ' ' }
		<span>{new Date().getFullYear()} ©</span>
	</section>;
};

export const FooterCopyright = styled(FooterCopyrightComponent)`
	${ mixins.padding(0.2, 1, 0.2, 0.7, true) }
	${ typo.fontTitle }
	font-weight: 500;
	font-size: ${ rem(15) };
	/* margin-top: 1em; */

	.casaeminente {
		display: none;
	}

	${ media.smallFooter`
		${ mixins.padding(0.2, 0.4, 0.2, 0.7, true) }
	` }

	${ media.mobile`
		${ mixins.padding(0.2, 1, 0.2, 0.7, true) }
		${ typo.fontText }
		${ typo.caps }

		.casaeminente {
			display: inline;
		}
	` }
`;
