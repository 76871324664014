import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import { useGlobalContext, usePageContext } from '$store';
import { spacing, mixins, rem, typo, colors, media } from '$styles';
import { PrismicRich, PrismicText } from '$components/text';
import { Icon, Illustration, ImageRatio } from '$components/image';
import { Button } from '$components/form/Button';
import { Parallax } from '$components/global/Parallax';
import { SectionTitle } from '$components/text/SectionTitle';
import { CTA } from '$components/form/CTA';
import isEmpty from '$utils/isEmpty';

export const query = graphql`
  fragment FloatingTrio_Rich on PrismicPageRichDataBodyFloatingTrio {
		id slice_label slice_type
		primary {
			illustration: floating_illustration
			text: floating_text { html }
			capsText: floating_caps { html }
			imageA: floating_image_landscape_main { alt url dimensions { height width } }
			imageB: floating_image_landscape_second { alt url dimensions { height width } }
			imageC: floating_image_portrait { alt url dimensions { height width } }
		}
	}
`;

const FloatingTrioComponent = ({ className, ...data }) => {
	data = data?.primary || {};
	if (data.floating_illustration) data.illustration = data.floating_illustration;
	if (data.floating_text) data.text = data.floating_text;
	if (data.floating_caps) data.capsText = data.floating_caps;
	if (data.floating_image_landscape_main) data.imageA = data.floating_image_landscape_main;
	if (data.floating_image_landscape_second) data.imageB = data.floating_image_landscape_second;
	if (data.floating_image_portrait) data.imageC = data.floating_image_portrait;

	className = classNames(className, {
		'floating-trio': true,
		'has-text': !isEmpty(data.text)
	});

	const { isMobile } = useGlobalContext();
	const parallaxMult = isMobile ? -0.2 : 1;

	const textComponent = !isEmpty(data.text) && (
		<Parallax strength={1 * parallaxMult}>
			<PrismicRich tag="div" className="text" text={data.text} />
		</Parallax>
	);

	return <section className={className}>
		{
			data.imageA
			&& <ImageRatio className='img img-a' ratio={1.45} {...data.imageA} />
		}

		<div className='content'>
			<Parallax strength={2 * parallaxMult} >
				<PrismicRich tag="div" className="caps" text={data.capsText} />
			</Parallax>
			{
				!isMobile && textComponent
			}
		</div>
		<div className="img-duo">
			{
				data.imageB
			&& <ImageRatio className='img img-b' ratio={1.36} {...data.imageB} />
			}
			{
				data.imageC
			&& <ImageRatio className='img img-c' ratio={0.66} {...data.imageC} arch />
			}
			{
				<Illustration
					color="green"
					id={data.illustration || 'tree'}
					parallax={ 0.5 }
				/>
			}
		</div>
		{
			isMobile && textComponent
		}
	</section>;
};

export const FloatingTrio = styled(FloatingTrioComponent)`
	${ mixins.margin(0, 0, 0.4, 0, true) }
	${ mixins.padding(1, 1, 1, 1, true) }
	position: relative;
	min-height: ${ rem(900) };

	// Typo
	.content {
		position: relative;
		z-index: 1;
	}

	.caps {
		${ typo.h3 }
		${ mixins.bolder(1) }
		text-transform: uppercase;
		font-weight: 500;
		font-size: ${ rem(40) };
		line-height: 1.5;
		${ media.mobile`
		${ mixins.bolder(0.5) }
			font-size: ${ rem(28) };
			line-height: 1.38;
		` }
		${ media.smallMobile`
			${ mixins.bolder(0.5) }
			font-size: ${ rem(22) };
			line-height: 1.38;
		` }
	}

	.text {
		${ typo.text }
		max-width: 28em;
		${ media.mobile` line-height: 1.7; ` }
	}

	.img, .illus {
		position: absolute;
		z-index: 0;
	}

	// Illustration setup
	.illus {
		margin-right: calc(var(--width) * -0.5);
		--base-width: var(--width);
		--width: calc(var(--base-width) * var(--illus-mult, 1));
		&.illus-sugarcanes { --illus-mult: 1.43; }
		&.illus-tree { --illus-mult: 2; }
	}

	// --- LAYOUT / NO TEXT - DESKTOP
	.content {
		margin-top: ${ rem(580) };
		margin-left: ${ rem(10) };
		padding-bottom: ${ rem(0) };
	}
	.caps {
		max-width: 14.5em;
		min-height: ${ rem(245) };
	}
	.img-a {
		top: ${ rem(140) };
		width: ${ rem(590) };
	}
	.img-b {
		top: ${ rem(650) };
		right: ${ rem(80) };
		width: ${ rem(480) };
	}
	.img-c {
		top: ${ rem(140) };
		right: ${ rem(220) };
		width: ${ rem(270) };
	}
	.illus {
		top: ${ rem(620) };
		right: ${ rem(570) };
		--base-width: ${ rem(70) };
	}

	&:not(.has-text) {
		${ media.below(900)`
		.caps {
			min-height: ${ rem(295) };
		}
		` }
	}

	// --- LAYOUT / TEXT
	&.has-text {
		.content {
			margin-top: ${ rem(450) };
			margin-left: ${ rem(105) };
			padding-bottom: ${ rem(5) };
		}
		.caps {
			max-width: 17em;
			min-height: 6.2em;
			margin-bottom: ${ rem(50) };
		}
		.text {
			margin-left: ${ rem(593) };
			min-height: ${ rem(330) };
		}
		.img-a {
			top: ${ rem(210) };
			bottom: auto;
			left: auto;
			right: ${ rem(60) };
			width: ${ rem(480) };
			--ratio: 1.55
		}
		.img-b {
			bottom: auto;
			top: ${ rem(90) };
			left: ${ rem(60) };
			right: auto;
			width: ${ rem(640) };
			--ratio: 1.75;
		}
		.img-c {
			top: auto;
			bottom: ${ rem(0) };
			right: auto;
			left: ${ rem(275) };
			width: ${ rem(270) };
		}
		.illus {
			top: auto;
			bottom: ${ rem(0) };
			right: auto;
			left: ${ rem(278) };
			margin-right: 0;
			margin-left: calc(var(--width) * -0.5);
			--base-width: ${ rem(85) };
		}

		${ media.below(900)`
		.img-a {
			right: auto;
		}
		.img-b {
			width: 40vw;
		}
		` }
	}

	// --- LAYOUT / MOBILE
	${ media.mobile`
		&, &.has-text {
			min-height: ${ rem(0) };
			padding-bottom: ${ rem(30) };
			margin-bottom: ${ rem(0) };

			.content {
				margin-top: ${ rem(30) };
				margin-left: ${ rem(0) };
				padding-bottom: 0 !important;
			}
			.caps {
				min-height: 0 !important;
				padding-bottom: 0;
				margin-bottom: 0;
			}
			.text {
				margin-left: 0;
				margin-top: 3em;
				min-height: 0;
			}
			.img-a {
				position: relative;
				top: auto;
				left: auto;
				width: 72vw;
			}
			.img-duo {
				position: relative;
				margin-top: ${ rem(45) };
				height: 75vw;
			}
			.illus {
				bottom: 25%;
				right: auto;
				left: 37vw;
				margin-right: 0;
				margin-left: calc(var(--width) * -0.5);
				--base-width: 9.3vw;
			}
		}

		&:not(.has-text) .img-b, &.has-text .img-c {
			top: 0;
			bottom: auto;
			right: auto;
			left: 0;
			width: 39vw;
			--ratio: 0.7;
		}

		&:not(.has-text) .img-c, &.has-text .img-b {
			top: auto;
			bottom: 0;
			width: 44vw;
			left: auto;
			right: 0;
		}

		&.has-text .img-b {
			--ratio: 0.8;
		}

		&.has-text {
			.img-duo {
				height: 68vw;
			}
			.illus {
				bottom: 0;
				left: auto;
				right: 43vw;
				margin-left: 0;
				margin-right: calc(var(--width) * -0.5);
			}
		}
	` }
`;

