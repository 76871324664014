import React, { createContext, useContext, useEffect } from 'react';
import { useMedia, useWindowSize } from 'react-use';

import { useAgegateCookie, useDevicePixelRatio } from '$hooks';
import { mqMobile, mqPortrait } from '$styles';
import createStates from '$utils/createStates';

export const GlobalContext = createContext({
	savedScrollTop: 0,
	scrollEnabled: true,
	headerHeight: null,
});

export const GlobalProvider = ({ children }) => {
	const [ { year, country } ] = useAgegateCookie();
	const isMobile = useMedia(mqMobile, false);
	const isPortrait = useMedia(mqPortrait, false);
	const { width: ww, height: wh } = useWindowSize(0, 0);
	const dpr = useDevicePixelRatio({ round: true });

	const store = {
		isMobile,
		isPortrait,
		ww,
		wh,
		dpr,
		...createStates({
			scrollEnabled: true,
			savedScrollTop: 0,
			headerHeight: null,
			isClient: false,
			isMenuOpen: false,
			isPage404: false,
			showAgeGate: year === null || country === null,
			userCountry: null,
			modal: undefined
		})
	};

	useEffect(() => store.setClient(true), []);
	return <GlobalContext.Provider value={store}>{children}</GlobalContext.Provider>;
};

export default GlobalProvider;

export const useGlobalContext = () => {
	const context = useContext(GlobalContext);
	if (!context) throw Error('useGlobalContext must be used inside a `GlobalProvider`');
	return context;
};
