import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { rgba } from 'polished';

import { rem, typo, colors, media, mixins } from '$styles';

import { LinkUnderline, PrismicSpan, PrismicText } from '$components/text';
import { Icon } from '$components/image';
import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { Tab } from './Tab';

export const query = graphql`
  fragment Tabs_Home on PrismicPageHomeDataBodyTabs {
		id slice_label slice_type
		items {
			tab_title
			tab_caps { html }
			tab_text { html }
			tab_cta_text
			tab_cta_link { url target type id }
			tab_illus
			tab_image_small { url alt dimensions { height width } }
			tab_image_big { url alt dimensions { height width } }
		}
	}
`;

const TabsComponent = ({ className, ...data }) => {
	const [ activeTab, setActiveTab ] = useState(0);

	const tabs = (data?.items || []).map(v => ({
		name: v.tab_title,
		quote: v.tab_caps,
		text: v.tab_text,
		illustration: v.tab_illus,
		cta: { link: v.tab_cta_link, text: v.tab_cta_text },
		imageBig: v.tab_image_big,
		imageSmall: v.tab_image_small
	}));

	className = classNames(className, 'tabs');

	if (!tabs.length) return null;
	return <section className={className}>
		<nav>
			{ tabs.map((tab, i) => (
				<LinkUnderline
					key={i + '_' + tab.name}
					tag="button"
					isActive={activeTab === i }
					onClick={() => activeTab !== i && setActiveTab(i)}
				>
					<PrismicSpan text={tab.name} />
					<Icon id="line-horizontal" freeScale />
				</LinkUnderline>
			)) }
		</nav>
		{ tabs.map((tab, i) => activeTab === i && (
			<Tab key={i + '_' + tab.name} invert={!!(i % 2)} {...tab} />
		)) }
	</section>;
};

export const Tabs = styled(TabsComponent)`

	${ mixins.padding(1, 1, 1, 1, true) }

	nav {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		margin-bottom: ${ rem(60) };
		${ media.smallMobile`justify-content: space-between;` }
	}

	nav button {
		${ mixins.buttonReset }
		${ typo.caps }
		position: relative;
		text-transform: uppercase;
		--accent-color: ${ colors.green };
		color: ${ rgba(colors.black, 0.5) };
		transition: color 200ms;
		font-size: ${ rem(28) };
		text-align: center;

		&.is-active {
			font-weight: 500;
			color: ${ colors.black };
		}

		.icon-line-horizontal {
			pointer-events: none;
			bottom: -0.2em;
			left: 0.3em;
			width: calc(100% - 0.7em);
		}

		&:not(:first-child) {
			margin-left: 2em;
		}

		${ media.mobile`
			font-size: ${ rem(20) };
		` }

		${ media.smallMobile`
			font-size: ${ rem(18) };
			&:not(:first-child) { margin-left: 1em };
		` }
	}

`;
