import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import { rem, typo, colors, media } from '$styles';

import { BaseMarquee } from '$components/text/BaseMarquee';
import { PrismicText } from '$components/text';
import { Icon } from '$components/image';

export const query = graphql`
  fragment Marquee_Home on PrismicPageHomeDataBodyMarquee {
		id slice_label slice_type
		primary {
			marquee_text { text }
		}
	}
`;

const MarqueeComponent = ({ className, ...data }) => {
	const text = data?.primary?.marquee_text?.text;
	if (!text) return null;

	const separator = <Icon id="star" />;
	const duration = 0.5 * text.length;

	return <BaseMarquee
		className={className}
		separator={separator}
		duration={duration}
	>
		<PrismicText tag="h2" text={text} />
	</BaseMarquee>;
};

export const Marquee = styled(MarqueeComponent)`
	padding: ${ rem(52) } 0;
	text-transform: uppercase;
	font-size: ${ rem(42) };

	h2 {
		${ typo.fontTitle }
		font-size: 1em;
		font-weight: 500;
	}

	.icon-star {
		font-size: 1.05em;
		margin: 0.31em 0.9em 0;
		color: ${ colors.green }
	}

	${ media.mobile`
		padding: ${ rem(15) } 0;
		font-size: ${ rem(30) };
	` }
`;
