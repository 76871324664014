import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

import { rem, typo, colors, media, mixins } from '$styles';
import { PrismicRich, PrismicText } from '$components/text';
import { Icon, Illustration, Image, ImageCover, ImageRatio } from '$components/image';
import { SectionTitle } from '$components/text/SectionTitle';
import { Slider } from '$components/global/Slider';
import { SmallBoxText } from '$components/text/SmallBoxText';
import { usePageContext } from '$store';
import classNames from 'classnames';
import isEmpty from '$utils/isEmpty';
import { Frame } from '$components/image/Frame';



export const query = graphql`
	fragment Chefs_Rich on PrismicPageRichDataBodyChefs {
		id slice_label slice_type
		primary {
			restaurant_chefs_title { text }
		}
		items {
			chefs_chef_text { html }
			chefs_chef_name { text }
			chefs_chef_image { alt dimensions { height width } url }
			chefs_chef_comingsoon
		}
	}
`;

const Chef = ({ name, image, text, isComingSoon, isFirstComingSoon }) => {
	const { siteData } = usePageContext();

	return isComingSoon ? (
		<div className={classNames('chef', 'chef-soon', { 'is-first': isFirstComingSoon })}>
			<div className="soon-content">
				{siteData.i18n_coming_soon}
				<Frame arch />
			</div>
		</div>
	) : (
		<div className="chef">
			<div className="chef-photo"><ImageRatio ratio="0.71" {...image} arch /></div>
			<PrismicText className="chef-name" text={name} />
			{ !isEmpty(text) && <PrismicRich className="chef-text" text={text} /> }
		</div>
	);
};

const ChefsComponent = ({ className, ...data }) => {
	className = classNames(className, 'chefs');

	let firstComingSoon = true;
	const chefs = (data.items || [])
		.map(v => ({
			text: v.chefs_chef_text,
			name: v.chefs_chef_name,
			image: v.chefs_chef_image,
			isComingSoon: v.chefs_chef_comingsoon
		}))
		.map(v => {
			v.isComingSoon |= !!(!v.name || !v.name.text || !v.image || !v.image.url);
			return v;
		})
		.map(v => {
			if (v.isComingSoon && firstComingSoon) {
				v.isFirstComingSoon = true;
				firstComingSoon = false;
			}
			return v;
		})
		.map((props, i) => <Chef {...props} index={i} key={props.name + '_' + i} />);


	const sliderOpts = {
		fixedWidth: rem(360),
		gap: rem(55),
		padding: { left: rem(65), right: rem(70) }
	};

	if (chefs.length < 1) return null;
	return <div className={className}>
		<SectionTitle text={data?.primary?.restaurant_chefs_title} parallax offset />
		<Slider className="chefs" options={sliderOpts}>{ chefs }</Slider>
	</div>;
};

export const Chefs = styled(ChefsComponent)`
	${ mixins.padding(1, 0, 1, 0, true) }

	h2 {
		${ mixins.margin(0, 1, 0, 1, true) }
	}

	.splide__track {
		overflow: visible;
	}

	.chef-name {
		${ typo.h3 }
		margin-top: 0.8em;
		margin-bottom: 0.2em;
	}

	.chef-text{
		${ typo.capsSmaller }
		text-decoration-line: underline;
		text-decoration-style: solid;
		text-underline-offset: 2px;
		text-decoration-color: ${ colors.green };
		text-decoration-thickness: 2px;
	}

	.chef-soon {
		position: relative;
		color: ${ colors.dust };
		background: ${ rgba(colors.green, 0.76) };
		width: 100%;
		border-top-left-radius: 5000px;
		border-top-right-radius: 5000px;
		padding-top: calc(100% / 0.71);
	}

	.soon-content {
		position: absolute;
		top: 0;
		left: 0;
		${ mixins.flexCenter }
		width: 100%;
		height: 100%;
		${ typo.h3 }
		text-transform: uppercase;
	}

	.chef-soon.is-first {
		color: ${ colors.green };
		background: ${ rgba(colors.green, 0.3) };
	}
`;

