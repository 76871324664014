import { graphql } from 'gatsby';

import React from 'react';
import styled from 'styled-components';

import { useGlobalContext } from '$store';
import { rem, typo, colors, media, mixins } from '$styles';
import { PrismicRich, PrismicText } from '$components/text';
import { Icon, Illustration, Image, ImageRatio } from '$components/image';
import { Button } from '$components/form/Button';
import classNames from 'classnames';
import { SectionTitle } from '$components/text/SectionTitle';

export const query = graphql`
	fragment ImagesGrid_Rich on PrismicPageRichDataBodyImagesGrid {
		id slice_label slice_type
		items {
			images_grid_image { alt dimensions { height width } url }
			images_grid_caption { html }
		}
	}
`;

const ImagesGridComponent = ({ className, ...data }) => {
	const { isMobile } = useGlobalContext();
	className = classNames(className, 'images-grid');
	let items = (data?.items || [])
		.filter(v => !!v.images_grid_image)
		.map(({ images_grid_image, images_grid_caption }, i) => (
			<div key={i} className={classNames({
				'image-block': true,
				'image-large': (i % 3 === 0),
				'image-normal': (i % 3 === 1),
				'image-portrait': (i % 3 === 2),
			})}>
				<ImageRatio { ...images_grid_image } arch={(i % 3 === 2)} />
				<PrismicRich className="caption" text={images_grid_caption} />
			</div>
		));

	// Reorder images on mobile
	if (isMobile) {
		let nItems = new Array(items.length);
		items.forEach((v, i) => {
			let index = i;
			if (i % 3 === 1) index += 1;
			else if (i % 3 === 2) index -= 1;
			nItems[ index ] = v;
		});
		items = nItems;
	}

	return <section className={className}>
		{items}
	</section>;
};

export const ImagesGrid = styled(ImagesGridComponent)`
	${ mixins.margin(1, 1, 1, 1, true) }
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: space-between;

	h3 {
		margin-top: 0.8em;
		margin-bottom: 0.1em;
		${ typo.h3 }
	}

	p {
		line-height: 1.5;
	}

	.image-block:not(:first-of-type) {
		margin-top: 6%;
	}

	.image-block {
		flex: 0 0 auto;
	}

	.image-large {
		width: 84%;
		--ratio: 1.6;
	}

	.image-normal {
		width: 52%;
		margin-left: 5%;
		--ratio: 1.5;
	}

	.image-portrait {
		padding-top: 3%;
		width: 30%;
		margin-right: 5%;
		--ratio: 0.667;
	}

	${ media.mobile`
		.image-large {
			width: 100%;
			--ratio: 1.7;
		}

		.image-portrait {
			width: 100%;
			margin-right: 0;
			padding-top: 0;
			--ratio: 0.8;
		}

		.image-normal {
			width: 90%;
			margin-left: 0;
			padding-top: 0;
		}
	` }
`;

