import page_rich from './PageRich';
import page_document from './PageDocument';
import page_home from './PageHome';
import page_menu from './PageMenu';

export default {
	page_rich,
	page_home,
	page_document,
	page_menu
};
