import { rem as polishedRem, em as polishedEm } from 'polished';

import { baseFontSize, defaultGridSize } from './metrics';

export function rem(value, base = baseFontSize) {
	return polishedRem(value, base);
}

export function em(value, base = baseFontSize) {
	return polishedEm(value, base);
}

export function fluidPx(px, variable = '--fluidPx') {
	return `calc(var(${ variable }) * ${ parseFloat(px, 10) })`;
}

export function sizeAsColVw(numberOfCols, gridSize = defaultGridSize) {
	return `${ (100 / gridSize) * numberOfCols }vw`;
}

export function sizeAsColPercent(numberOfCols, gridSize = defaultGridSize) {
	return `${ (100 / gridSize) * numberOfCols }%`;
}
