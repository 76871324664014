import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

import { usePageContext } from '$store';

export const query = graphql`
  fragment SEO_Home on PrismicPageHomeDataType { seo_title seo_description }
	fragment SEO_Standby on PrismicPageStandbyDataType { seo_title seo_description }
	fragment SEO_Rich on PrismicPageRichDataType { seo_title seo_description }
	fragment SEO_Document on PrismicPageDocumentDataType { seo_title seo_description }
	fragment SEO_Menu on PrismicPageMenuDataType { seo_title seo_description }
	fragment SEO_Booking on PrismicPageBookingDataType { seo_title seo_description }
	fragment SEO_404 on PrismicPage404DataType { seo_title seo_description }
`;

export function SEO({
	seo_title,
	seo_description,
	seo_image,
	...meta
}) {
	const { lang, siteData, hreflang } = usePageContext();
	const { site } = useStaticQuery(
		graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `
	);

	if (!siteData) return null;

	const {
		seo_title_suffix: titleSuffix,
		seo_title: defaultTitle,
		seo_description: defaultDescription,
		seo_image: defaultImage,
		seo_twitter_site,
	} = siteData;

	let title = seo_title || defaultTitle;
	if (title !== defaultTitle) {
		if (title.endsWith(' ')) title = title.slice(0, -1);
		if (!titleSuffix.startsWith(' ')) title += ' ';
		title += titleSuffix;
	}

	const description = seo_description || defaultDescription;
	const hasImage = !!seo_image?.url;
	const image = hasImage ? seo_image : defaultImage;

	const metaImage = image.url
		? [
			{
				property: 'og:image',
				content: image.url,
			},
			{
				property: 'og:image:width',
				content: image.dimensions?.width || null,
			},
			{
				property: 'og:image:height',
				content: image.dimensions?.height || null,
			},
		]
		: [];

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			meta={[
				{
					name: `description`,
					content: description,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: description,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: description,
				},
				{
					name: `twitter:site`,
					content: seo_twitter_site,
				},
			]
				.concat(metaImage)
				.concat(meta)
			}
		>
			{hreflang.map(({ lang, url }) => (
				<link
					key={url}
					rel="alternate"
					href={`${ site.siteMetadata.siteUrl }${ url }`}
					hrefLang={lang}
				/>
			))}
		</Helmet>
	);
}

