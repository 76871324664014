import { graphql } from 'gatsby';

import React from 'react';
import styled from 'styled-components';

import { useGlobalContext } from '$store';
import { rem, typo, colors, media, mixins } from '$styles';
import { PrismicRich, PrismicText } from '$components/text';
import { Icon, Illustration, Image, ImageRatio } from '$components/image';
import { Button } from '$components/form/Button';
import classNames from 'classnames';
import { SectionTitle } from '$components/text/SectionTitle';

export const query = graphql`
  fragment ImageText_Rich on PrismicPageRichDataBodyImageText {
		id slice_label slice_type
		primary {
			image_text_title { text }
			image_text_text { html text }
			image_text_image { url alt dimensions { width height } }
		}
	}
`;

const ImageTextComponent = ({ className, ...data }) => {
	const { isMobile } = useGlobalContext();
	className = classNames(className, 'image-text');
	data = data?.primary || {};

	return <div className={className}>
		<SectionTitle text={data.image_text_title} offset parallax />
		<div className="wrapper">
			<div className="image">
				{
					data.image_text_image
					&& <ImageRatio ratio="0.7" parallax={ isMobile ? 0 : 0.4 } {...data.image_text_image} />
				}
			</div>
			<PrismicRich className="content" text={data.image_text_text} />
		</div>
	</div>;
};

export const ImageText = styled(ImageTextComponent)`
	${ mixins.margin(1, 1, 1, 1, true) }

	.wrapper {
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-start;
		justify-content: space-between;
	}

	div.image {
		flex: 0 0 auto;
		${ mixins.padding(0.5, null, null, 1, true) }
		width: 50%;
	}

	.content {
		flex: 1 1 auto;
		${ mixins.padding(0.7, 1, 0, 1, true) }
	}

	h3 {
		${ typo.h3 }
		max-width: 16em;
		margin-bottom: 1em;
	}

	p {
		${ typo.text }
		margin-top: 1em;
		max-width: 30em;
	}

	${ media.mobile`
		.wrapper {
			display: block;
		}

		div.image {
			${ mixins.padding(0, 0, 0, 0, true) }
			width: 100%;
		}

		.image-ratio {
			--ratio: 0.85;
		}
	` }

`;

