import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

import { rem, typo, colors, media, mixins } from '$styles';
import { PrismicRich, PrismicText } from '$components/text';
import { Icon, Illustration, Image, ImageCover, ImageRatio } from '$components/image';
import { SectionTitle } from '$components/text/SectionTitle';
import { Slider } from '$components/global/Slider';
import { SmallBoxText } from '$components/text/SmallBoxText';
import { useGlobalContext, usePageContext } from '$store';
import classNames from 'classnames';
import isEmpty from '$utils/isEmpty';
import { Frame } from '$components/image/Frame';
import { Card } from '$components/global/Card';

export const query = graphql`
	fragment CardImage_Rich on PrismicPageRichDataBodyCardImage {
		id slice_label slice_type
		primary {
			type: card_type
			image: card_image { alt dimensions { height 	width } url }
			title: card_title { text }
			text: card_content { html text }
			minorCtaText: card_cta_minor_text
			minorCtaLink: card_cta_minor_link { id url type target }
			ctaText: card_cta_text
			ctaLink: card_cta_link { url target id type }
		}
	}
`;

const CardImageComponent = ({ className, comboIndex, ...data }) => {
	const { isMobile } = useGlobalContext();

	data = data?.primary || {};
	if (data.card_type) data.type = data.card_type;
	if (data.card_image) data.image = data.card_image;
	if (data.card_title) data.title = data.card_title;
	if (data.card_content) data.text = data.card_content;
	if (data.card_cta_minor_text) data.minorCtaText = data.card_cta_minor_text;
	if (data.card_cta_minor_link) data.minorCtaLink = data.card_cta_minor_link;
	if (data.card_cta_text) data.ctaText = data.card_cta_text;
	if (data.card_cta_link) data.ctaLink = data.card_cta_link;

	const isAlt = comboIndex % 2;


	className = classNames(className, {
		'card-image': true,
		'card-image-alt': isAlt,
		'card-alternating': data.type.includes('alternating'),
		'card-large': data.type.includes('large')
	});



	const iMult = isMobile ? 0 : 1;
	const cMult = isMobile ? 0.4 : 1;

	return <section className={className}>
		{
			data.image
			&& <ImageRatio
				{...data.image}
				ratio="1.167"
				parallax={(isAlt ? -0.1 : 0.1) * iMult}
			/>
		}
		<div className="card-wrapper">
			<Card
				{...data}
				green={isAlt || data.type.includes('green')}
				parallax={(isAlt ? 0.22 : -0.22) * cMult}
			/>
		</div>
	</section>;
};

export const CardImage = styled(CardImageComponent)`
	${ mixins.margin(1, 1, 1, 1, true) }

	&.card-large{
		${ mixins.margin(1.5, 1, 0.5, 1, true) }
		${ media.mobile`margin-top: ${ rem(30) };` }
	}

	position: relative;
	border: 1px solid transparent;

	--margin: ${ rem(90) };
	${ media.mobile` --margin: ${ rem(70) }; ` }

	.image-ratio {
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		width: ${ rem(700) };
	}

	.card-wrapper {
		position: relative;
		z-index: 1;
		margin-left: auto;
		margin-top: var(--margin);
		width: 47%;
		display: flex;
		flex-flow: column nowrap;
		align-items: stretch;
		justify-content: flex-end;
	}


	&.card-alternating .card-wrapper {
		margin-right: ${ rem(50) };
	}

	&.card-alternating.card-image-alt .card-wrapper {
		margin-right: 0;
		margin-left: ${ rem(50) };
	}


	.card {
		margin-top: 0;
	}

	&.card-image-alt {
		.card-wrapper {
			margin-left: 0;
			margin-right: auto;
			margin-top: 0;
			margin-bottom: calc(var(--margin));
		}

		.image-ratio {
			top: auto;
			left: auto;
			right: 0;
			bottom: 0;
		}
	}

	&.card-large {
		.image-ratio {
			--ratio: 1.5;
			width: ${ rem(730) };
		}

		.card-wrapper {
			min-height: ${ rem(500) };
			width: 50%;
		}
	}

	${ media.mobile`
		border: none;
		margin-left: 0 !important;
		margin-right: 0 !important;
		margin-bottom: ${ rem(40) };

		.image-ratio {
			position: relative;
			top: auto; left: auto; right: auto; bottom: auto;
			width: 100%;
			margin: 0;
			--ratio: 1.4;
		}

		.card-wrapper, 	&.card-image-alt .card-wrapper {
			min-height: 0 !important;
			margin-top: ${ rem(-120) };
			margin-right: ${ rem(20) } !important;
			margin-left: ${ rem(20) } !important;
			width: auto !important;
		}
	` }

`;

