module.exports = function linkResolver({ uid, type, lang, link_type, url }) {
	if (link_type === 'Web') return url ? url : '/';
	if (!uid || !lang) return '/';

	const [ locale ] = lang.split('-');
	const isDefaultLocale = locale === 'fr';

	switch (type) {
		// STANDBY UPDATE - page_standby becomes the new homepage
		case 'page_standby':
			return isDefaultLocale ? `/` : `/${ locale }/`;
		// case 'page_home':
		// 	return isDefaultLocale ? `/` : `/${ locale }/`;
		case 'page_404':
			return isDefaultLocale ? `/404/` : `/${ locale }/404/`;
		default:
			return isDefaultLocale ? `/${ uid }/` : `/${ locale }/${ uid }/`;
	}
};
