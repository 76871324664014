import { graphql } from 'gatsby';

import React from 'react';
import styled from 'styled-components';

import { rem, typo, colors, media, mixins } from '$styles';

import { PrismicRich, PrismicText } from '$components/text';
import { Icon, Illustration, Image, ImageCover } from '$components/image';
import classNames from 'classnames';
import { Parallax } from '$components/global/Parallax';
import { CTA } from '$components/form/CTA';
import { Slider } from '$components/global/Slider';

export const query = graphql`
	fragment StandbyEminente_Standby on PrismicPageStandbyDataBodyStandbyEminente {
		id slice_label slice_type
		primary {
			eminente_cta_link { url type target id }
			eminente_cta_text
			eminente_text { html }
			eminente_image { url dimensions { height width } alt }
		}
	}
`;

const StandbyEminenteComponent = ({ className, ...data }) => {
	const {
		eminente_text,
		eminente_cta_text,
		eminente_cta_link,
		eminente_image
	} = data?.primary || {};
	// const images = (data?.items || []).map(v => v.casa_image).filter(Boolean);

	className = classNames(className, 'section-home-casa');
	return <section className={className}>
		<div className="content">
			<Parallax strength={-3}>
				<Icon id="eminente" />
			</Parallax>
			<PrismicRich className='text' text={eminente_text} />
			<CTA {...{ ctaLink: eminente_cta_link, ctaText: eminente_cta_text }} />
		</div>
		<figure className="bg">
			<ImageCover {...eminente_image} />
		</figure>
	</section>;
};

export const StandbyEminente = styled(StandbyEminenteComponent)`
	background: ${ colors.brown };
	position: relative;
	overflow: hidden;

	.content {
		${ mixins.padding(1, 1, 1.2, 1, true) }
		position: relative;
		z-index: 2;
		color: ${ colors.dust };
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;
		min-height: ${ rem(750) };
		pointer-events: none;

		button, a {
			pointer-events: all;
		}
	}

	.icon-eminente {
		width: ${ rem(400) };
		margin-bottom: ${ rem(50) };
		height: auto;

		${ media.mobile`
			width: min(300px, 60vw);
			` }
	}

	.title {
		text-align: center;
		margin-bottom: 0.4em;
		${ typo.h2 }
		max-width: 15em;
	}

	.text {
		text-align: center;
		${ typo.h3 }
		max-width: 50ch;
		margin-bottom: 2em;
		${ typo.shadow() }
	}

	.cta {
		${ typo.shadow() }
	}

	${ media.mobile`
		.content {
			min-height: ${ rem(550) };
		}

		.title {
			margin-bottom: 0.6em;
		}

		.text {
			max-width: 26em;
		}
	` }

	// Slider
	.bg {
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.bg:after {
		content: '';
		display: block;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, rgba(34, 40, 34, 0.4) 0%, rgba(29, 37, 29, 0.73) 100%);
	}
`;

StandbyEminente.sliceType = 'standby_eminente';
