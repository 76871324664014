import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { media, mixins, colors, z, rem } from '$styles';
import { Icon } from '$components/image';
import { Link } from '$components/global/Link';

const FooterLogoComponent = ({ className }) => {
	className = classNames(className, 'footer-logo');

	return <section className={className}>
		<Link to="/">
			<Icon id="logo" />
		</Link>
	</section>;
};

export const FooterLogo = styled(FooterLogoComponent)`
	${ mixins.flexCenter };
	min-width: ${ rem(320) };

	.icon {
		${ mixins.margin(0.3, 0.3, 0.3, 0.3, true) }
		width: ${ rem(180) };
		height: auto;
	}

	${ media.smallFooter`
	min-width: ${ rem(270) };
	` }

	${ media.mobile`
		.icon {
			width: ${ rem(140) };
		}
	` }
`;
