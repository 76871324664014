import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { useGlobalContext, usePageContext } from '$store';
import { mixins, rem, typo, colors, media } from '$styles';
import { PrismicText, PrismicSpan, PrismicRich } from '$components/text';
import { Icon, Illustration } from '$components/image';
import { Button } from '$components/form/Button';
import { Parallax } from '$components/global/Parallax';
import { CTA } from '$components/form/CTA';
import { SectionTitle } from '$components/text/SectionTitle';
import linkResolver from '$prismic/linkResolver';

const DOTS = '.'.repeat(200);

const formatPrice = (v) => {
	if (!v) return null;
	v = (v + '').trim();
	if (!v.endsWith('€')) v += '€';
	return v;
};

const CarteComponent = ({ className, ...data }) => {
	const title = data?.menu_title || '';
	const suptitle = data?.menu_suptitle || '';
	const items = (data?.body || []).map(cat => ({
		key: cat.id,
		title: cat?.primary?.menu_category_title || '',
		illustration: cat?.primary?.menu_category_illustration,
		items: cat.items
			.map((item, i) => ({
				key: item?.menu_element_text + '_' + i,
				text: item?.menu_element_text || '',
				price: formatPrice(item?.menu_element_price)
			}))
	}));

	return <section className={className}>
		<section className="heading">
			<div className='suptitle'>
				<PrismicText tag="div" text={suptitle} />
				<Icon id="line-horizontal" freeScale />
			</div>
			<PrismicText tag="h1" text={title} />
		</section>
		<section className="list">
			{items.map(({ key, title, illustration, items }) => (
				<div key={key} className="category">
					<PrismicText tag="h3" text={title} />
					<ul className='items'>
						{items.map(({ key, text, price }) => (
							<li className="item" key={key}>
								<PrismicRich className="item-text" text={text} />
								{ price && <PrismicText tag="p" className="item-price" text={price} /> }
							</li>
						))}
					</ul>
					{
						illustration
						&& <Illustration id={illustration} color="green" parallax="-1"/>
					}
				</div>
			))}
		</section>
	</section>;
};

export const Carte = styled(CarteComponent)`
	${ mixins.padding(1, 1, 1, 1, true) }

	// HEADING
	.heading {
		h1 {
			${ typo.h1 };
			text-transform: uppercase;
			color: ${ colors.green };
			margin-bottom: 0.5em;
		}

		.suptitle {
			display: inline-block;
			position: relative;
			margin-bottom: 0.6em;
			${ typo.h3 }

			.icon {
				position: absolute;
				bottom: -0.2em;
				left: 0;
				width: 100%;
				height: 3px;
				color: ${ colors.green };
			}
		}
	}

	// LIST
	.list {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
		justify-content: space-between;

		${ mixins.padding(0.3, 1, 0.2, 1, true) }
		${ media.below(1000)`${ mixins.padding(0.3, 0.6, 0.2, 0.6, true) }` }
		${ media.mobile`${ mixins.padding(0.3, 1, 0.2, 1, true) }` }

		.category {
			position: relative;
			width: 42%;
			margin-bottom: ${ rem(50) };

			${ media.below(1300)`width: 44%;` }
			${ media.below(1100)`width: 46%;` }
			${ media.below(900)`width: 48%;` }
			${ media.mobile`width: 100%;` }
		}

		h3 {
			${ typo.h3 }
			font-size: max(22px, ${ rem(32) }); // 28 fontsize_update
  		${ media.mobile`  font-size: ${ rem(26) };  ` }

			text-transform: uppercase;
			margin-bottom: 1em;
		}

		.items {
			width: 100%;
			display: flex;
			flex-flow: row wrap;
			align-items: flex-start;
			justify-content: space-between;
		}

		.item {
			${ typo.text }
			width: 100%;
			/* display: flex;
			flex-flow: row nowrap;
			align-items: flex-end;
			justify-content: space-between; */
			margin-bottom: 2em;
		}

		.item-text {
			${ typo.fontTitle }
			font-size: 1.4em;
			line-height: 1.3em;
			text-transform: none;
			color: ${ colors.green };
		}

		.item-text h4 {
			text-transform: uppercase;
			font-weight: 500;
		}

		.item-price {
			${ typo.smallCaps }
			font-size: max(12.5px, ${ rem(12.5) });
			flex: 0 0 auto;
			font-weight: ${ typo.fontWeight.medium };
			margin-top: 0.7em;
		}

		.illus {
			display: block;
			margin-left: auto;
			margin-top: ${ rem(-40) };
			--width: ${ rem(140) };
			${ media.mobile`
				--width: 20vw;
			` }
		}

		.category:nth-child(even) .illus {
			margin-right: ${ rem(60) };
			margin-top: ${ rem(-25) };
		}

		.category:nth-child(odd) .illus {
			margin-right: ${ rem(-60) };
			margin-top: ${ rem(-53) };
			${ media.mobile`
				margin-right: 5vw;
			` }
		}
	}
`;
