import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import withPrismicPreview from '$utils/withPrismicPreview';
import { SEO } from '$components/global/SEO';
import { PageContainer as _PageContainer } from '$components/global/PageContainer';
import SliceZone from '$components/global/SliceZone';

import { rem, spacing, media, colors, mixins, typo } from '$styles';
import { PrismicText } from '$components/text';

import { Accordion } from '$components/slices/Accordion';
import { Text } from '$components/slices/Text';
import { Illustration } from '$components/image';
import { Parallax } from '$components/global/Parallax';


export const pageQuery = graphql`
  query ($uid: String!, $lang: String!) {
		prismicPageDocument(uid: { eq: $uid }, lang: { eq: $lang }) {
			...Base_Document
			data {
				...SEO_Document
				title { text }
        illustration_top_left
       	illustration_right_center
				body {
          ...Accordion_Document
					...Text_Document
        }
			}
		}
	}
`;
export default withPrismicPreview(({ className, ...props }) => {
	const data = props.data?.prismicPageDocument?.data || {};

	return (
		<PageContainer className={className} data={data}>
			<SEO {...data} />
			<div className='page-content'>
				<Parallax strength="3">
					<div className='illustration illustration-left'>
						<Illustration className='illus-left' color="green" id={data.illustration_top_left} />
					</div>
				</Parallax>
				<Parallax strength="-3">
					<div className='illustration illustration-right'>
						<Illustration className="illus-right" color="green" id={data.illustration_right_center} />
					</div>
				</Parallax>
				<div className="wrapper">
					<h1><PrismicText tag="span" text={data?.title} data-nosnippet="" /></h1>
					<div className="content">
						<SliceZone {...data} />
					</div>
				</div>
			</div>
		</PageContainer>
	);
});

const PageContainer = styled(_PageContainer)`
	.page-content {
		position: relative;
		overflow: hidden;
	}

	.wrapper {
		${ mixins.padding(1, 1, 1, 1, true) }
		max-width: 70%;
		margin: 0 auto;
	}

	h1 {
		${ typo.h1 }
		text-transform: uppercase;
		color: ${ colors.green };
		max-width: 9em;
		margin-bottom: 0.8em;
	}

	.content {
		max-width: 90%;
		margin: 0 auto;
	}

  ${ Text },
  ${ Accordion } {
    margin: ${ spacing.s3 } 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${ Accordion } + ${ Text } {
    margin-top: ${ spacing.s6 };
  }

	${ media.smallDesktop`
		.wrapper {
			max-width: 90%;
		}
	` }

	${ media.mobile`
		.wrapper {
			max-width: 100%;
		}

		h1 {
			max-width: 9em;
			margin-bottom: 1.3em;
		}

		.content {
			max-width: 95%;
		}
	` }


	// Illustration
	.illustration {
		position: absolute;
	}

	.illustration-left {
		top: 0;
		left: 0;
	}

	.illustration-right {
		top: ${ rem(300) };
		right: 0;
	}

	.illus {
		--offset: 0px;
		--width: calc(${ rem(220) } * var(--size-mult, 1));
	}

	.illus-left {
		margin-left: calc((var(--width) + var(--offset)) * -0.2);
	}

	.illus-right {
		margin-right: calc((var(--width) + var(--offset)) * -0.15);
	}

	.illus-right {
		transform: scaleX(-1);
	}

	${ media.smallDesktop`
		.illustration-right {
			display: none;
		}

		.illus {
			--width: calc(16vw * var(--size-mult, 1));
		}

		.illustration-left {
			top: 0;
			right: 0;
			left: auto;
		}

		.illus-left {
			margin-right: calc((var(--width) + var(--offset)) * -0.15);
			margin-left: 0;
		}

		.illus-left {
			transform: scaleX(-1);
		}
	` }

	.illus-tree {
		--size-mult: 0.7;
	}

	.illus-sugarcanes {
		--size-mult: 0.35;
		--offset: ${ rem(-260) };
	}

	.illus-bottle {
		--size-mult: 0.4;
		--offset: ${ rem(-300) };
	}

	.illus-right.illus-bird {
		transform: scaleX(-1) rotate(-45deg);
	}
`;

