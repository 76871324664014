import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

import { rem, typo, colors, media, mixins } from '$styles';
import { PrismicRich, PrismicText } from '$components/text';
import { Icon, Illustration, Image, ImageCover, ImageRatio } from '$components/image';
import { SectionTitle } from '$components/text/SectionTitle';
import { Slider } from '$components/global/Slider';
import { SmallBoxText } from '$components/text/SmallBoxText';
import { useGlobalContext, usePageContext } from '$store';
import classNames from 'classnames';
import isEmpty from '$utils/isEmpty';
import { Frame } from '$components/image/Frame';
import { Card } from '$components/global/Card';

export const query = graphql`
	fragment DuoImages_Home on PrismicPageHomeDataBodyDuoImages {
		id slice_label slice_type
		primary {
			title: duo_images_title { text }
			text: duo_images_text { text html }
			illustration: duo_images_illustration
			imageLandscape: duo_images_landscape { alt dimensions { height width } url }
			imagePortrait: duo_images_portrait { alt dimensions { height 	width } url }
		}
	}
	fragment DuoImages_Rich on PrismicPageRichDataBodyDuoImages {
		id slice_label slice_type
		primary {
			title: duo_images_title { text }
			text: duo_images_text { text html }
			illustration: duo_images_illustration
			imageLandscape: duo_images_landscape { alt dimensions { height width } url }
			imagePortrait: duo_images_portrait { alt dimensions { height 	width } url }
		}
	}
`;

const DuoImagesComponent = ({ className, ...data }) => {
	const { isMobile } = useGlobalContext();
	data = data?.primary || {};
	if (data.duo_images_title) data.title = data.duo_images_title;
	if (data.duo_images_text) data.text = data.duo_images_text;
	if (data.duo_images_illustration) data.illustration = data.duo_images_illustration;
	if (data.duo_images_landscape) data.imageLandscape = data.duo_images_landscape;
	if (data.duo_images_portrait) data.imagePortrait = data.duo_images_portrait;


	className = classNames(className, {
		'duo-images': true,
	});

	return <section className={className}>
		<SectionTitle text={data.title} parallax="-1" offset />
		<div className="images">
			{
				data.imageLandscape
				&& <div className="image-landscape">
					<ImageCover {...data.imageLandscape } />
				</div>
			}
			{
				data.imagePortrait
				&& <div className="image-portrait">
					<ImageCover {...data.imagePortrait } />
				</div>
			}
			<Illustration
				color="green"
				id={data.illustration}
				parallax="1"
			/>
		</div>
		<PrismicRich className="content" text={data.text} />
	</section>;
};

export const DuoImages = styled(DuoImagesComponent)`
	${ mixins.padding(1, 1, 0.3, 1, true) }

	.page-home &  {
		${ mixins.padding(1, 1, 1, 1, true) }
	}

	h2 {
		min-width: 14em;
		hyphens: none;
	}

	.images {
		position: relative;
		display: flex;
		flex-flow: row nowrap;
		align-items: stretch;
		justify-content: space-between;
		height: ${ rem(500) };
	}

	.image-landscape {
		position: relative;
		width: 65%;
	}

	.image-portrait {
		position: relative;
		width: 30%;
	}

	.illus {
		position: absolute;
		z-index: 1;
		--width ${ rem(160) };
		left: calc(var(--width) * -0.5 + 30%);
		bottom: calc(var(--height) * -0.6);
	}

	.content {
		${ typo.text }
		margin-top: 3em;
		width: 51%;
		margin-left: auto;

		.page-home & {
			margin-top: 6em;
		}

		p {
			max-width: 32em;

			.page-home & {
				max-width: 25em;
			}
		}

		h3 {
			${ typo.h3 }
			margin-bottom: 1em;
			max-width: 25em;
		}
	}

	${ media.mobile`
		h2 {
			min-width: 7em;
		}

		.images {
			position: relative;
			display: block;
			height: auto;
		}

		.image-landscape {
			width: 100%;
			height: 55vw;
		}

		.image-portrait {
			margin-top: 6vw;
			width: 70%;
			height: 75vw;
		}

		.illus {
			--width: 19vw;
			left: auto;
			right: calc(var(--width) * -0.5 + 26%);
			bottom: calc(var(--height) * -0.1);
		}


		.content {
			width: 100%;

			p {
				max-width: 28em;
			}

			h3 {
				max-width: 18em;
			}
		}
	` }
`;

