import { css } from 'styled-components';
import colors from './colors';
import { rem } from './utils';
import { media } from './media';

export * from './backgrounds';

export const margin = (t = 0, r = 0, b = 0, l = 0) => css`
  ${ t != null && `margin-top: ${ rem(80 * t) }` };
  ${ r != null && `margin-right: ${ rem(60 * r) }` };
	${ b != null && `margin-bottom: ${ rem(80 * b) }` };
  ${ l != null && `margin-left: ${ rem(60 * l) }` };
  ${ media.mobile`
		${ t != null && `margin-top: ${ rem(60 * t) }` };
		${ r != null && `margin-right: ${ rem(16 * r) }` };
		${ b != null && `margin-bottom: ${ rem(60 * b) }` };
		${ l != null && `margin-left: ${ rem(16 * l) }` };
  ` }
`;

export const padding = (t = 0, r = 0, b = 0, l = 0, mobile = true) => css`
  ${ t != null && `padding-top: ${ rem(80 * t) }` };
  ${ r != null && `padding-right: ${ rem(60 * r) }` };
	${ b != null && `padding-bottom: ${ rem(80 * b) }` };
  ${ l != null && `padding-left: ${ rem(60 * l) }` };
  ${ mobile != null && media.mobile`
		${ t != null && `padding-top: ${ rem(60 * t) }` };
		${ r != null && `padding-right: ${ rem(16 * r) }` };
		${ b != null && `padding-bottom: ${ rem(60 * b) }` };
		${ l != null && `padding-left: ${ rem(16 * l) }` };
  ` }
`;

export const headerPadding = css`
	/* padding-top: var(--header-height); */
`;

export const linkReset = css`
  text-decoration: none;
  outline: none;
  color: currentColor;
  cursor: pointer;
  letter-spacing: inherit;
`;

export const buttonReset = css`
  text-decoration: none;
  letter-spacing: inherit;
  font: inherit;
  border-radius: 0;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;
  color: inherit;
  text-align: inherit;
  text-transform: inherit;
`;

export const hiddenScrollbar = css`
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const stroke = '1px solid ' + colors.black;

export const fullSize = css`
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
`;

export const flexCenter = css`
	flex: 0, 0, auto;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
`;

export const hasHover = block => css`
	@media (hover: hover) and (pointer: fine) {
		${ block }
	}
`;

export const hover = block => css`
	@media (hover: hover) and (pointer: fine) {
		&:hover {
			${ block }
		}
	}
`;

export const arch = css`
	overflow: hidden;
	backface-visibility: hidden;
	border-top-left-radius: 2000px;
	border-top-right-radius: 2000px;
`;

export const overlay = (opacity = 0.5, zIndex = 0) => css`
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: ${ colors.black };
	z-index: ${ zIndex };
	opacity: ${ opacity };
`;

export const bolder = (v = 0.5) => css`
	-webkit-text-stroke: ${ v }px currentColor;
	`;

export const underline = (color, thickness, offset) => css`
	text-decoration-line: underline;
	text-decoration-style: solid;
	text-underline-offset: ${ offset || '0.15em' };
	text-decoration-color: ${ color || colors.green };
	text-decoration-thickness: ${ thickness || '1.5px' };
`;

export const sliderDot = `background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 4' preserveAspectRatio='none'%3e%3cpath d='M9 3.5h-.1l-3.2-.8-4.6.8a.5.5 0 0 1-.2-1l2.4-.4L1 1.5A.5.5 0 0 1 .5.9.5.5 0 0 1 1 .5l7.8.7a.5.5 0 0 1 .4.4.5.5 0 0 1-.4.6H8l1 .3a.5.5 0 0 1 .4.6.5.5 0 0 1-.5.4Z' fill='black'/%3e%3c/svg%3e");`;
export const sliderDotActive = `background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 4' preserveAspectRatio='none'%3e%3cpath d='M9 3.5h-.1l-3.2-.8-4.6.8a.5.5 0 0 1-.2-1l2.4-.4L1 1.5A.5.5 0 0 1 .5.9.5.5 0 0 1 1 .5l7.8.7a.5.5 0 0 1 .4.4.5.5 0 0 1-.4.6H8l1 .3a.5.5 0 0 1 .4.6.5.5 0 0 1-.5.4Z' fill='%23496048'/%3e%3c/svg%3e"); !important`;
export const sliderDotDust = `background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 4' preserveAspectRatio='none'%3e%3cpath d='M9 3.5h-.1l-3.2-.8-4.6.8a.5.5 0 0 1-.2-1l2.4-.4L1 1.5A.5.5 0 0 1 .5.9.5.5 0 0 1 1 .5l7.8.7a.5.5 0 0 1 .4.4.5.5 0 0 1-.4.6H8l1 .3a.5.5 0 0 1 .4.6.5.5 0 0 1-.5.4Z' fill='%23E9DBCD'/%3e%3c/svg%3e"); !important`;
