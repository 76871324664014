import React from 'react';
import classNames from 'classnames';
import styled, { keyframes } from 'styled-components';

import { useGlobalContext } from '$store';
import { rem, typo, colors, media, mixins } from '$styles';
import { LinkUnderline, PrismicRich, PrismicSpan, PrismicText } from '$components/text';
import { Icon, Illustration, ImageCover } from '$components/image';
import { ImageRatio } from '$components/image/ImageRatio';
import { CTA } from '$components/form/CTA';

const inAnim = keyframes`
	from { opacity: 0; }
	to { opacity: 1; }
`;

const TabComponent = ({ className, ...data }) => {
	const { isMobile } = useGlobalContext();

	className = classNames(className, {
		'is-reverse': data.invert,
		'is-mobile': isMobile
	});

	const cta = <CTA {...(data.cta || {})}/>;

	const quote = <PrismicRich key="a" className="quote" text={data.quote || ''} />;
	const text = <div key="b">
		<PrismicRich className="text" text={data.text || ''} />
		{ cta }
	</div>;

	const smallImg = (<div key="c" className='img img-small'>
		{data.imageSmall && <ImageRatio {...data.imageSmall} />}
	</div>);

	const bigImg = (<div key="d" className='img img-big'>
		{data.imageBig && <ImageRatio {...data.imageBig} />}
		{ data.illustration && <Illustration
			id={data.illustration}
			color="green"
			parallax
		/> }
	</div>);

	return <section className={className}>
		{ isMobile ? <>
			{ smallImg } { quote } { bigImg } { text }
		</> : <>
			<div key="a" className="top">{[ smallImg, bigImg ]}</div>
			<div key="b" className="bottom">{[ quote, text ]}</div>
		</> }
	</section>;
};


export const Tab = styled(TabComponent)`
	opacity: 1;
	animation: ${ () => inAnim } 500ms forwards;

	.cta {
		margin-top: 1.5em;
	}

	.img {
		position: relative;
		width: 100%;
	}

	.img-small {
		${ mixins.arch }
		--ratio-mult: 0.9;
	}

	.text {
		p {
			${ typo.text }
			max-width: 40em;
		}

		h3 {
			${ typo.h3 }
			position: relative;
			max-width: 15em;
			padding-bottom: 1em;
			margin-bottom: 0.6em;
		}

		h3:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			background: ${ colors.black };
			height: 1px;
			width: 4em;
		}
	}

	.quote {
		${ typo.fontTitle }
		font-size: ${ rem(32) }; // 40 fontsize_update
		max-width: 18em;
		line-height: 1.5em;
		text-transform: uppercase;
		margin-top: 2em;

		p:not(:first-child) {
			margin-top: 0.5em;
		}

		em {
			display: block;
			font-weight: 500;
			font-style: normal;
			color: ${ colors.green }
		}

		${ media.mobile`
			font-size: 20px; // 22 fontsize_update
			line-height: 1.36em;
		` }
	}

	.top {
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-start;
		justify-content: space-between;

		.img-small {
			width: ${ rem(382) };
			margin-left: 2em;
		}

		.img-big {
			width: ${ rem(750) };
		}

		.image-ratio {
			height: ${ rem(500) };
		}
	}

	.bottom {
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-start;
		justify-content: space-between;
		margin-top: ${ rem(60) };

		.quote {
			flex: 0 0 auto;
			width: 60%;
			margin-right: 1.5em;
		}

		.text {
			flex: 1 1 auto;
		}
	}

	&.is-mobile {
		.quote { margin-top: ${ rem(30) } }
		.text { margin-top: ${ rem(30) } }
		.img-big { margin-top: ${ rem(60) } }
	}

	&.is-reverse .top, &.is-reverse .bottom {
		flex-direction: row-reverse;
	}

	&.is-reverse .bottom .quote {
		margin-left: 1.5em;
		margin-right: auto;
	}

	// Illustration position
	.illus {
		position: absolute;
		object-fit: contain;
		--w: ${ rem(270) };
		--h: calc(var(--w) / 1.15);
		width: var(--w);
		height: var(--h);
		top: auto;
		right: auto;
		bottom: calc(var(--h) * -0.44);
		left: calc(var(--w) * -0.42);
	}

	&.is-reverse .illus {
		top: auto;
		right: calc(var(--w) * -0.15);
		bottom: calc(var(--h) * -0.34);
		left: auto;
	}

	&.is-mobile .illus {
		--w: 30vw;
		top: calc(var(--h) * -0.5);
		right: calc(var(--w) * 0.1);
		bottom: auto;
		left: auto;
	}
`;
