import { graphql } from 'gatsby';

import React from 'react';
import styled from 'styled-components';

import { useGlobalContext } from '$store';
import { rem, typo, colors, media, mixins } from '$styles';
import { PrismicRich, PrismicText } from '$components/text';
import { Icon, Illustration, Image, ImageRatio } from '$components/image';
import { Button } from '$components/form/Button';
import classNames from 'classnames';

export const query = graphql`
  fragment ImageTextGreen_Rich on PrismicPageRichDataBodyImageTextGreen {
		id slice_label slice_type
		primary {
			image_text_green_title { text }
			image_text_green_text { html text }
			image_text_green_footer { html text }
			image_text_green_image { url alt dimensions { width height } }
		}
	}
`;

const ImageTextGreenComponent = ({ className, ...data }) => {
	className = classNames(className, 'image-text-green');
	data = data?.primary || {};

	return <div className={className}>
		<div className="right">
			<PrismicText tag="h2" text={data.image_text_green_title} />
			<PrismicRich className="content" text={data.image_text_green_text} />
			<PrismicRich tag="footer" text={data.image_text_green_footer} />
		</div>
		<div className="left">
			{ data.image_text_green_image && <ImageRatio ratio="0.662" {...data.image_text_green_image} /> }
		</div>
	</div>;
};

export const ImageTextGreen = styled(ImageTextGreenComponent)`
	${ mixins.bgGreen }
	${ mixins.padding(1, 1, 1, 1, true) }
	${ typo.fontTitle }

	display: flex;
	flex-flow: row-reverse nowrap;
	align-items: center;
	justify-content: space-between;



	.left {
		width: 50%;
		${ mixins.padding(0, 0, 0, 1, true) }
	}

	.right {
		width: 50%;
		${ mixins.padding(0, 0.5, 0, 0.5, true) }

		display: flex;
		flex-flow: column nowrap;
		align-items: flex-start;
		justify-content: center;


		h2 {
			font-size: ${ rem(42) };
			line-height: 1.3;
			${ media.mobile`  font-size: ${ rem(35) }; ` }
			${ media.smallMobile` font-size: ${ rem(32) }; ` }
			max-width: 12em;
		}

		.content, footer {
			font-size: ${ rem(25) };
			line-height: 1.3;
			font-weight: ${ typo.fontWeight.medium };
			${ media.mobile`  font-size: ${ rem(22) }; ` }
			${ media.smallMobile` font-size: ${ rem(22) }; ` }
			max-width: 18em;
		}

		p {
			margin-top: 1em;
		}

		footer {
			width: 100%;
			margin-top: 2em;
			text-align: right;

			p {
				margin-top: 0;
			}
		}

		footer em {
			${ typo.text }
			${ typo.capsSmaller }
			${ mixins.underline(colors.white, null, '0.2em') }
			letter-spacing: 0.12ch;
			font-weight: ${ typo.fontWeight.medium };
			font-style: normal;

		}
	}

	${ media.mobile`
		display: block;

		.left, .right {
			width: 100%;
			padding: 0;
		}

		.right {
			margin-bottom: ${ rem(60) };

			.content, footer {
				max-width: 20em;
			}
		}

		footer {
			display: none;
		}

		.image-ratio {
			--ratio: 0.78;
		}

	` }
`;

