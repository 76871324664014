import { useState, useEffect } from 'react';

export function useDevicePixelRatio(opts) {
	const dpr = getDevicePixelRatio(opts);
	const [ currentDpr, setCurrentDpr ] = useState(dpr);

	useEffect(() => {
		const canListen = typeof window !== 'undefined' && 'matchMedia' in window;
		if (!canListen) return;
		const updateDpr = () => setCurrentDpr(getDevicePixelRatio(opts));
		const mediaMatcher = window.matchMedia(`screen and (resolution: ${ currentDpr }dppx)`);
		mediaMatcher.addEventListener('change', updateDpr);
		return () => mediaMatcher.removeEventListener('change', updateDpr);
	}, [ currentDpr, opts ]);

	return currentDpr;
}

export function getDevicePixelRatio(opts) {
	const { defaultDpr = 1, maxDpr = 3, round = true } = opts || {};
	const hasDprProp = typeof window !== 'undefined' && typeof window.devicePixelRatio === 'number';
	const dpr = hasDprProp ? window.devicePixelRatio : defaultDpr;
	const rounded = Math.min(Math.max(1, round ? Math.floor(dpr) : dpr), maxDpr);
	return rounded;
}
