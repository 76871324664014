import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import classNames from 'classnames';

import { useParallax } from '$hooks/useParallax';
import clamp from '$utils/clamp';

const sizes = {
	'bird': { width: 650, height: 566 },
	'barrel': { width: 341, height: 496 },
	'bottle': { width: 332, height: 936 },
	'column': { width: 216, height: 1020 },
	'crocodile-circle': { width: 326, height: 328 },
	'crocodile': { width: 648, height: 528 },
	'distillery': { width: 432, height: 557 },
	'door': { width: 364, height: 593 },
	'eminente-club': { width: 363, height: 363 },
	'jungle': { width: 666, height: 880 },
	'leaves': { width: 377, height: 364 },
	'sugarcanes': { width: 207, height: 818 },
	'tree': { width: 432, height: 864 },
};

const IllustrationParallax = ({ parallax, style = {}, ... props }) => {
	let strength = 1; let options = {};
	if (typeof parallax === 'number' || typeof parallax === 'string') {
		strength = +parallax;
	} else if (typeof parallax === 'object') {
		if (parallax.strength != null) strength = parallax.strength;
		if (parallax.options != null) options = parallax.options;
	}

	const [ $ref, y ] = useParallax((y, b) => {
		const max = b.h * (strength / 10);
		return clamp(y * max, -Math.abs(max), Math.abs(max));
	}, options);

	return <IllustrationComponent
		ref={$ref}
		style={{ ...style, y }}
		{...props}
	/>;
};

const IllustrationComponent = forwardRef(
	({ id, parallax, className, color = 'dust', style = {}, ...props }, ref) => {
		if (!sizes[ id ]) return null;

		if (parallax) {
			return <IllustrationParallax {...{
				id,
				parallax,
				className,
				color,
				...props
			}} />;
		}

		const width = sizes[ id ].width;
		const height = sizes[ id ].height;

		const ratio = height / width;
		style[ '--natural-width' ] = width + 'px';
		style[ '--ratio' ] = ratio;

		className = classNames(
			className,
			'illus',
			'illus-' + id,
			'color-' + color
		);

		return <motion.img
			ref={ref}
			className={className}
			src={`/images/illustrations/${ id }.png`}
			role="presentation"
			draggable="false"
			loading="lazy"
			decoding="async"
			style={ style }
			{...props}
		/>;
	}
);

IllustrationComponent.displayName = 'IllustrationComponent';

export const Illustration = styled(IllustrationComponent)`
	--width: var(--natural-width);
	--height: calc(var(--width) * var(--ratio));
	width: var(--width);
	height: var(--height);
	object-fit: contain;
	user-select: none;
	pointer-events: none;

	&.color-white {
		filter: invert(100%);
	}

	&.color-dust {
		filter: invert(83%) sepia(18%) saturate(201%) hue-rotate(345deg) brightness(110%) contrast(83%);
	}

	&.color-green {
		filter: invert(35%) sepia(13%) saturate(785%) hue-rotate(69deg) brightness(93%) contrast(89%);
	}
`;
